import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: string; output: string; }
  Decimal: { input: number; output: number; }
  UUID: { input: string; output: string; }
};

export type Account = {
  __typename: 'Account';
  code: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  customerServiceRepresentative: Maybe<SystemUser>;
  customerServiceRepresentativeId: Maybe<Scalars['UUID']['output']>;
  doNotAllowTexting: Maybe<Scalars['Boolean']['output']>;
  doNotBulkEMail: Maybe<Scalars['Boolean']['output']>;
  doNotBulkPostalMail: Maybe<Scalars['Boolean']['output']>;
  doNotEMail: Maybe<Scalars['Boolean']['output']>;
  doNotFax: Maybe<Scalars['Boolean']['output']>;
  doNotPhone: Maybe<Scalars['Boolean']['output']>;
  doNotPostalMail: Maybe<Scalars['Boolean']['output']>;
  doNotSendMM: Maybe<Scalars['Boolean']['output']>;
  emailAddress1: Maybe<Scalars['String']['output']>;
  emailAddress2: Maybe<Scalars['String']['output']>;
  emailAddress3: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isPwp: Maybe<Scalars['Boolean']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  primaryContact: Maybe<Contact>;
  primaryContactId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
};

export type AccountBreed = {
  __typename: 'AccountBreed';
  account: Account;
  accountId: Scalars['UUID']['output'];
  breed: Breed;
  breedId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
};

export type AccountBreedFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<AccountBreedFilterInput>>;
  breed?: InputMaybe<BreedFilterInput>;
  breedId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AccountBreedFilterInput>>;
};

export type AccountFilterInput = {
  and?: InputMaybe<Array<AccountFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  customerServiceRepresentative?: InputMaybe<SystemUserFilterInput>;
  customerServiceRepresentativeId?: InputMaybe<UuidOperationFilterInput>;
  doNotAllowTexting?: InputMaybe<BooleanOperationFilterInput>;
  doNotBulkEMail?: InputMaybe<BooleanOperationFilterInput>;
  doNotBulkPostalMail?: InputMaybe<BooleanOperationFilterInput>;
  doNotEMail?: InputMaybe<BooleanOperationFilterInput>;
  doNotFax?: InputMaybe<BooleanOperationFilterInput>;
  doNotPhone?: InputMaybe<BooleanOperationFilterInput>;
  doNotPostalMail?: InputMaybe<BooleanOperationFilterInput>;
  doNotSendMM?: InputMaybe<BooleanOperationFilterInput>;
  emailAddress1?: InputMaybe<StringOperationFilterInput>;
  emailAddress2?: InputMaybe<StringOperationFilterInput>;
  emailAddress3?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isPwp?: InputMaybe<BooleanOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountFilterInput>>;
  primaryContact?: InputMaybe<ContactFilterInput>;
  primaryContactId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
};

export type AccountSortInput = {
  code?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  customerServiceRepresentative?: InputMaybe<SystemUserSortInput>;
  customerServiceRepresentativeId?: InputMaybe<SortEnumType>;
  doNotAllowTexting?: InputMaybe<SortEnumType>;
  doNotBulkEMail?: InputMaybe<SortEnumType>;
  doNotBulkPostalMail?: InputMaybe<SortEnumType>;
  doNotEMail?: InputMaybe<SortEnumType>;
  doNotFax?: InputMaybe<SortEnumType>;
  doNotPhone?: InputMaybe<SortEnumType>;
  doNotPostalMail?: InputMaybe<SortEnumType>;
  doNotSendMM?: InputMaybe<SortEnumType>;
  emailAddress1?: InputMaybe<SortEnumType>;
  emailAddress2?: InputMaybe<SortEnumType>;
  emailAddress3?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPwp?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  primaryContact?: InputMaybe<ContactSortInput>;
  primaryContactId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AccountsCollectionSegment = {
  __typename: 'AccountsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Account>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type AdUser = {
  __typename: 'AdUser';
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type AetaResult = {
  __typename: 'AetaResult';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<SystemUser>;
  createdById: Scalars['UUID']['output'];
  embryoDevelopmentRate: Scalars['Float']['output'];
  embryosPerAspiration: Scalars['Float']['output'];
  id: Scalars['UUID']['output'];
  oocytesPerAspiration: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<SystemUser>;
  updatedById: Maybe<Scalars['UUID']['output']>;
  year: Scalars['Int']['output'];
};

export type AetaResultFilterInput = {
  and?: InputMaybe<Array<AetaResultFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  embryoDevelopmentRate?: InputMaybe<FloatOperationFilterInput>;
  embryosPerAspiration?: InputMaybe<FloatOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  oocytesPerAspiration?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<AetaResultFilterInput>>;
  type?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  updatedById?: InputMaybe<UuidOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type AetaResultSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  embryoDevelopmentRate?: InputMaybe<SortEnumType>;
  embryosPerAspiration?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  oocytesPerAspiration?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedById?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export enum AetaResultType {
  BEEF = 'BEEF',
  DAIRY = 'DAIRY'
}

/** A segment of a collection. */
export type AetaResultsCollectionSegment = {
  __typename: 'AetaResultsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<AetaResult>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type App = {
  __typename: 'App';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactiveAt: Maybe<Scalars['DateTime']['output']>;
  isFavorite: Scalars['Boolean']['output'];
  isLegacy: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum ApplyPolicy {
  AFTER_RESOLVER = 'AFTER_RESOLVER',
  BEFORE_RESOLVER = 'BEFORE_RESOLVER',
  VALIDATION = 'VALIDATION'
}

export type Aspiration = {
  __typename: 'Aspiration';
  aspDate: Maybe<Scalars['DateTime']['output']>;
  aspTime: Maybe<Scalars['String']['output']>;
  aspirationPool: Maybe<AspirationPool>;
  aspirationPoolId: Maybe<Scalars['UUID']['output']>;
  aspstatus: Maybe<Scalars['Int']['output']>;
  assistant: Maybe<Equipment>;
  assistantId: Maybe<Scalars['UUID']['output']>;
  billDate: Maybe<Scalars['DateTime']['output']>;
  billDrugs: Maybe<Scalars['Boolean']['output']>;
  billStatus: Maybe<Scalars['Int']['output']>;
  category: Maybe<Scalars['Int']['output']>;
  cdComment: Maybe<Scalars['String']['output']>;
  cll: Maybe<Scalars['Int']['output']>;
  clr: Maybe<Scalars['Int']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  completeOrder: Maybe<Scalars['Int']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  cystl: Maybe<Scalars['String']['output']>;
  cystr: Maybe<Scalars['String']['output']>;
  dead: Maybe<Scalars['Int']['output']>;
  donor: Maybe<Donor>;
  donorId: Maybe<Scalars['UUID']['output']>;
  donorProcedureId: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Unreliable: usually empty. */
  donorSchedules: Array<DonorSchedule>;
  embCompleteOrder: Maybe<Scalars['Int']['output']>;
  embryoPlan: Maybe<Scalars['Int']['output']>;
  fertPlan: Maybe<tog_fertplan>;
  fertilizations: Array<Fertilization>;
  flexFieldPartner: Maybe<Scalars['String']['output']>;
  flshid: Maybe<Scalars['Int']['output']>;
  fshNext: Maybe<Scalars['Int']['output']>;
  fshType: Maybe<Scalars['Int']['output']>;
  gpBatch: Maybe<Scalars['String']['output']>;
  gradeA: Maybe<Scalars['Int']['output']>;
  gradeB: Maybe<Scalars['Int']['output']>;
  gradeC: Maybe<Scalars['Int']['output']>;
  gradeD: Maybe<Scalars['Int']['output']>;
  gradeE: Maybe<Scalars['Int']['output']>;
  histId: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  ivmComment: Maybe<Scalars['String']['output']>;
  ivmCyto: Maybe<Scalars['Int']['output']>;
  ivmMat: Maybe<Scalars['Int']['output']>;
  ivmMature: Maybe<Scalars['Int']['output']>;
  ivmRec: Maybe<Scalars['Int']['output']>;
  ivmTechnician: Maybe<Equipment>;
  ivmTechnicianId: Maybe<Scalars['UUID']['output']>;
  ivmTime: Maybe<Scalars['String']['output']>;
  matStatus: Maybe<Scalars['Int']['output']>;
  mean: Maybe<Scalars['Decimal']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  nextScheduleMaster: Maybe<ScheduleMaster>;
  nextScheduleMasterId: Maybe<Scalars['UUID']['output']>;
  nofollll: Maybe<Scalars['Int']['output']>;
  nofolllm: Maybe<Scalars['Int']['output']>;
  nofollls: Maybe<Scalars['Int']['output']>;
  nofollrl: Maybe<Scalars['Int']['output']>;
  nofollrm: Maybe<Scalars['Int']['output']>;
  nofollrs: Maybe<Scalars['Int']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  procId: Maybe<Scalars['String']['output']>;
  qcAirQuality: Maybe<Scalars['Int']['output']>;
  qcCellstrainer: Maybe<Scalars['String']['output']>;
  qcComment: Maybe<Scalars['String']['output']>;
  qcFiftyMLLotNumber: Maybe<Scalars['String']['output']>;
  qcFiftyMLTubeType: Maybe<Scalars['String']['output']>;
  qcHepDate: Maybe<Scalars['DateTime']['output']>;
  qcIncubatorNumber: Maybe<Scalars['String']['output']>;
  qcMatDate: Maybe<Scalars['DateTime']['output']>;
  qcOpuFlush: Maybe<Scalars['String']['output']>;
  qcOpuFlushDate: Maybe<Scalars['DateTime']['output']>;
  qcOpuHolding: Maybe<Scalars['String']['output']>;
  qcOpuHoldingDate: Maybe<Scalars['DateTime']['output']>;
  qcRinseMediaLotDate: Maybe<Scalars['DateTime']['output']>;
  qcTenMLSyringe: Maybe<Scalars['String']['output']>;
  qcThirtyMLSyringe: Maybe<Scalars['String']['output']>;
  resultsEnteredAspiration: Maybe<Scalars['Boolean']['output']>;
  resultsEnteredMaturation: Maybe<Scalars['Boolean']['output']>;
  scheduleMaster: Maybe<ScheduleMaster>;
  scheduleMasterId: Maybe<Scalars['UUID']['output']>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  soSchedule: Maybe<SuperOvulationSchedule>;
  soScheduleId: Maybe<Scalars['UUID']['output']>;
  soSchedules: Array<SuperOvulationSchedule>;
  splitOver40: Maybe<Scalars['Int']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  technician: Maybe<Equipment>;
  technicianId: Maybe<Scalars['UUID']['output']>;
  togK1Banamine: Maybe<Scalars['Int']['output']>;
  totTrans: Maybe<Scalars['Int']['output']>;
  totalFroze: Maybe<Scalars['Int']['output']>;
  totalShip: Maybe<Scalars['Int']['output']>;
  totdisc: Maybe<Scalars['Int']['output']>;
  totfolll: Maybe<Scalars['Int']['output']>;
  totfollr: Maybe<Scalars['Int']['output']>;
  usdaSeal: Maybe<Scalars['String']['output']>;
};

export type AspirationFilterInput = {
  and?: InputMaybe<Array<AspirationFilterInput>>;
  aspDate?: InputMaybe<DateTimeOperationFilterInput>;
  aspTime?: InputMaybe<StringOperationFilterInput>;
  aspirationPool?: InputMaybe<AspirationPoolFilterInput>;
  aspirationPoolId?: InputMaybe<UuidOperationFilterInput>;
  aspstatus?: InputMaybe<IntOperationFilterInput>;
  assistant?: InputMaybe<EquipmentFilterInput>;
  assistantId?: InputMaybe<UuidOperationFilterInput>;
  billDate?: InputMaybe<DateTimeOperationFilterInput>;
  billDrugs?: InputMaybe<BooleanOperationFilterInput>;
  billStatus?: InputMaybe<IntOperationFilterInput>;
  category?: InputMaybe<IntOperationFilterInput>;
  cdComment?: InputMaybe<StringOperationFilterInput>;
  cll?: InputMaybe<IntOperationFilterInput>;
  clr?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  completeOrder?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  cystl?: InputMaybe<StringOperationFilterInput>;
  cystr?: InputMaybe<StringOperationFilterInput>;
  dead?: InputMaybe<IntOperationFilterInput>;
  donor?: InputMaybe<DonorFilterInput>;
  donorId?: InputMaybe<UuidOperationFilterInput>;
  donorProcedureId?: InputMaybe<UuidOperationFilterInput>;
  donorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  embCompleteOrder?: InputMaybe<IntOperationFilterInput>;
  embryoPlan?: InputMaybe<IntOperationFilterInput>;
  fertPlan?: InputMaybe<NullableOftog_fertplanOperationFilterInput>;
  fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  flexFieldPartner?: InputMaybe<StringOperationFilterInput>;
  flshid?: InputMaybe<IntOperationFilterInput>;
  fshNext?: InputMaybe<IntOperationFilterInput>;
  fshType?: InputMaybe<IntOperationFilterInput>;
  gpBatch?: InputMaybe<StringOperationFilterInput>;
  gradeA?: InputMaybe<IntOperationFilterInput>;
  gradeB?: InputMaybe<IntOperationFilterInput>;
  gradeC?: InputMaybe<IntOperationFilterInput>;
  gradeD?: InputMaybe<IntOperationFilterInput>;
  gradeE?: InputMaybe<IntOperationFilterInput>;
  histId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  ivmComment?: InputMaybe<StringOperationFilterInput>;
  ivmCyto?: InputMaybe<IntOperationFilterInput>;
  ivmMat?: InputMaybe<IntOperationFilterInput>;
  ivmMature?: InputMaybe<IntOperationFilterInput>;
  ivmRec?: InputMaybe<IntOperationFilterInput>;
  ivmTechnician?: InputMaybe<EquipmentFilterInput>;
  ivmTechnicianId?: InputMaybe<UuidOperationFilterInput>;
  ivmTime?: InputMaybe<StringOperationFilterInput>;
  matStatus?: InputMaybe<IntOperationFilterInput>;
  mean?: InputMaybe<DecimalOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nextScheduleMaster?: InputMaybe<ScheduleMasterFilterInput>;
  nextScheduleMasterId?: InputMaybe<UuidOperationFilterInput>;
  nofollll?: InputMaybe<IntOperationFilterInput>;
  nofolllm?: InputMaybe<IntOperationFilterInput>;
  nofollls?: InputMaybe<IntOperationFilterInput>;
  nofollrl?: InputMaybe<IntOperationFilterInput>;
  nofollrm?: InputMaybe<IntOperationFilterInput>;
  nofollrs?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AspirationFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  procId?: InputMaybe<StringOperationFilterInput>;
  qcAirQuality?: InputMaybe<IntOperationFilterInput>;
  qcCellstrainer?: InputMaybe<StringOperationFilterInput>;
  qcComment?: InputMaybe<StringOperationFilterInput>;
  qcFiftyMLLotNumber?: InputMaybe<StringOperationFilterInput>;
  qcFiftyMLTubeType?: InputMaybe<StringOperationFilterInput>;
  qcHepDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcIncubatorNumber?: InputMaybe<StringOperationFilterInput>;
  qcMatDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcOpuFlush?: InputMaybe<StringOperationFilterInput>;
  qcOpuFlushDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcOpuHolding?: InputMaybe<StringOperationFilterInput>;
  qcOpuHoldingDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcRinseMediaLotDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcTenMLSyringe?: InputMaybe<StringOperationFilterInput>;
  qcThirtyMLSyringe?: InputMaybe<StringOperationFilterInput>;
  resultsEnteredAspiration?: InputMaybe<BooleanOperationFilterInput>;
  resultsEnteredMaturation?: InputMaybe<BooleanOperationFilterInput>;
  scheduleMaster?: InputMaybe<ScheduleMasterFilterInput>;
  scheduleMasterId?: InputMaybe<UuidOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  soSchedule?: InputMaybe<SuperOvulationScheduleFilterInput>;
  soScheduleId?: InputMaybe<UuidOperationFilterInput>;
  soSchedules?: InputMaybe<ListFilterInputTypeOfSuperOvulationScheduleFilterInput>;
  splitOver40?: InputMaybe<IntOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  technician?: InputMaybe<EquipmentFilterInput>;
  technicianId?: InputMaybe<UuidOperationFilterInput>;
  togK1Banamine?: InputMaybe<IntOperationFilterInput>;
  totTrans?: InputMaybe<IntOperationFilterInput>;
  totalFroze?: InputMaybe<IntOperationFilterInput>;
  totalShip?: InputMaybe<IntOperationFilterInput>;
  totdisc?: InputMaybe<IntOperationFilterInput>;
  totfolll?: InputMaybe<IntOperationFilterInput>;
  totfollr?: InputMaybe<IntOperationFilterInput>;
  usdaSeal?: InputMaybe<StringOperationFilterInput>;
};

export type AspirationPool = {
  __typename: 'AspirationPool';
  aspirations: Array<Aspiration>;
  breed: Maybe<Breed>;
  breedId: Maybe<Scalars['UUID']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  date: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  donorsInPool: Maybe<Scalars['String']['output']>;
  fertilizations: Array<Fertilization>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  numberInPool: Maybe<Scalars['Int']['output']>;
  opuSite: Maybe<Site>;
  opuSiteid: Maybe<Scalars['UUID']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  poolNumber: Maybe<Scalars['Int']['output']>;
  pooledBy: Maybe<Scalars['Int']['output']>;
  probableDonor: Maybe<Donor>;
  probableDonorId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  togIdsInPool: Maybe<Scalars['String']['output']>;
};

export type AspirationPoolFilterInput = {
  and?: InputMaybe<Array<AspirationPoolFilterInput>>;
  aspirations?: InputMaybe<ListFilterInputTypeOfAspirationFilterInput>;
  breed?: InputMaybe<BreedFilterInput>;
  breedId?: InputMaybe<UuidOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  donorsInPool?: InputMaybe<StringOperationFilterInput>;
  fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberInPool?: InputMaybe<IntOperationFilterInput>;
  opuSite?: InputMaybe<SiteFilterInput>;
  opuSiteid?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AspirationPoolFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  poolNumber?: InputMaybe<IntOperationFilterInput>;
  pooledBy?: InputMaybe<IntOperationFilterInput>;
  probableDonor?: InputMaybe<DonorFilterInput>;
  probableDonorId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  togIdsInPool?: InputMaybe<StringOperationFilterInput>;
};

export type AspirationPoolSortInput = {
  breed?: InputMaybe<BreedSortInput>;
  breedId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  donorsInPool?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberInPool?: InputMaybe<SortEnumType>;
  opuSite?: InputMaybe<SiteSortInput>;
  opuSiteid?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  poolNumber?: InputMaybe<SortEnumType>;
  pooledBy?: InputMaybe<SortEnumType>;
  probableDonor?: InputMaybe<DonorSortInput>;
  probableDonorId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  togIdsInPool?: InputMaybe<SortEnumType>;
};

export type AspirationSortInput = {
  aspDate?: InputMaybe<SortEnumType>;
  aspTime?: InputMaybe<SortEnumType>;
  aspirationPool?: InputMaybe<AspirationPoolSortInput>;
  aspirationPoolId?: InputMaybe<SortEnumType>;
  aspstatus?: InputMaybe<SortEnumType>;
  assistant?: InputMaybe<EquipmentSortInput>;
  assistantId?: InputMaybe<SortEnumType>;
  billDate?: InputMaybe<SortEnumType>;
  billDrugs?: InputMaybe<SortEnumType>;
  billStatus?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  cdComment?: InputMaybe<SortEnumType>;
  cll?: InputMaybe<SortEnumType>;
  clr?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  completeOrder?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  cystl?: InputMaybe<SortEnumType>;
  cystr?: InputMaybe<SortEnumType>;
  dead?: InputMaybe<SortEnumType>;
  donor?: InputMaybe<DonorSortInput>;
  donorId?: InputMaybe<SortEnumType>;
  donorProcedureId?: InputMaybe<SortEnumType>;
  embCompleteOrder?: InputMaybe<SortEnumType>;
  embryoPlan?: InputMaybe<SortEnumType>;
  fertPlan?: InputMaybe<SortEnumType>;
  flexFieldPartner?: InputMaybe<SortEnumType>;
  flshid?: InputMaybe<SortEnumType>;
  fshNext?: InputMaybe<SortEnumType>;
  fshType?: InputMaybe<SortEnumType>;
  gpBatch?: InputMaybe<SortEnumType>;
  gradeA?: InputMaybe<SortEnumType>;
  gradeB?: InputMaybe<SortEnumType>;
  gradeC?: InputMaybe<SortEnumType>;
  gradeD?: InputMaybe<SortEnumType>;
  gradeE?: InputMaybe<SortEnumType>;
  histId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  ivmComment?: InputMaybe<SortEnumType>;
  ivmCyto?: InputMaybe<SortEnumType>;
  ivmMat?: InputMaybe<SortEnumType>;
  ivmMature?: InputMaybe<SortEnumType>;
  ivmRec?: InputMaybe<SortEnumType>;
  ivmTechnician?: InputMaybe<EquipmentSortInput>;
  ivmTechnicianId?: InputMaybe<SortEnumType>;
  ivmTime?: InputMaybe<SortEnumType>;
  matStatus?: InputMaybe<SortEnumType>;
  mean?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nextScheduleMaster?: InputMaybe<ScheduleMasterSortInput>;
  nextScheduleMasterId?: InputMaybe<SortEnumType>;
  nofollll?: InputMaybe<SortEnumType>;
  nofolllm?: InputMaybe<SortEnumType>;
  nofollls?: InputMaybe<SortEnumType>;
  nofollrl?: InputMaybe<SortEnumType>;
  nofollrm?: InputMaybe<SortEnumType>;
  nofollrs?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  procId?: InputMaybe<SortEnumType>;
  qcAirQuality?: InputMaybe<SortEnumType>;
  qcCellstrainer?: InputMaybe<SortEnumType>;
  qcComment?: InputMaybe<SortEnumType>;
  qcFiftyMLLotNumber?: InputMaybe<SortEnumType>;
  qcFiftyMLTubeType?: InputMaybe<SortEnumType>;
  qcHepDate?: InputMaybe<SortEnumType>;
  qcIncubatorNumber?: InputMaybe<SortEnumType>;
  qcMatDate?: InputMaybe<SortEnumType>;
  qcOpuFlush?: InputMaybe<SortEnumType>;
  qcOpuFlushDate?: InputMaybe<SortEnumType>;
  qcOpuHolding?: InputMaybe<SortEnumType>;
  qcOpuHoldingDate?: InputMaybe<SortEnumType>;
  qcRinseMediaLotDate?: InputMaybe<SortEnumType>;
  qcTenMLSyringe?: InputMaybe<SortEnumType>;
  qcThirtyMLSyringe?: InputMaybe<SortEnumType>;
  resultsEnteredAspiration?: InputMaybe<SortEnumType>;
  resultsEnteredMaturation?: InputMaybe<SortEnumType>;
  scheduleMaster?: InputMaybe<ScheduleMasterSortInput>;
  scheduleMasterId?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  soSchedule?: InputMaybe<SuperOvulationScheduleSortInput>;
  soScheduleId?: InputMaybe<SortEnumType>;
  splitOver40?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  technician?: InputMaybe<EquipmentSortInput>;
  technicianId?: InputMaybe<SortEnumType>;
  togK1Banamine?: InputMaybe<SortEnumType>;
  totTrans?: InputMaybe<SortEnumType>;
  totalFroze?: InputMaybe<SortEnumType>;
  totalShip?: InputMaybe<SortEnumType>;
  totdisc?: InputMaybe<SortEnumType>;
  totfolll?: InputMaybe<SortEnumType>;
  totfollr?: InputMaybe<SortEnumType>;
  usdaSeal?: InputMaybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Breed = {
  __typename: 'Breed';
  accountBreeds: Array<AccountBreed>;
  aspirationPools: Array<AspirationPool>;
  breedEmbryos: Array<Embryo>;
  category: Maybe<tog_category>;
  code: Maybe<Scalars['String']['output']>;
  /** Comma-separated RGB values */
  color: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  donors: Array<Donor>;
  frameSize: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  recipBreedEmbryos: Array<Embryo>;
  recipients: Array<Recipient>;
  sires: Array<Sire>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  subFamily: Maybe<tog_subfamily>;
  type: Maybe<Scalars['Int']['output']>;
};

export type BreedFilterInput = {
  accountBreeds?: InputMaybe<ListFilterInputTypeOfAccountBreedFilterInput>;
  and?: InputMaybe<Array<BreedFilterInput>>;
  aspirationPools?: InputMaybe<ListFilterInputTypeOfAspirationPoolFilterInput>;
  breedEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  category?: InputMaybe<NullableOftog_categoryOperationFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  donors?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  frameSize?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BreedFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  recipBreedEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  recipients?: InputMaybe<ListFilterInputTypeOfRecipientFilterInput>;
  sires?: InputMaybe<ListFilterInputTypeOfSireFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  subFamily?: InputMaybe<NullableOftog_subfamilyOperationFilterInput>;
  type?: InputMaybe<IntOperationFilterInput>;
};

export type BreedSortInput = {
  category?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  frameSize?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  subFamily?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type BusinessUnit = {
  __typename: 'BusinessUnit';
  calendars: Array<Calendar>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['UUID']['output'];
};

export type BusinessUnitFilterInput = {
  and?: InputMaybe<Array<BusinessUnitFilterInput>>;
  calendars?: InputMaybe<ListFilterInputTypeOfCalendarFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BusinessUnitFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
};

export type BusinessUnitSortInput = {
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
};

export type Calendar = {
  __typename: 'Calendar';
  businessUnit: Maybe<BusinessUnit>;
  businessUnitId: Maybe<Scalars['UUID']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isShared: Scalars['Boolean']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organizationId: Scalars['UUID']['output'];
  type: Scalars['Int']['output'];
};

export type CalendarFilterInput = {
  and?: InputMaybe<Array<CalendarFilterInput>>;
  businessUnit?: InputMaybe<BusinessUnitFilterInput>;
  businessUnitId?: InputMaybe<UuidOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isShared?: InputMaybe<BooleanOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CalendarFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  type?: InputMaybe<IntOperationFilterInput>;
};

export type CalendarSortInput = {
  businessUnit?: InputMaybe<BusinessUnitSortInput>;
  businessUnitId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isShared?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type Calf = {
  __typename: 'Calf';
  activityDate: Maybe<Scalars['DateTime']['output']>;
  billComment: Maybe<Scalars['String']['output']>;
  billInstructions: Maybe<Scalars['Int']['output']>;
  birth: Maybe<Scalars['DateTime']['output']>;
  birthTime: Maybe<Scalars['Int']['output']>;
  birthWeight: Maybe<Scalars['Int']['output']>;
  boardBilledDate: Maybe<Scalars['DateTime']['output']>;
  breed: Maybe<Breed>;
  breedId: Maybe<Scalars['UUID']['output']>;
  calfType: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unknown */
  calfd: Maybe<Scalars['Int']['output']>;
  clientPlans: Maybe<Scalars['String']['output']>;
  /** @deprecated What is cm? */
  cm: Maybe<Scalars['Int']['output']>;
  /** @deprecated What is cod? */
  cod: Maybe<Scalars['String']['output']>;
  color: Maybe<Color>;
  colorId: Maybe<Scalars['UUID']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  ddEntryDate: Maybe<Scalars['DateTime']['output']>;
  destination: Maybe<Scalars['String']['output']>;
  dnaBarcode: Maybe<Scalars['String']['output']>;
  dnaReceivedOn: Maybe<Scalars['DateTime']['output']>;
  dnaSentOn: Maybe<Scalars['DateTime']['output']>;
  donor: Maybe<Donor>;
  donorId: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Unknown */
  dtogid: Maybe<Scalars['Int']['output']>;
  embryo: Maybe<Embryo>;
  embryoId: Maybe<Scalars['UUID']['output']>;
  estimatedDueDate: Maybe<Scalars['DateTime']['output']>;
  fetalSex: Maybe<Scalars['Int']['output']>;
  fosterRecipient: Maybe<Recipient>;
  fosterRecipientId: Maybe<Scalars['UUID']['output']>;
  genomicDecision: Maybe<Scalars['Int']['output']>;
  haveTesticlesDecended: Maybe<Scalars['Boolean']['output']>;
  herdOrigin: Maybe<Scalars['String']['output']>;
  hipHeight: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['UUID']['output'];
  /** @deprecated Unknown */
  impid: Maybe<Scalars['Int']['output']>;
  isInduced: Maybe<Scalars['Boolean']['output']>;
  lopuDonor: Maybe<Donor>;
  lopuDonorId: Maybe<Scalars['UUID']['output']>;
  metalTag: Maybe<Scalars['String']['output']>;
  method: Maybe<Scalars['Int']['output']>;
  methodValue: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  /** @deprecated What is nm? */
  nm: Maybe<Scalars['Int']['output']>;
  opuProgram: Maybe<Scalars['Int']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  pedigreeName: Maybe<Scalars['String']['output']>;
  pen: Maybe<Pen>;
  penId: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Unknown. What is bvdpi? */
  preEntryBvdpiTesting: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unknown */
  procedureUser: Maybe<SystemUser>;
  /** @deprecated Unknown */
  procedureUserId: Maybe<Scalars['UUID']['output']>;
  quarantine: Maybe<Scalars['Int']['output']>;
  recipient: Maybe<Recipient>;
  recipientId: Maybe<Scalars['UUID']['output']>;
  registry: Maybe<Scalars['String']['output']>;
  secondTissue: Maybe<Scalars['String']['output']>;
  sex: Maybe<Scalars['Int']['output']>;
  sexValue: Maybe<Scalars['String']['output']>;
  ship: Maybe<Scalars['DateTime']['output']>;
  shipment: Maybe<Shipment>;
  shipmentId: Maybe<Scalars['UUID']['output']>;
  shipmentPlan: Maybe<Scalars['String']['output']>;
  sire: Maybe<Sire>;
  sireId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  status: Maybe<OptionSet>;
  statusCode: Maybe<Scalars['Int']['output']>;
  statusId: Maybe<Scalars['UUID']['output']>;
  stopBoardOn: Maybe<Scalars['DateTime']['output']>;
  tag: Maybe<Scalars['String']['output']>;
  tattoo: Maybe<Scalars['String']['output']>;
  testiclesDecendedOn: Maybe<Scalars['DateTime']['output']>;
  testicularDevelopment: Maybe<Scalars['Int']['output']>;
  togId: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unknown */
  tog_ocv: Maybe<Scalars['String']['output']>;
  /** @deprecated What is tpi? */
  tpi: Maybe<Scalars['Int']['output']>;
  weanOn: Maybe<Scalars['DateTime']['output']>;
  weanWeight: Maybe<Scalars['Int']['output']>;
  weight: Maybe<Scalars['Int']['output']>;
  yard: Maybe<TOG_yardBase>;
  yardId: Maybe<Scalars['UUID']['output']>;
};

export type CalfFilterInput = {
  activityDate?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<CalfFilterInput>>;
  billComment?: InputMaybe<StringOperationFilterInput>;
  billInstructions?: InputMaybe<IntOperationFilterInput>;
  birth?: InputMaybe<DateTimeOperationFilterInput>;
  birthTime?: InputMaybe<IntOperationFilterInput>;
  birthWeight?: InputMaybe<IntOperationFilterInput>;
  boardBilledDate?: InputMaybe<DateTimeOperationFilterInput>;
  breed?: InputMaybe<BreedFilterInput>;
  breedId?: InputMaybe<UuidOperationFilterInput>;
  calfType?: InputMaybe<IntOperationFilterInput>;
  calfd?: InputMaybe<IntOperationFilterInput>;
  clientPlans?: InputMaybe<StringOperationFilterInput>;
  cm?: InputMaybe<IntOperationFilterInput>;
  cod?: InputMaybe<StringOperationFilterInput>;
  color?: InputMaybe<ColorFilterInput>;
  colorId?: InputMaybe<UuidOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  ddEntryDate?: InputMaybe<DateTimeOperationFilterInput>;
  destination?: InputMaybe<StringOperationFilterInput>;
  dnaBarcode?: InputMaybe<StringOperationFilterInput>;
  dnaReceivedOn?: InputMaybe<DateTimeOperationFilterInput>;
  dnaSentOn?: InputMaybe<DateTimeOperationFilterInput>;
  donor?: InputMaybe<DonorFilterInput>;
  donorId?: InputMaybe<UuidOperationFilterInput>;
  dtogid?: InputMaybe<IntOperationFilterInput>;
  embryo?: InputMaybe<EmbryoFilterInput>;
  embryoId?: InputMaybe<UuidOperationFilterInput>;
  estimatedDueDate?: InputMaybe<DateTimeOperationFilterInput>;
  fetalSex?: InputMaybe<IntOperationFilterInput>;
  fosterRecipient?: InputMaybe<RecipientFilterInput>;
  fosterRecipientId?: InputMaybe<UuidOperationFilterInput>;
  genomicDecision?: InputMaybe<IntOperationFilterInput>;
  haveTesticlesDecended?: InputMaybe<BooleanOperationFilterInput>;
  herdOrigin?: InputMaybe<StringOperationFilterInput>;
  hipHeight?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  impid?: InputMaybe<IntOperationFilterInput>;
  isInduced?: InputMaybe<BooleanOperationFilterInput>;
  lopuDonor?: InputMaybe<DonorFilterInput>;
  lopuDonorId?: InputMaybe<UuidOperationFilterInput>;
  metalTag?: InputMaybe<StringOperationFilterInput>;
  method?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  nm?: InputMaybe<IntOperationFilterInput>;
  opuProgram?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CalfFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  pedigreeName?: InputMaybe<StringOperationFilterInput>;
  pen?: InputMaybe<PenFilterInput>;
  penId?: InputMaybe<UuidOperationFilterInput>;
  preEntryBvdpiTesting?: InputMaybe<IntOperationFilterInput>;
  procedureUser?: InputMaybe<SystemUserFilterInput>;
  procedureUserId?: InputMaybe<UuidOperationFilterInput>;
  quarantine?: InputMaybe<IntOperationFilterInput>;
  recipient?: InputMaybe<RecipientFilterInput>;
  recipientId?: InputMaybe<UuidOperationFilterInput>;
  registry?: InputMaybe<StringOperationFilterInput>;
  secondTissue?: InputMaybe<StringOperationFilterInput>;
  sex?: InputMaybe<IntOperationFilterInput>;
  ship?: InputMaybe<DateTimeOperationFilterInput>;
  shipment?: InputMaybe<ShipmentFilterInput>;
  shipmentId?: InputMaybe<UuidOperationFilterInput>;
  shipmentPlan?: InputMaybe<StringOperationFilterInput>;
  sire?: InputMaybe<SireFilterInput>;
  sireId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<OptionSetFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  statusId?: InputMaybe<UuidOperationFilterInput>;
  stopBoardOn?: InputMaybe<DateTimeOperationFilterInput>;
  tag?: InputMaybe<StringOperationFilterInput>;
  tattoo?: InputMaybe<StringOperationFilterInput>;
  testiclesDecendedOn?: InputMaybe<DateTimeOperationFilterInput>;
  testicularDevelopment?: InputMaybe<IntOperationFilterInput>;
  togId?: InputMaybe<IntOperationFilterInput>;
  tog_ocv?: InputMaybe<StringOperationFilterInput>;
  tpi?: InputMaybe<IntOperationFilterInput>;
  weanOn?: InputMaybe<DateTimeOperationFilterInput>;
  weanWeight?: InputMaybe<IntOperationFilterInput>;
  weight?: InputMaybe<IntOperationFilterInput>;
  yard?: InputMaybe<TOG_yardBaseFilterInput>;
  yardId?: InputMaybe<UuidOperationFilterInput>;
};

export type CalfProcedure = {
  __typename: 'CalfProcedure';
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  fosterRecipient: Maybe<Recipient>;
  fosterRecipientId: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  newYard: Maybe<TOG_yardBase>;
  newYardId: Maybe<Scalars['UUID']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  status: Maybe<OptionSet>;
  statusCode: Maybe<Scalars['Int']['output']>;
  statusId: Maybe<Scalars['UUID']['output']>;
  tog_Date: Maybe<Scalars['DateTime']['output']>;
  tog_Description: Maybe<Scalars['String']['output']>;
  tog_DiagnosisId: Maybe<Scalars['UUID']['output']>;
  tog_Dose: Maybe<Scalars['Decimal']['output']>;
  tog_Lot: Maybe<Scalars['String']['output']>;
  tog_NewPenId: Maybe<Scalars['UUID']['output']>;
  tog_PenId: Maybe<Scalars['UUID']['output']>;
  tog_Procedure: Maybe<Scalars['String']['output']>;
  tog_Tag: Maybe<Scalars['String']['output']>;
  tog_Unit: Maybe<Scalars['String']['output']>;
  tog_Weight: Maybe<Scalars['Int']['output']>;
  tog_Who: Maybe<Scalars['String']['output']>;
  tog_billstatus: Maybe<Scalars['Int']['output']>;
  tog_boardtypechange: Maybe<Scalars['Int']['output']>;
  tog_calfid: Maybe<Scalars['UUID']['output']>;
  tog_drugid: Maybe<Scalars['UUID']['output']>;
  tog_hipheight: Maybe<Scalars['Decimal']['output']>;
  tog_histid: Maybe<Scalars['Int']['output']>;
  tog_ownerid: Maybe<Scalars['UUID']['output']>;
  tog_proccodeid: Maybe<Scalars['UUID']['output']>;
  tog_temp: Maybe<Scalars['Decimal']['output']>;
  tog_timeofday: Maybe<Scalars['Int']['output']>;
  tog_togid: Maybe<Scalars['Int']['output']>;
  yard: Maybe<TOG_yardBase>;
  yardId: Maybe<Scalars['UUID']['output']>;
};

export type CalfProcedureFilterInput = {
  and?: InputMaybe<Array<CalfProcedureFilterInput>>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  fosterRecipient?: InputMaybe<RecipientFilterInput>;
  fosterRecipientId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  newYard?: InputMaybe<TOG_yardBaseFilterInput>;
  newYardId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CalfProcedureFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<OptionSetFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  statusId?: InputMaybe<UuidOperationFilterInput>;
  tog_Date?: InputMaybe<DateTimeOperationFilterInput>;
  tog_Description?: InputMaybe<StringOperationFilterInput>;
  tog_DiagnosisId?: InputMaybe<UuidOperationFilterInput>;
  tog_Dose?: InputMaybe<DecimalOperationFilterInput>;
  tog_Lot?: InputMaybe<StringOperationFilterInput>;
  tog_NewPenId?: InputMaybe<UuidOperationFilterInput>;
  tog_PenId?: InputMaybe<UuidOperationFilterInput>;
  tog_Procedure?: InputMaybe<StringOperationFilterInput>;
  tog_Tag?: InputMaybe<StringOperationFilterInput>;
  tog_Unit?: InputMaybe<StringOperationFilterInput>;
  tog_Weight?: InputMaybe<IntOperationFilterInput>;
  tog_Who?: InputMaybe<StringOperationFilterInput>;
  tog_billstatus?: InputMaybe<IntOperationFilterInput>;
  tog_boardtypechange?: InputMaybe<IntOperationFilterInput>;
  tog_calfid?: InputMaybe<UuidOperationFilterInput>;
  tog_drugid?: InputMaybe<UuidOperationFilterInput>;
  tog_hipheight?: InputMaybe<DecimalOperationFilterInput>;
  tog_histid?: InputMaybe<IntOperationFilterInput>;
  tog_ownerid?: InputMaybe<UuidOperationFilterInput>;
  tog_proccodeid?: InputMaybe<UuidOperationFilterInput>;
  tog_temp?: InputMaybe<DecimalOperationFilterInput>;
  tog_timeofday?: InputMaybe<IntOperationFilterInput>;
  tog_togid?: InputMaybe<IntOperationFilterInput>;
  yard?: InputMaybe<TOG_yardBaseFilterInput>;
  yardId?: InputMaybe<UuidOperationFilterInput>;
};

export type CalfSortInput = {
  activityDate?: InputMaybe<SortEnumType>;
  billComment?: InputMaybe<SortEnumType>;
  billInstructions?: InputMaybe<SortEnumType>;
  birth?: InputMaybe<SortEnumType>;
  birthTime?: InputMaybe<SortEnumType>;
  birthWeight?: InputMaybe<SortEnumType>;
  boardBilledDate?: InputMaybe<SortEnumType>;
  breed?: InputMaybe<BreedSortInput>;
  breedId?: InputMaybe<SortEnumType>;
  calfType?: InputMaybe<SortEnumType>;
  calfd?: InputMaybe<SortEnumType>;
  clientPlans?: InputMaybe<SortEnumType>;
  cm?: InputMaybe<SortEnumType>;
  cod?: InputMaybe<SortEnumType>;
  color?: InputMaybe<ColorSortInput>;
  colorId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  ddEntryDate?: InputMaybe<SortEnumType>;
  destination?: InputMaybe<SortEnumType>;
  dnaBarcode?: InputMaybe<SortEnumType>;
  dnaReceivedOn?: InputMaybe<SortEnumType>;
  dnaSentOn?: InputMaybe<SortEnumType>;
  donor?: InputMaybe<DonorSortInput>;
  donorId?: InputMaybe<SortEnumType>;
  dtogid?: InputMaybe<SortEnumType>;
  embryo?: InputMaybe<EmbryoSortInput>;
  embryoId?: InputMaybe<SortEnumType>;
  estimatedDueDate?: InputMaybe<SortEnumType>;
  fetalSex?: InputMaybe<SortEnumType>;
  fosterRecipient?: InputMaybe<RecipientSortInput>;
  fosterRecipientId?: InputMaybe<SortEnumType>;
  genomicDecision?: InputMaybe<SortEnumType>;
  haveTesticlesDecended?: InputMaybe<SortEnumType>;
  herdOrigin?: InputMaybe<SortEnumType>;
  hipHeight?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  impid?: InputMaybe<SortEnumType>;
  isInduced?: InputMaybe<SortEnumType>;
  lopuDonor?: InputMaybe<DonorSortInput>;
  lopuDonorId?: InputMaybe<SortEnumType>;
  metalTag?: InputMaybe<SortEnumType>;
  method?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  nm?: InputMaybe<SortEnumType>;
  opuProgram?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  pedigreeName?: InputMaybe<SortEnumType>;
  pen?: InputMaybe<PenSortInput>;
  penId?: InputMaybe<SortEnumType>;
  preEntryBvdpiTesting?: InputMaybe<SortEnumType>;
  procedureUser?: InputMaybe<SystemUserSortInput>;
  procedureUserId?: InputMaybe<SortEnumType>;
  quarantine?: InputMaybe<SortEnumType>;
  recipient?: InputMaybe<RecipientSortInput>;
  recipientId?: InputMaybe<SortEnumType>;
  registry?: InputMaybe<SortEnumType>;
  secondTissue?: InputMaybe<SortEnumType>;
  sex?: InputMaybe<SortEnumType>;
  ship?: InputMaybe<SortEnumType>;
  shipment?: InputMaybe<ShipmentSortInput>;
  shipmentId?: InputMaybe<SortEnumType>;
  shipmentPlan?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SireSortInput>;
  sireId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<OptionSetSortInput>;
  statusCode?: InputMaybe<SortEnumType>;
  statusId?: InputMaybe<SortEnumType>;
  stopBoardOn?: InputMaybe<SortEnumType>;
  tag?: InputMaybe<SortEnumType>;
  tattoo?: InputMaybe<SortEnumType>;
  testiclesDecendedOn?: InputMaybe<SortEnumType>;
  testicularDevelopment?: InputMaybe<SortEnumType>;
  togId?: InputMaybe<SortEnumType>;
  tog_ocv?: InputMaybe<SortEnumType>;
  tpi?: InputMaybe<SortEnumType>;
  weanOn?: InputMaybe<SortEnumType>;
  weanWeight?: InputMaybe<SortEnumType>;
  weight?: InputMaybe<SortEnumType>;
  yard?: InputMaybe<TOG_yardBaseSortInput>;
  yardId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CalvesCollectionSegment = {
  __typename: 'CalvesCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Calf>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type ClosedItRequestsCollectionSegment = {
  __typename: 'ClosedItRequestsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type Color = {
  __typename: 'Color';
  calves: Array<Calf>;
  code: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  recipients: Array<Recipient>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
};

export type ColorFilterInput = {
  and?: InputMaybe<Array<ColorFilterInput>>;
  calves?: InputMaybe<ListFilterInputTypeOfCalfFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ColorFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  recipients?: InputMaybe<ListFilterInputTypeOfRecipientFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
};

export type ColorSortInput = {
  code?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
};

export type Contact = {
  __typename: 'Contact';
  accountRoleCode: Maybe<Scalars['Int']['output']>;
  accountsPrimaryContact: Array<Account>;
  aging30: Maybe<Scalars['Decimal']['output']>;
  aging30_Base: Maybe<Scalars['Decimal']['output']>;
  aging60: Maybe<Scalars['Decimal']['output']>;
  aging60_Base: Maybe<Scalars['Decimal']['output']>;
  aging90: Maybe<Scalars['Decimal']['output']>;
  aging90_Base: Maybe<Scalars['Decimal']['output']>;
  anniversary: Maybe<Scalars['DateTime']['output']>;
  annualIncome_Base: Maybe<Scalars['Decimal']['output']>;
  assistantName: Maybe<Scalars['String']['output']>;
  assistantPhone: Maybe<Scalars['String']['output']>;
  birthDate: Maybe<Scalars['DateTime']['output']>;
  business2: Maybe<Scalars['String']['output']>;
  childrensNames: Maybe<Scalars['String']['output']>;
  company: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdByExternalParty: Maybe<Scalars['UUID']['output']>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  creditLimit: Maybe<Scalars['Decimal']['output']>;
  creditLimit_Base: Maybe<Scalars['Decimal']['output']>;
  creditOnHold: Maybe<Scalars['Boolean']['output']>;
  customerSizeCode: Maybe<Scalars['Int']['output']>;
  customerTypeCode: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  doNotBulkEmail: Maybe<Scalars['Boolean']['output']>;
  doNotBulkPostalMail: Maybe<Scalars['Boolean']['output']>;
  doNotEmail: Maybe<Scalars['Boolean']['output']>;
  doNotFax: Maybe<Scalars['Boolean']['output']>;
  doNotPhone: Maybe<Scalars['Boolean']['output']>;
  doNotPostalMail: Maybe<Scalars['Boolean']['output']>;
  doNotSendMM: Maybe<Scalars['Boolean']['output']>;
  educationCode: Maybe<Scalars['Int']['output']>;
  emailAddress1: Maybe<Scalars['String']['output']>;
  emailAddress2: Maybe<Scalars['String']['output']>;
  emailAddress3: Maybe<Scalars['String']['output']>;
  employeeId: Maybe<Scalars['String']['output']>;
  employeeStatus: Maybe<Scalars['Int']['output']>;
  entityImageId: Maybe<Scalars['UUID']['output']>;
  exchangeRate: Maybe<Scalars['Decimal']['output']>;
  extension: Maybe<Scalars['String']['output']>;
  externalUserIdentifier: Maybe<Scalars['String']['output']>;
  familyStatusCode: Maybe<Scalars['Int']['output']>;
  fax: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  followEmail: Maybe<Scalars['Boolean']['output']>;
  ftpSiteUrl: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  genderCode: Maybe<Scalars['Int']['output']>;
  hasChildrenCode: Maybe<Scalars['Int']['output']>;
  home2: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  importSequenceNumber: Maybe<Scalars['Int']['output']>;
  isAutoCreate: Maybe<Scalars['Boolean']['output']>;
  isPrivate: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  lastUsedInCampaign: Maybe<Scalars['DateTime']['output']>;
  managerName: Maybe<Scalars['String']['output']>;
  managerPhone: Maybe<Scalars['String']['output']>;
  marketingOnly: Maybe<Scalars['Boolean']['output']>;
  masterId: Maybe<Scalars['UUID']['output']>;
  merged: Maybe<Scalars['Boolean']['output']>;
  middleName: Maybe<Scalars['String']['output']>;
  mobilePhone: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedByExternalParty: Maybe<Scalars['UUID']['output']>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  nickName: Maybe<Scalars['String']['output']>;
  numberOfChildren: Maybe<Scalars['Int']['output']>;
  ownerId: Scalars['UUID']['output'];
  ownerIdType: Scalars['Int']['output'];
  pager: Maybe<Scalars['String']['output']>;
  parentCustomerId: Maybe<Scalars['UUID']['output']>;
  parentCustomerIdName: Maybe<Scalars['String']['output']>;
  parentCustomerIdType: Maybe<Scalars['Int']['output']>;
  parentCustomerIdYomiName: Maybe<Scalars['String']['output']>;
  paymentTermsCode: Maybe<Scalars['Int']['output']>;
  preferredAppointmentDayCode: Maybe<Scalars['Int']['output']>;
  preferredAppointmentTimeCode: Maybe<Scalars['Int']['output']>;
  preferredContactMethodCode: Maybe<Scalars['Int']['output']>;
  preferredEquipmentId: Maybe<Scalars['UUID']['output']>;
  preferredServiceId: Maybe<Scalars['UUID']['output']>;
  preferredSystemUserId: Maybe<Scalars['UUID']['output']>;
  processId: Maybe<Scalars['UUID']['output']>;
  shippingMethodCode: Maybe<Scalars['Int']['output']>;
  spousesName: Maybe<Scalars['String']['output']>;
  stageId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  subscriptionId: Maybe<Scalars['UUID']['output']>;
  suffix: Maybe<Scalars['String']['output']>;
  telephone1: Maybe<Scalars['String']['output']>;
  telephone2: Maybe<Scalars['String']['output']>;
  telephone3: Maybe<Scalars['String']['output']>;
  territoryCode: Maybe<Scalars['Int']['output']>;
  timeZoneRuleVersionNumber: Maybe<Scalars['Int']['output']>;
  tog_SiteId: Maybe<Scalars['UUID']['output']>;
  tog_annotationid: Maybe<Scalars['String']['output']>;
  tog_contactid: Maybe<Scalars['UUID']['output']>;
  transactionCurrencyId: Maybe<Scalars['UUID']['output']>;
  traversedPath: Maybe<Scalars['String']['output']>;
  utcConversionTimeZoneCode: Maybe<Scalars['Int']['output']>;
  webSiteUrl: Maybe<Scalars['String']['output']>;
};

export type ContactFilterInput = {
  accountRoleCode?: InputMaybe<IntOperationFilterInput>;
  accountsPrimaryContact?: InputMaybe<ListFilterInputTypeOfAccountFilterInput>;
  aging30?: InputMaybe<DecimalOperationFilterInput>;
  aging30_Base?: InputMaybe<DecimalOperationFilterInput>;
  aging60?: InputMaybe<DecimalOperationFilterInput>;
  aging60_Base?: InputMaybe<DecimalOperationFilterInput>;
  aging90?: InputMaybe<DecimalOperationFilterInput>;
  aging90_Base?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ContactFilterInput>>;
  anniversary?: InputMaybe<DateTimeOperationFilterInput>;
  annualIncome_Base?: InputMaybe<DecimalOperationFilterInput>;
  assistantName?: InputMaybe<StringOperationFilterInput>;
  assistantPhone?: InputMaybe<StringOperationFilterInput>;
  birthDate?: InputMaybe<DateTimeOperationFilterInput>;
  business2?: InputMaybe<StringOperationFilterInput>;
  childrensNames?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdByExternalParty?: InputMaybe<UuidOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  creditLimit?: InputMaybe<DecimalOperationFilterInput>;
  creditLimit_Base?: InputMaybe<DecimalOperationFilterInput>;
  creditOnHold?: InputMaybe<BooleanOperationFilterInput>;
  customerSizeCode?: InputMaybe<IntOperationFilterInput>;
  customerTypeCode?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  doNotBulkEmail?: InputMaybe<BooleanOperationFilterInput>;
  doNotBulkPostalMail?: InputMaybe<BooleanOperationFilterInput>;
  doNotEmail?: InputMaybe<BooleanOperationFilterInput>;
  doNotFax?: InputMaybe<BooleanOperationFilterInput>;
  doNotPhone?: InputMaybe<BooleanOperationFilterInput>;
  doNotPostalMail?: InputMaybe<BooleanOperationFilterInput>;
  doNotSendMM?: InputMaybe<BooleanOperationFilterInput>;
  educationCode?: InputMaybe<IntOperationFilterInput>;
  emailAddress1?: InputMaybe<StringOperationFilterInput>;
  emailAddress2?: InputMaybe<StringOperationFilterInput>;
  emailAddress3?: InputMaybe<StringOperationFilterInput>;
  employeeId?: InputMaybe<StringOperationFilterInput>;
  employeeStatus?: InputMaybe<IntOperationFilterInput>;
  entityImageId?: InputMaybe<UuidOperationFilterInput>;
  exchangeRate?: InputMaybe<DecimalOperationFilterInput>;
  extension?: InputMaybe<StringOperationFilterInput>;
  externalUserIdentifier?: InputMaybe<StringOperationFilterInput>;
  familyStatusCode?: InputMaybe<IntOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  followEmail?: InputMaybe<BooleanOperationFilterInput>;
  ftpSiteUrl?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  genderCode?: InputMaybe<IntOperationFilterInput>;
  hasChildrenCode?: InputMaybe<IntOperationFilterInput>;
  home2?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  importSequenceNumber?: InputMaybe<IntOperationFilterInput>;
  isAutoCreate?: InputMaybe<BooleanOperationFilterInput>;
  isPrivate?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUsedInCampaign?: InputMaybe<DateTimeOperationFilterInput>;
  managerName?: InputMaybe<StringOperationFilterInput>;
  managerPhone?: InputMaybe<StringOperationFilterInput>;
  marketingOnly?: InputMaybe<BooleanOperationFilterInput>;
  masterId?: InputMaybe<UuidOperationFilterInput>;
  merged?: InputMaybe<BooleanOperationFilterInput>;
  middleName?: InputMaybe<StringOperationFilterInput>;
  mobilePhone?: InputMaybe<StringOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedByExternalParty?: InputMaybe<UuidOperationFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  nickName?: InputMaybe<StringOperationFilterInput>;
  numberOfChildren?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  ownerIdType?: InputMaybe<IntOperationFilterInput>;
  pager?: InputMaybe<StringOperationFilterInput>;
  parentCustomerId?: InputMaybe<UuidOperationFilterInput>;
  parentCustomerIdName?: InputMaybe<StringOperationFilterInput>;
  parentCustomerIdType?: InputMaybe<IntOperationFilterInput>;
  parentCustomerIdYomiName?: InputMaybe<StringOperationFilterInput>;
  paymentTermsCode?: InputMaybe<IntOperationFilterInput>;
  preferredAppointmentDayCode?: InputMaybe<IntOperationFilterInput>;
  preferredAppointmentTimeCode?: InputMaybe<IntOperationFilterInput>;
  preferredContactMethodCode?: InputMaybe<IntOperationFilterInput>;
  preferredEquipmentId?: InputMaybe<UuidOperationFilterInput>;
  preferredServiceId?: InputMaybe<UuidOperationFilterInput>;
  preferredSystemUserId?: InputMaybe<UuidOperationFilterInput>;
  processId?: InputMaybe<UuidOperationFilterInput>;
  shippingMethodCode?: InputMaybe<IntOperationFilterInput>;
  spousesName?: InputMaybe<StringOperationFilterInput>;
  stageId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  subscriptionId?: InputMaybe<UuidOperationFilterInput>;
  suffix?: InputMaybe<StringOperationFilterInput>;
  telephone1?: InputMaybe<StringOperationFilterInput>;
  telephone2?: InputMaybe<StringOperationFilterInput>;
  telephone3?: InputMaybe<StringOperationFilterInput>;
  territoryCode?: InputMaybe<IntOperationFilterInput>;
  timeZoneRuleVersionNumber?: InputMaybe<IntOperationFilterInput>;
  tog_SiteId?: InputMaybe<UuidOperationFilterInput>;
  tog_annotationid?: InputMaybe<StringOperationFilterInput>;
  tog_contactid?: InputMaybe<UuidOperationFilterInput>;
  transactionCurrencyId?: InputMaybe<UuidOperationFilterInput>;
  traversedPath?: InputMaybe<StringOperationFilterInput>;
  utcConversionTimeZoneCode?: InputMaybe<IntOperationFilterInput>;
  webSiteUrl?: InputMaybe<StringOperationFilterInput>;
};

export type ContactSortInput = {
  accountRoleCode?: InputMaybe<SortEnumType>;
  aging30?: InputMaybe<SortEnumType>;
  aging30_Base?: InputMaybe<SortEnumType>;
  aging60?: InputMaybe<SortEnumType>;
  aging60_Base?: InputMaybe<SortEnumType>;
  aging90?: InputMaybe<SortEnumType>;
  aging90_Base?: InputMaybe<SortEnumType>;
  anniversary?: InputMaybe<SortEnumType>;
  annualIncome_Base?: InputMaybe<SortEnumType>;
  assistantName?: InputMaybe<SortEnumType>;
  assistantPhone?: InputMaybe<SortEnumType>;
  birthDate?: InputMaybe<SortEnumType>;
  business2?: InputMaybe<SortEnumType>;
  childrensNames?: InputMaybe<SortEnumType>;
  company?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdByExternalParty?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  creditLimit?: InputMaybe<SortEnumType>;
  creditLimit_Base?: InputMaybe<SortEnumType>;
  creditOnHold?: InputMaybe<SortEnumType>;
  customerSizeCode?: InputMaybe<SortEnumType>;
  customerTypeCode?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  doNotBulkEmail?: InputMaybe<SortEnumType>;
  doNotBulkPostalMail?: InputMaybe<SortEnumType>;
  doNotEmail?: InputMaybe<SortEnumType>;
  doNotFax?: InputMaybe<SortEnumType>;
  doNotPhone?: InputMaybe<SortEnumType>;
  doNotPostalMail?: InputMaybe<SortEnumType>;
  doNotSendMM?: InputMaybe<SortEnumType>;
  educationCode?: InputMaybe<SortEnumType>;
  emailAddress1?: InputMaybe<SortEnumType>;
  emailAddress2?: InputMaybe<SortEnumType>;
  emailAddress3?: InputMaybe<SortEnumType>;
  employeeId?: InputMaybe<SortEnumType>;
  employeeStatus?: InputMaybe<SortEnumType>;
  entityImageId?: InputMaybe<SortEnumType>;
  exchangeRate?: InputMaybe<SortEnumType>;
  extension?: InputMaybe<SortEnumType>;
  externalUserIdentifier?: InputMaybe<SortEnumType>;
  familyStatusCode?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  followEmail?: InputMaybe<SortEnumType>;
  ftpSiteUrl?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  genderCode?: InputMaybe<SortEnumType>;
  hasChildrenCode?: InputMaybe<SortEnumType>;
  home2?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  importSequenceNumber?: InputMaybe<SortEnumType>;
  isAutoCreate?: InputMaybe<SortEnumType>;
  isPrivate?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  lastUsedInCampaign?: InputMaybe<SortEnumType>;
  managerName?: InputMaybe<SortEnumType>;
  managerPhone?: InputMaybe<SortEnumType>;
  marketingOnly?: InputMaybe<SortEnumType>;
  masterId?: InputMaybe<SortEnumType>;
  merged?: InputMaybe<SortEnumType>;
  middleName?: InputMaybe<SortEnumType>;
  mobilePhone?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedByExternalParty?: InputMaybe<SortEnumType>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  nickName?: InputMaybe<SortEnumType>;
  numberOfChildren?: InputMaybe<SortEnumType>;
  ownerId?: InputMaybe<SortEnumType>;
  ownerIdType?: InputMaybe<SortEnumType>;
  pager?: InputMaybe<SortEnumType>;
  parentCustomerId?: InputMaybe<SortEnumType>;
  parentCustomerIdName?: InputMaybe<SortEnumType>;
  parentCustomerIdType?: InputMaybe<SortEnumType>;
  parentCustomerIdYomiName?: InputMaybe<SortEnumType>;
  paymentTermsCode?: InputMaybe<SortEnumType>;
  preferredAppointmentDayCode?: InputMaybe<SortEnumType>;
  preferredAppointmentTimeCode?: InputMaybe<SortEnumType>;
  preferredContactMethodCode?: InputMaybe<SortEnumType>;
  preferredEquipmentId?: InputMaybe<SortEnumType>;
  preferredServiceId?: InputMaybe<SortEnumType>;
  preferredSystemUserId?: InputMaybe<SortEnumType>;
  processId?: InputMaybe<SortEnumType>;
  shippingMethodCode?: InputMaybe<SortEnumType>;
  spousesName?: InputMaybe<SortEnumType>;
  stageId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  subscriptionId?: InputMaybe<SortEnumType>;
  suffix?: InputMaybe<SortEnumType>;
  telephone1?: InputMaybe<SortEnumType>;
  telephone2?: InputMaybe<SortEnumType>;
  telephone3?: InputMaybe<SortEnumType>;
  territoryCode?: InputMaybe<SortEnumType>;
  timeZoneRuleVersionNumber?: InputMaybe<SortEnumType>;
  tog_SiteId?: InputMaybe<SortEnumType>;
  tog_annotationid?: InputMaybe<SortEnumType>;
  tog_contactid?: InputMaybe<SortEnumType>;
  transactionCurrencyId?: InputMaybe<SortEnumType>;
  traversedPath?: InputMaybe<SortEnumType>;
  utcConversionTimeZoneCode?: InputMaybe<SortEnumType>;
  webSiteUrl?: InputMaybe<SortEnumType>;
};

export type CreateAetaResultInput = {
  embryoDevelopmentRate: Scalars['Float']['input'];
  embryosPerAspiration: Scalars['Float']['input'];
  oocytesPerAspiration: Scalars['Float']['input'];
  type: AetaResultType;
  year: Scalars['Int']['input'];
};

export type CreateAetaResultPayload = {
  __typename: 'CreateAetaResultPayload';
  aetaResult: Maybe<AetaResult>;
};

/** A segment of a collection. */
export type DailySireSelectionsCollectionSegment = {
  __typename: 'DailySireSelectionsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<DailySireSelectionsDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type DailySireSelectionsDto = {
  __typename: 'DailySireSelectionsDto';
  accountId: Scalars['UUID']['output'];
  csrId: Scalars['UUID']['output'];
  date: Scalars['DateTime']['output'];
  donorName: Maybe<Scalars['String']['output']>;
  donorScheduleId: Scalars['UUID']['output'];
  oocyteCount: Scalars['Int']['output'];
  semenType: Maybe<Scalars['String']['output']>;
  sireName: Maybe<Scalars['String']['output']>;
  textingNumber: Scalars['String']['output'];
};

export type DailySireSelectionsDtoFilterInput = {
  accountId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<DailySireSelectionsDtoFilterInput>>;
  csrId?: InputMaybe<UuidOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  donorName?: InputMaybe<StringOperationFilterInput>;
  donorScheduleId?: InputMaybe<UuidOperationFilterInput>;
  oocyteCount?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DailySireSelectionsDtoFilterInput>>;
  semenType?: InputMaybe<StringOperationFilterInput>;
  sireName?: InputMaybe<StringOperationFilterInput>;
  textingNumber?: InputMaybe<StringOperationFilterInput>;
};

export type DailySireSelectionsDtoSortInput = {
  accountId?: InputMaybe<SortEnumType>;
  csrId?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  donorName?: InputMaybe<SortEnumType>;
  donorScheduleId?: InputMaybe<SortEnumType>;
  oocyteCount?: InputMaybe<SortEnumType>;
  semenType?: InputMaybe<SortEnumType>;
  sireName?: InputMaybe<SortEnumType>;
  textingNumber?: InputMaybe<SortEnumType>;
};

export type DailySireSelectionsWithAccountsDto = {
  __typename: 'DailySireSelectionsWithAccountsDto';
  accountId: Scalars['UUID']['output'];
  donorScheduleId: Scalars['UUID']['output'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type Donor = {
  __typename: 'Donor';
  activityDate: Maybe<Scalars['DateTime']['output']>;
  birth: Maybe<Scalars['DateTime']['output']>;
  blood: Maybe<Scalars['Boolean']['output']>;
  breed: Maybe<Breed>;
  breedId: Maybe<Scalars['UUID']['output']>;
  breedPct: Maybe<Scalars['Decimal']['output']>;
  calf: Maybe<Scalars['Boolean']['output']>;
  calfSex: Maybe<Scalars['Int']['output']>;
  calfType: Maybe<Scalars['Int']['output']>;
  category: Maybe<Scalars['Int']['output']>;
  cemMergeXml: Maybe<Scalars['String']['output']>;
  cfTogId: Maybe<Scalars['Int']['output']>;
  cm: Maybe<Scalars['Int']['output']>;
  cod: Maybe<Scalars['String']['output']>;
  condition: Maybe<Scalars['String']['output']>;
  consolidatedHealthStatus: Maybe<Scalars['String']['output']>;
  contract: Maybe<Scalars['Boolean']['output']>;
  countryCodeId: Maybe<Scalars['UUID']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  dam: Maybe<Scalars['String']['output']>;
  damReg: Maybe<Scalars['String']['output']>;
  damSire: Maybe<Scalars['String']['output']>;
  damSrReg: Maybe<Scalars['String']['output']>;
  destination: Maybe<Scalars['String']['output']>;
  discontinueIvf: Maybe<Scalars['DateTime']['output']>;
  dnaBarcode: Maybe<Scalars['String']['output']>;
  dnaReceived: Maybe<Scalars['DateTime']['output']>;
  dnaSent: Maybe<Scalars['DateTime']['output']>;
  donorGroup: Maybe<Scalars['String']['output']>;
  donorName: Maybe<Scalars['String']['output']>;
  edonorClientId: Maybe<Scalars['String']['output']>;
  edonorId: Maybe<Scalars['String']['output']>;
  embryoPlan: Maybe<Scalars['Int']['output']>;
  embryos: Array<Embryo>;
  fertilizations: Array<Fertilization>;
  flexFieldPartner: Maybe<Scalars['String']['output']>;
  freezeInstructions: Maybe<Scalars['String']['output']>;
  grp: Maybe<Scalars['Int']['output']>;
  healthStatus: Maybe<Scalars['String']['output']>;
  heiferCow: Maybe<Scalars['Int']['output']>;
  housing: Maybe<OptionSet>;
  housingId: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  inversePooledDonor: Array<Donor>;
  itComm: Maybe<Scalars['String']['output']>;
  ivfStatus: Maybe<OptionSet>;
  ivfStatusId: Maybe<Scalars['UUID']['output']>;
  jpi: Maybe<Scalars['Int']['output']>;
  lastActivity: Maybe<Scalars['String']['output']>;
  lastAspirationOn: Maybe<Scalars['DateTime']['output']>;
  lastBred: Maybe<Scalars['DateTime']['output']>;
  lastBredSire: Maybe<Sire>;
  lastBredSireId: Maybe<Scalars['UUID']['output']>;
  lastFetalSex: Maybe<Scalars['Int']['output']>;
  lastFlush: Maybe<Scalars['DateTime']['output']>;
  lastHeat: Maybe<Scalars['DateTime']['output']>;
  leaseOrigin: Maybe<Account>;
  leaseOriginId: Maybe<Scalars['UUID']['output']>;
  liveCalf: Maybe<Scalars['Boolean']['output']>;
  lopuCalf: Maybe<Scalars['UUID']['output']>;
  metalTag: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  nm: Maybe<Scalars['Int']['output']>;
  numCalves: Maybe<Scalars['Int']['output']>;
  ocvStatus: Maybe<OptionSet>;
  ocvStatusId: Maybe<Scalars['UUID']['output']>;
  onFarm: Maybe<Scalars['Boolean']['output']>;
  opuProgram: Maybe<Scalars['Int']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  origin: Maybe<Scalars['String']['output']>;
  ourTag: Maybe<Scalars['String']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  pairedCalf: Maybe<Donor>;
  pairedCalfId: Maybe<Scalars['UUID']['output']>;
  pairedCalved: Array<Donor>;
  pedName: Maybe<Scalars['String']['output']>;
  pedigree: Maybe<Scalars['Boolean']['output']>;
  pooledDonor: Maybe<Donor>;
  pooledDonorId: Maybe<Scalars['UUID']['output']>;
  pregnant: Maybe<Scalars['Int']['output']>;
  pregnantValue: Maybe<Scalars['String']['output']>;
  probableDonorEmbryos: Array<Embryo>;
  probableDonorFertilizations: Array<Fertilization>;
  ranchTag: Maybe<Scalars['String']['output']>;
  received: Maybe<Scalars['DateTime']['output']>;
  regPrefix: Maybe<Scalars['String']['output']>;
  registrationNumber: Maybe<Scalars['String']['output']>;
  ship: Maybe<Scalars['DateTime']['output']>;
  shipment: Maybe<Shipment>;
  shipmentId: Maybe<Scalars['UUID']['output']>;
  shipmentPlan: Maybe<Scalars['String']['output']>;
  sire: Maybe<Scalars['String']['output']>;
  sireReg: Maybe<Scalars['String']['output']>;
  stateCode: Scalars['Int']['output'];
  status: Maybe<OptionSet>;
  statusCode: Maybe<Scalars['Int']['output']>;
  statusId: Maybe<Scalars['UUID']['output']>;
  tattoo: Maybe<Scalars['String']['output']>;
  togId: Maybe<Scalars['Int']['output']>;
  tpi: Maybe<Scalars['Int']['output']>;
  weight: Maybe<Scalars['Int']['output']>;
  yard: Maybe<TOG_yardBase>;
  yardBeforeChangeId: Maybe<Scalars['UUID']['output']>;
  yardId: Maybe<Scalars['UUID']['output']>;
};

export type DonorFilterInput = {
  activityDate?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<DonorFilterInput>>;
  birth?: InputMaybe<DateTimeOperationFilterInput>;
  blood?: InputMaybe<BooleanOperationFilterInput>;
  breed?: InputMaybe<BreedFilterInput>;
  breedId?: InputMaybe<UuidOperationFilterInput>;
  breedPct?: InputMaybe<DecimalOperationFilterInput>;
  calf?: InputMaybe<BooleanOperationFilterInput>;
  calfSex?: InputMaybe<IntOperationFilterInput>;
  calfType?: InputMaybe<IntOperationFilterInput>;
  category?: InputMaybe<IntOperationFilterInput>;
  cemMergeXml?: InputMaybe<StringOperationFilterInput>;
  cfTogId?: InputMaybe<IntOperationFilterInput>;
  cm?: InputMaybe<IntOperationFilterInput>;
  cod?: InputMaybe<StringOperationFilterInput>;
  condition?: InputMaybe<StringOperationFilterInput>;
  consolidatedHealthStatus?: InputMaybe<StringOperationFilterInput>;
  contract?: InputMaybe<BooleanOperationFilterInput>;
  countryCodeId?: InputMaybe<UuidOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  dam?: InputMaybe<StringOperationFilterInput>;
  damReg?: InputMaybe<StringOperationFilterInput>;
  damSire?: InputMaybe<StringOperationFilterInput>;
  damSrReg?: InputMaybe<StringOperationFilterInput>;
  destination?: InputMaybe<StringOperationFilterInput>;
  discontinueIvf?: InputMaybe<DateTimeOperationFilterInput>;
  dnaBarcode?: InputMaybe<StringOperationFilterInput>;
  dnaReceived?: InputMaybe<DateTimeOperationFilterInput>;
  dnaSent?: InputMaybe<DateTimeOperationFilterInput>;
  donorGroup?: InputMaybe<StringOperationFilterInput>;
  donorName?: InputMaybe<StringOperationFilterInput>;
  edonorClientId?: InputMaybe<StringOperationFilterInput>;
  edonorId?: InputMaybe<StringOperationFilterInput>;
  embryoPlan?: InputMaybe<IntOperationFilterInput>;
  embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  flexFieldPartner?: InputMaybe<StringOperationFilterInput>;
  freezeInstructions?: InputMaybe<StringOperationFilterInput>;
  grp?: InputMaybe<IntOperationFilterInput>;
  healthStatus?: InputMaybe<StringOperationFilterInput>;
  heiferCow?: InputMaybe<IntOperationFilterInput>;
  housing?: InputMaybe<OptionSetFilterInput>;
  housingId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  inversePooledDonor?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  itComm?: InputMaybe<StringOperationFilterInput>;
  ivfStatus?: InputMaybe<OptionSetFilterInput>;
  ivfStatusId?: InputMaybe<UuidOperationFilterInput>;
  jpi?: InputMaybe<IntOperationFilterInput>;
  lastActivity?: InputMaybe<StringOperationFilterInput>;
  lastAspirationOn?: InputMaybe<DateTimeOperationFilterInput>;
  lastBred?: InputMaybe<DateTimeOperationFilterInput>;
  lastBredSire?: InputMaybe<SireFilterInput>;
  lastBredSireId?: InputMaybe<UuidOperationFilterInput>;
  lastFetalSex?: InputMaybe<IntOperationFilterInput>;
  lastFlush?: InputMaybe<DateTimeOperationFilterInput>;
  lastHeat?: InputMaybe<DateTimeOperationFilterInput>;
  leaseOrigin?: InputMaybe<AccountFilterInput>;
  leaseOriginId?: InputMaybe<UuidOperationFilterInput>;
  liveCalf?: InputMaybe<BooleanOperationFilterInput>;
  lopuCalf?: InputMaybe<UuidOperationFilterInput>;
  metalTag?: InputMaybe<StringOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nm?: InputMaybe<IntOperationFilterInput>;
  numCalves?: InputMaybe<IntOperationFilterInput>;
  ocvStatus?: InputMaybe<OptionSetFilterInput>;
  ocvStatusId?: InputMaybe<UuidOperationFilterInput>;
  onFarm?: InputMaybe<BooleanOperationFilterInput>;
  opuProgram?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DonorFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  origin?: InputMaybe<StringOperationFilterInput>;
  ourTag?: InputMaybe<StringOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  pairedCalf?: InputMaybe<DonorFilterInput>;
  pairedCalfId?: InputMaybe<UuidOperationFilterInput>;
  pairedCalved?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  pedName?: InputMaybe<StringOperationFilterInput>;
  pedigree?: InputMaybe<BooleanOperationFilterInput>;
  pooledDonor?: InputMaybe<DonorFilterInput>;
  pooledDonorId?: InputMaybe<UuidOperationFilterInput>;
  pregnant?: InputMaybe<IntOperationFilterInput>;
  probableDonorEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  probableDonorFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  ranchTag?: InputMaybe<StringOperationFilterInput>;
  received?: InputMaybe<DateTimeOperationFilterInput>;
  regPrefix?: InputMaybe<StringOperationFilterInput>;
  registrationNumber?: InputMaybe<StringOperationFilterInput>;
  ship?: InputMaybe<DateTimeOperationFilterInput>;
  shipment?: InputMaybe<ShipmentFilterInput>;
  shipmentId?: InputMaybe<UuidOperationFilterInput>;
  shipmentPlan?: InputMaybe<StringOperationFilterInput>;
  sire?: InputMaybe<StringOperationFilterInput>;
  sireReg?: InputMaybe<StringOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<OptionSetFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  statusId?: InputMaybe<UuidOperationFilterInput>;
  tattoo?: InputMaybe<StringOperationFilterInput>;
  togId?: InputMaybe<IntOperationFilterInput>;
  tpi?: InputMaybe<IntOperationFilterInput>;
  weight?: InputMaybe<IntOperationFilterInput>;
  yard?: InputMaybe<TOG_yardBaseFilterInput>;
  yardBeforeChangeId?: InputMaybe<UuidOperationFilterInput>;
  yardId?: InputMaybe<UuidOperationFilterInput>;
};

export type DonorSchedule = {
  __typename: 'DonorSchedule';
  accountComment: Maybe<Scalars['String']['output']>;
  aspirationSite: Maybe<Site>;
  aspirationSiteId: Maybe<Scalars['UUID']['output']>;
  backupCsrId: Maybe<Scalars['UUID']['output']>;
  backupSemen: Maybe<Semen>;
  backupSemenId: Maybe<Scalars['UUID']['output']>;
  backupSemenType: Maybe<Scalars['Int']['output']>;
  backupSire: Maybe<Sire>;
  backupSireId: Maybe<Scalars['UUID']['output']>;
  billDrugs: Maybe<Scalars['Boolean']['output']>;
  billingAccount: Maybe<Account>;
  billingAccountId: Maybe<Scalars['UUID']['output']>;
  billingComment: Maybe<Scalars['String']['output']>;
  cane: Maybe<Scalars['String']['output']>;
  clinicRanchHaulIn: Maybe<Scalars['Int']['output']>;
  contact: Maybe<Scalars['String']['output']>;
  contractSemen: Maybe<Scalars['Boolean']['output']>;
  createSOAuto: Maybe<Scalars['Int']['output']>;
  createdBy: Maybe<SystemUser>;
  createdByApp: Maybe<Scalars['Int']['output']>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  creditRebill: Maybe<Scalars['Boolean']['output']>;
  critical: Maybe<Scalars['Int']['output']>;
  csr: Maybe<Scalars['String']['output']>;
  csrId: Maybe<Scalars['UUID']['output']>;
  csrfrztrf: Maybe<Scalars['String']['output']>;
  date: Maybe<Scalars['DateTime']['output']>;
  day7pickup: Maybe<Scalars['Int']['output']>;
  donor: Maybe<Donor>;
  donorComment: Maybe<Scalars['String']['output']>;
  donorId: Maybe<Scalars['UUID']['output']>;
  donorLocation: Maybe<Scalars['String']['output']>;
  dow: Maybe<Scalars['String']['output']>;
  drugShipingFee: Maybe<Scalars['Int']['output']>;
  drugStatus: Maybe<Scalars['Int']['output']>;
  embryoComment: Maybe<Scalars['String']['output']>;
  expectedSemenQty: Maybe<Scalars['Int']['output']>;
  export: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unreliable: usually empty. */
  fertilization: Maybe<Fertilization>;
  /** @deprecated Unreliable: usually empty. */
  fertilizationId: Maybe<Scalars['UUID']['output']>;
  fertilizationSite: Maybe<Site>;
  fertilizationSiteId: Maybe<Scalars['UUID']['output']>;
  fertilizations: Array<Fertilization>;
  flexFieldPartner: Maybe<Scalars['String']['output']>;
  freeze: Maybe<Scalars['Int']['output']>;
  freezeMethod: Maybe<OptionSet>;
  freezeMethodId: Maybe<Scalars['UUID']['output']>;
  freezeinstructions: Maybe<Scalars['String']['output']>;
  fsh2Id: Maybe<Scalars['UUID']['output']>;
  fshid: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  implantType: Maybe<Scalars['Int']['output']>;
  ivfLabSemen: Maybe<Semen>;
  ivfLabSemenId: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Unreliable: usually empty. */
  linkedAspiration: Maybe<Aspiration>;
  /** @deprecated Unreliable: usually empty. */
  linkedAspirationId: Maybe<Scalars['UUID']['output']>;
  loadDonorPartners: Maybe<Scalars['Int']['output']>;
  loadDonorPartnersfrz: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  notify: Maybe<Scalars['Boolean']['output']>;
  oocyteShipping: Maybe<Scalars['Int']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  parity: Maybe<Scalars['Int']['output']>;
  pool: Maybe<Scalars['Boolean']['output']>;
  pregnant: Maybe<Scalars['Int']['output']>;
  priority: Maybe<Scalars['Int']['output']>;
  procedureTime: Maybe<Scalars['Int']['output']>;
  procedureTimeComment: Maybe<Scalars['String']['output']>;
  rev: Maybe<Scalars['String']['output']>;
  scheduleMaster: Maybe<ScheduleMaster>;
  scheduleMasterId: Maybe<Scalars['UUID']['output']>;
  scheduleStatus: Maybe<Scalars['String']['output']>;
  scheduled: Maybe<Scalars['Boolean']['output']>;
  semen: Maybe<Semen>;
  semenComment: Maybe<Scalars['String']['output']>;
  semenId: Maybe<Scalars['UUID']['output']>;
  semenLocation: Maybe<StorageLocation>;
  semenLocationId: Maybe<Scalars['UUID']['output']>;
  semenOwner: Maybe<Account>;
  semenOwnerId: Maybe<Scalars['UUID']['output']>;
  semenPrice: Maybe<Scalars['Decimal']['output']>;
  semenReleased: Maybe<Scalars['Boolean']['output']>;
  semenType: Maybe<Scalars['Int']['output']>;
  semenTypeValue: Maybe<Scalars['String']['output']>;
  separateFert: Maybe<Scalars['Boolean']['output']>;
  ship: Maybe<Scalars['Int']['output']>;
  shipGroup: Maybe<Scalars['String']['output']>;
  shipInstructions: Maybe<Scalars['String']['output']>;
  shipToFbd: Maybe<Scalars['String']['output']>;
  shipmentGroupId: Maybe<Scalars['UUID']['output']>;
  sire: Maybe<Sire>;
  sireId: Maybe<Scalars['UUID']['output']>;
  sireSelectionRequest: Maybe<SireSelectionRequest>;
  sireSelectionRequestId: Maybe<Scalars['UUID']['output']>;
  soSchedule: Maybe<SuperOvulationSchedule>;
  soScheduleId: Maybe<Scalars['UUID']['output']>;
  soSchedules: Array<SuperOvulationSchedule>;
  specialBilling: Maybe<Scalars['Int']['output']>;
  specialInstructions: Maybe<Scalars['String']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  suggestedSemenMove: Maybe<Scalars['Int']['output']>;
  targetTime: Maybe<Scalars['Boolean']['output']>;
  textingNumber: Maybe<Scalars['String']['output']>;
  transfer: Maybe<Scalars['Int']['output']>;
  tt: Maybe<Scalars['Boolean']['output']>;
  validationMessage: Maybe<Scalars['String']['output']>;
  validationSts: Maybe<Scalars['Int']['output']>;
  week: Maybe<Scalars['Int']['output']>;
};

export type DonorScheduleFilterInput = {
  accountComment?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<DonorScheduleFilterInput>>;
  aspirationSite?: InputMaybe<SiteFilterInput>;
  aspirationSiteId?: InputMaybe<UuidOperationFilterInput>;
  backupCsrId?: InputMaybe<UuidOperationFilterInput>;
  backupSemen?: InputMaybe<SemenFilterInput>;
  backupSemenId?: InputMaybe<UuidOperationFilterInput>;
  backupSemenType?: InputMaybe<IntOperationFilterInput>;
  backupSire?: InputMaybe<SireFilterInput>;
  backupSireId?: InputMaybe<UuidOperationFilterInput>;
  billDrugs?: InputMaybe<BooleanOperationFilterInput>;
  billingAccount?: InputMaybe<AccountFilterInput>;
  billingAccountId?: InputMaybe<UuidOperationFilterInput>;
  billingComment?: InputMaybe<StringOperationFilterInput>;
  cane?: InputMaybe<StringOperationFilterInput>;
  clinicRanchHaulIn?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<StringOperationFilterInput>;
  contractSemen?: InputMaybe<BooleanOperationFilterInput>;
  createSOAuto?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdByApp?: InputMaybe<IntOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  creditRebill?: InputMaybe<BooleanOperationFilterInput>;
  critical?: InputMaybe<IntOperationFilterInput>;
  csr?: InputMaybe<StringOperationFilterInput>;
  csrId?: InputMaybe<UuidOperationFilterInput>;
  csrfrztrf?: InputMaybe<StringOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  day7pickup?: InputMaybe<IntOperationFilterInput>;
  donor?: InputMaybe<DonorFilterInput>;
  donorComment?: InputMaybe<StringOperationFilterInput>;
  donorId?: InputMaybe<UuidOperationFilterInput>;
  donorLocation?: InputMaybe<StringOperationFilterInput>;
  dow?: InputMaybe<StringOperationFilterInput>;
  drugShipingFee?: InputMaybe<IntOperationFilterInput>;
  drugStatus?: InputMaybe<IntOperationFilterInput>;
  embryoComment?: InputMaybe<StringOperationFilterInput>;
  expectedSemenQty?: InputMaybe<IntOperationFilterInput>;
  export?: InputMaybe<IntOperationFilterInput>;
  fertilization?: InputMaybe<FertilizationFilterInput>;
  fertilizationId?: InputMaybe<UuidOperationFilterInput>;
  fertilizationSite?: InputMaybe<SiteFilterInput>;
  fertilizationSiteId?: InputMaybe<UuidOperationFilterInput>;
  fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  flexFieldPartner?: InputMaybe<StringOperationFilterInput>;
  freeze?: InputMaybe<IntOperationFilterInput>;
  freezeMethod?: InputMaybe<OptionSetFilterInput>;
  freezeMethodId?: InputMaybe<UuidOperationFilterInput>;
  freezeinstructions?: InputMaybe<StringOperationFilterInput>;
  fsh2Id?: InputMaybe<UuidOperationFilterInput>;
  fshid?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  implantType?: InputMaybe<IntOperationFilterInput>;
  ivfLabSemen?: InputMaybe<SemenFilterInput>;
  ivfLabSemenId?: InputMaybe<UuidOperationFilterInput>;
  linkedAspiration?: InputMaybe<AspirationFilterInput>;
  linkedAspirationId?: InputMaybe<UuidOperationFilterInput>;
  loadDonorPartners?: InputMaybe<IntOperationFilterInput>;
  loadDonorPartnersfrz?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notify?: InputMaybe<BooleanOperationFilterInput>;
  oocyteShipping?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DonorScheduleFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  parity?: InputMaybe<IntOperationFilterInput>;
  pool?: InputMaybe<BooleanOperationFilterInput>;
  pregnant?: InputMaybe<IntOperationFilterInput>;
  priority?: InputMaybe<IntOperationFilterInput>;
  procedureTime?: InputMaybe<IntOperationFilterInput>;
  procedureTimeComment?: InputMaybe<StringOperationFilterInput>;
  rev?: InputMaybe<StringOperationFilterInput>;
  scheduleMaster?: InputMaybe<ScheduleMasterFilterInput>;
  scheduleMasterId?: InputMaybe<UuidOperationFilterInput>;
  scheduleStatus?: InputMaybe<StringOperationFilterInput>;
  scheduled?: InputMaybe<BooleanOperationFilterInput>;
  semen?: InputMaybe<SemenFilterInput>;
  semenComment?: InputMaybe<StringOperationFilterInput>;
  semenId?: InputMaybe<UuidOperationFilterInput>;
  semenLocation?: InputMaybe<StorageLocationFilterInput>;
  semenLocationId?: InputMaybe<UuidOperationFilterInput>;
  semenOwner?: InputMaybe<AccountFilterInput>;
  semenOwnerId?: InputMaybe<UuidOperationFilterInput>;
  semenPrice?: InputMaybe<DecimalOperationFilterInput>;
  semenReleased?: InputMaybe<BooleanOperationFilterInput>;
  semenType?: InputMaybe<IntOperationFilterInput>;
  separateFert?: InputMaybe<BooleanOperationFilterInput>;
  ship?: InputMaybe<IntOperationFilterInput>;
  shipGroup?: InputMaybe<StringOperationFilterInput>;
  shipInstructions?: InputMaybe<StringOperationFilterInput>;
  shipToFbd?: InputMaybe<StringOperationFilterInput>;
  shipmentGroupId?: InputMaybe<UuidOperationFilterInput>;
  sire?: InputMaybe<SireFilterInput>;
  sireId?: InputMaybe<UuidOperationFilterInput>;
  sireSelectionRequest?: InputMaybe<SireSelectionRequestFilterInput>;
  sireSelectionRequestId?: InputMaybe<UuidOperationFilterInput>;
  soSchedule?: InputMaybe<SuperOvulationScheduleFilterInput>;
  soScheduleId?: InputMaybe<UuidOperationFilterInput>;
  soSchedules?: InputMaybe<ListFilterInputTypeOfSuperOvulationScheduleFilterInput>;
  specialBilling?: InputMaybe<IntOperationFilterInput>;
  specialInstructions?: InputMaybe<StringOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  suggestedSemenMove?: InputMaybe<IntOperationFilterInput>;
  targetTime?: InputMaybe<BooleanOperationFilterInput>;
  textingNumber?: InputMaybe<StringOperationFilterInput>;
  transfer?: InputMaybe<IntOperationFilterInput>;
  tt?: InputMaybe<BooleanOperationFilterInput>;
  validationMessage?: InputMaybe<StringOperationFilterInput>;
  validationSts?: InputMaybe<IntOperationFilterInput>;
  week?: InputMaybe<IntOperationFilterInput>;
};

export type DonorScheduleSortInput = {
  accountComment?: InputMaybe<SortEnumType>;
  aspirationSite?: InputMaybe<SiteSortInput>;
  aspirationSiteId?: InputMaybe<SortEnumType>;
  backupCsrId?: InputMaybe<SortEnumType>;
  backupSemen?: InputMaybe<SemenSortInput>;
  backupSemenId?: InputMaybe<SortEnumType>;
  backupSemenType?: InputMaybe<SortEnumType>;
  backupSire?: InputMaybe<SireSortInput>;
  backupSireId?: InputMaybe<SortEnumType>;
  billDrugs?: InputMaybe<SortEnumType>;
  billingAccount?: InputMaybe<AccountSortInput>;
  billingAccountId?: InputMaybe<SortEnumType>;
  billingComment?: InputMaybe<SortEnumType>;
  cane?: InputMaybe<SortEnumType>;
  clinicRanchHaulIn?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<SortEnumType>;
  contractSemen?: InputMaybe<SortEnumType>;
  createSOAuto?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdByApp?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  creditRebill?: InputMaybe<SortEnumType>;
  critical?: InputMaybe<SortEnumType>;
  csr?: InputMaybe<SortEnumType>;
  csrId?: InputMaybe<SortEnumType>;
  csrfrztrf?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  day7pickup?: InputMaybe<SortEnumType>;
  donor?: InputMaybe<DonorSortInput>;
  donorComment?: InputMaybe<SortEnumType>;
  donorId?: InputMaybe<SortEnumType>;
  donorLocation?: InputMaybe<SortEnumType>;
  dow?: InputMaybe<SortEnumType>;
  drugShipingFee?: InputMaybe<SortEnumType>;
  drugStatus?: InputMaybe<SortEnumType>;
  embryoComment?: InputMaybe<SortEnumType>;
  expectedSemenQty?: InputMaybe<SortEnumType>;
  export?: InputMaybe<SortEnumType>;
  fertilization?: InputMaybe<FertilizationSortInput>;
  fertilizationId?: InputMaybe<SortEnumType>;
  fertilizationSite?: InputMaybe<SiteSortInput>;
  fertilizationSiteId?: InputMaybe<SortEnumType>;
  flexFieldPartner?: InputMaybe<SortEnumType>;
  freeze?: InputMaybe<SortEnumType>;
  freezeMethod?: InputMaybe<OptionSetSortInput>;
  freezeMethodId?: InputMaybe<SortEnumType>;
  freezeinstructions?: InputMaybe<SortEnumType>;
  fsh2Id?: InputMaybe<SortEnumType>;
  fshid?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  implantType?: InputMaybe<SortEnumType>;
  ivfLabSemen?: InputMaybe<SemenSortInput>;
  ivfLabSemenId?: InputMaybe<SortEnumType>;
  linkedAspiration?: InputMaybe<AspirationSortInput>;
  linkedAspirationId?: InputMaybe<SortEnumType>;
  loadDonorPartners?: InputMaybe<SortEnumType>;
  loadDonorPartnersfrz?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notify?: InputMaybe<SortEnumType>;
  oocyteShipping?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  parity?: InputMaybe<SortEnumType>;
  pool?: InputMaybe<SortEnumType>;
  pregnant?: InputMaybe<SortEnumType>;
  priority?: InputMaybe<SortEnumType>;
  procedureTime?: InputMaybe<SortEnumType>;
  procedureTimeComment?: InputMaybe<SortEnumType>;
  rev?: InputMaybe<SortEnumType>;
  scheduleMaster?: InputMaybe<ScheduleMasterSortInput>;
  scheduleMasterId?: InputMaybe<SortEnumType>;
  scheduleStatus?: InputMaybe<SortEnumType>;
  scheduled?: InputMaybe<SortEnumType>;
  semen?: InputMaybe<SemenSortInput>;
  semenComment?: InputMaybe<SortEnumType>;
  semenId?: InputMaybe<SortEnumType>;
  semenLocation?: InputMaybe<StorageLocationSortInput>;
  semenLocationId?: InputMaybe<SortEnumType>;
  semenOwner?: InputMaybe<AccountSortInput>;
  semenOwnerId?: InputMaybe<SortEnumType>;
  semenPrice?: InputMaybe<SortEnumType>;
  semenReleased?: InputMaybe<SortEnumType>;
  semenType?: InputMaybe<SortEnumType>;
  separateFert?: InputMaybe<SortEnumType>;
  ship?: InputMaybe<SortEnumType>;
  shipGroup?: InputMaybe<SortEnumType>;
  shipInstructions?: InputMaybe<SortEnumType>;
  shipToFbd?: InputMaybe<SortEnumType>;
  shipmentGroupId?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SireSortInput>;
  sireId?: InputMaybe<SortEnumType>;
  sireSelectionRequest?: InputMaybe<SireSelectionRequestSortInput>;
  sireSelectionRequestId?: InputMaybe<SortEnumType>;
  soSchedule?: InputMaybe<SuperOvulationScheduleSortInput>;
  soScheduleId?: InputMaybe<SortEnumType>;
  specialBilling?: InputMaybe<SortEnumType>;
  specialInstructions?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  suggestedSemenMove?: InputMaybe<SortEnumType>;
  targetTime?: InputMaybe<SortEnumType>;
  textingNumber?: InputMaybe<SortEnumType>;
  transfer?: InputMaybe<SortEnumType>;
  tt?: InputMaybe<SortEnumType>;
  validationMessage?: InputMaybe<SortEnumType>;
  validationSts?: InputMaybe<SortEnumType>;
  week?: InputMaybe<SortEnumType>;
};

export type DonorSortInput = {
  activityDate?: InputMaybe<SortEnumType>;
  birth?: InputMaybe<SortEnumType>;
  blood?: InputMaybe<SortEnumType>;
  breed?: InputMaybe<BreedSortInput>;
  breedId?: InputMaybe<SortEnumType>;
  breedPct?: InputMaybe<SortEnumType>;
  calf?: InputMaybe<SortEnumType>;
  calfSex?: InputMaybe<SortEnumType>;
  calfType?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  cemMergeXml?: InputMaybe<SortEnumType>;
  cfTogId?: InputMaybe<SortEnumType>;
  cm?: InputMaybe<SortEnumType>;
  cod?: InputMaybe<SortEnumType>;
  condition?: InputMaybe<SortEnumType>;
  consolidatedHealthStatus?: InputMaybe<SortEnumType>;
  contract?: InputMaybe<SortEnumType>;
  countryCodeId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  dam?: InputMaybe<SortEnumType>;
  damReg?: InputMaybe<SortEnumType>;
  damSire?: InputMaybe<SortEnumType>;
  damSrReg?: InputMaybe<SortEnumType>;
  destination?: InputMaybe<SortEnumType>;
  discontinueIvf?: InputMaybe<SortEnumType>;
  dnaBarcode?: InputMaybe<SortEnumType>;
  dnaReceived?: InputMaybe<SortEnumType>;
  dnaSent?: InputMaybe<SortEnumType>;
  donorGroup?: InputMaybe<SortEnumType>;
  donorName?: InputMaybe<SortEnumType>;
  edonorClientId?: InputMaybe<SortEnumType>;
  edonorId?: InputMaybe<SortEnumType>;
  embryoPlan?: InputMaybe<SortEnumType>;
  flexFieldPartner?: InputMaybe<SortEnumType>;
  freezeInstructions?: InputMaybe<SortEnumType>;
  grp?: InputMaybe<SortEnumType>;
  healthStatus?: InputMaybe<SortEnumType>;
  heiferCow?: InputMaybe<SortEnumType>;
  housing?: InputMaybe<OptionSetSortInput>;
  housingId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  itComm?: InputMaybe<SortEnumType>;
  ivfStatus?: InputMaybe<OptionSetSortInput>;
  ivfStatusId?: InputMaybe<SortEnumType>;
  jpi?: InputMaybe<SortEnumType>;
  lastActivity?: InputMaybe<SortEnumType>;
  lastAspirationOn?: InputMaybe<SortEnumType>;
  lastBred?: InputMaybe<SortEnumType>;
  lastBredSire?: InputMaybe<SireSortInput>;
  lastBredSireId?: InputMaybe<SortEnumType>;
  lastFetalSex?: InputMaybe<SortEnumType>;
  lastFlush?: InputMaybe<SortEnumType>;
  lastHeat?: InputMaybe<SortEnumType>;
  leaseOrigin?: InputMaybe<AccountSortInput>;
  leaseOriginId?: InputMaybe<SortEnumType>;
  liveCalf?: InputMaybe<SortEnumType>;
  lopuCalf?: InputMaybe<SortEnumType>;
  metalTag?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nm?: InputMaybe<SortEnumType>;
  numCalves?: InputMaybe<SortEnumType>;
  ocvStatus?: InputMaybe<OptionSetSortInput>;
  ocvStatusId?: InputMaybe<SortEnumType>;
  onFarm?: InputMaybe<SortEnumType>;
  opuProgram?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  origin?: InputMaybe<SortEnumType>;
  ourTag?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  pairedCalf?: InputMaybe<DonorSortInput>;
  pairedCalfId?: InputMaybe<SortEnumType>;
  pedName?: InputMaybe<SortEnumType>;
  pedigree?: InputMaybe<SortEnumType>;
  pooledDonor?: InputMaybe<DonorSortInput>;
  pooledDonorId?: InputMaybe<SortEnumType>;
  pregnant?: InputMaybe<SortEnumType>;
  ranchTag?: InputMaybe<SortEnumType>;
  received?: InputMaybe<SortEnumType>;
  regPrefix?: InputMaybe<SortEnumType>;
  registrationNumber?: InputMaybe<SortEnumType>;
  ship?: InputMaybe<SortEnumType>;
  shipment?: InputMaybe<ShipmentSortInput>;
  shipmentId?: InputMaybe<SortEnumType>;
  shipmentPlan?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SortEnumType>;
  sireReg?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<OptionSetSortInput>;
  statusCode?: InputMaybe<SortEnumType>;
  statusId?: InputMaybe<SortEnumType>;
  tattoo?: InputMaybe<SortEnumType>;
  togId?: InputMaybe<SortEnumType>;
  tpi?: InputMaybe<SortEnumType>;
  weight?: InputMaybe<SortEnumType>;
  yard?: InputMaybe<TOG_yardBaseSortInput>;
  yardBeforeChangeId?: InputMaybe<SortEnumType>;
  yardId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type DonorsCollectionSegment = {
  __typename: 'DonorsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Donor>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Embryo = {
  __typename: 'Embryo';
  abort: Maybe<Scalars['Int']['output']>;
  accountType: Maybe<Scalars['Int']['output']>;
  additionalEmbryoImplant: Maybe<Scalars['Boolean']['output']>;
  betaCasein: Maybe<Scalars['String']['output']>;
  billStatusCrt: Maybe<Scalars['Int']['output']>;
  billStatusImp: Maybe<Scalars['Int']['output']>;
  breed: Maybe<Breed>;
  breedId: Maybe<Scalars['UUID']['output']>;
  calfSex: Maybe<Scalars['Int']['output']>;
  calfType: Maybe<Scalars['Int']['output']>;
  cane: Maybe<Scalars['String']['output']>;
  cellId: Maybe<Scalars['String']['output']>;
  cellLineId: Maybe<Scalars['UUID']['output']>;
  clientRecipient: Maybe<Scalars['String']['output']>;
  clinic: Maybe<Scalars['Int']['output']>;
  clinicRanchHaulIn: Maybe<Scalars['Int']['output']>;
  comm: Maybe<Scalars['String']['output']>;
  crackedZona: Maybe<Scalars['Boolean']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  creation: Maybe<Scalars['DateTime']['output']>;
  dairyCompEmbryoId: Maybe<Scalars['Int']['output']>;
  dateUsed: Maybe<Scalars['DateTime']['output']>;
  dist: Maybe<Scalars['Int']['output']>;
  donor: Maybe<Donor>;
  donorAge: Maybe<Scalars['String']['output']>;
  donorCheeseMerit: Maybe<Scalars['Int']['output']>;
  donorId: Maybe<Scalars['UUID']['output']>;
  donorJpi: Maybe<Scalars['Int']['output']>;
  donorNetMerit: Maybe<Scalars['Int']['output']>;
  donorTpi: Maybe<Scalars['Int']['output']>;
  edonorId: Maybe<Scalars['String']['output']>;
  embTag: Maybe<Scalars['String']['output']>;
  embTagNumber: Maybe<Scalars['Int']['output']>;
  embryoOutsideEntryId: Maybe<Scalars['UUID']['output']>;
  embryoPa: Maybe<Scalars['Int']['output']>;
  embryoStagingId: Maybe<Scalars['Int']['output']>;
  equilibrationSolutionDate: Maybe<Scalars['DateTime']['output']>;
  estDueDate: Maybe<Scalars['DateTime']['output']>;
  estrusSync: Maybe<Scalars['Int']['output']>;
  estrusSyncNumber: Maybe<Scalars['Decimal']['output']>;
  exchangeRate: Maybe<Scalars['Decimal']['output']>;
  export: Maybe<Scalars['Int']['output']>;
  feeder: Maybe<Scalars['Int']['output']>;
  fertilization: Maybe<Fertilization>;
  fertilizationId: Maybe<Scalars['UUID']['output']>;
  flexFieldPartners: Maybe<Scalars['String']['output']>;
  flshId: Maybe<Scalars['String']['output']>;
  flush: Maybe<Flush>;
  flushId: Maybe<Scalars['UUID']['output']>;
  flushIdNumber: Maybe<Scalars['Int']['output']>;
  freezeComment: Maybe<Scalars['String']['output']>;
  freezeMethod: Maybe<OptionSet>;
  freezeMethodId: Maybe<Scalars['UUID']['output']>;
  freezeTech: Maybe<Equipment>;
  freezeTechId: Maybe<Scalars['UUID']['output']>;
  freezeTechName: Maybe<Scalars['String']['output']>;
  freezeTime: Maybe<Scalars['String']['output']>;
  freezer: Maybe<Scalars['String']['output']>;
  freshFrozen: Maybe<Scalars['Int']['output']>;
  freshStrawNo: Maybe<Scalars['Int']['output']>;
  frozenGrade: Maybe<Scalars['Int']['output']>;
  frozenStage: Maybe<Scalars['Int']['output']>;
  gnrhUsedAtImplant: Maybe<Scalars['Boolean']['output']>;
  grade: Maybe<Scalars['Int']['output']>;
  grader7: Maybe<Equipment>;
  grader7Id: Maybe<Scalars['UUID']['output']>;
  grader7Name: Maybe<Scalars['String']['output']>;
  grader65: Maybe<Scalars['String']['output']>;
  group: Maybe<Scalars['Int']['output']>;
  hcgApplied: Maybe<Scalars['Int']['output']>;
  heat1: Maybe<Scalars['DateTime']['output']>;
  holsteinPtanetMerit: Maybe<Scalars['Int']['output']>;
  hornStatus: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  impId: Maybe<Scalars['Int']['output']>;
  implantDate: Maybe<Scalars['DateTime']['output']>;
  implantStatusCode: Maybe<Scalars['Int']['output']>;
  implantStatusCodeValue: Maybe<Scalars['String']['output']>;
  jerseyPtanetMerit: Maybe<Scalars['Int']['output']>;
  jpi: Maybe<Scalars['Int']['output']>;
  kappaCasein: Maybe<Scalars['String']['output']>;
  label1: Maybe<Scalars['String']['output']>;
  label2: Maybe<Scalars['String']['output']>;
  lastAct: Maybe<Scalars['String']['output']>;
  loadBy: Maybe<Equipment>;
  loadById: Maybe<Scalars['UUID']['output']>;
  loadTime: Maybe<Scalars['String']['output']>;
  lowQuality: Maybe<Scalars['Boolean']['output']>;
  method: Maybe<Scalars['Int']['output']>;
  methodValue: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  oldOwner: Maybe<Account>;
  oldOwnerId: Maybe<Scalars['UUID']['output']>;
  oldStorageLocation: Maybe<StorageLocation>;
  oldStorageLocationId: Maybe<Scalars['UUID']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  origin: Maybe<Scalars['String']['output']>;
  originalFreezeSite: Maybe<Site>;
  originalFreezeSiteId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  pc1Date: Maybe<Scalars['DateTime']['output']>;
  pc1Res: Maybe<Scalars['Int']['output']>;
  pc2Date: Maybe<Scalars['DateTime']['output']>;
  pc2Res: Maybe<Scalars['Int']['output']>;
  pc3Date: Maybe<Scalars['DateTime']['output']>;
  pc3Res: Maybe<Scalars['Int']['output']>;
  pc4Date: Maybe<Scalars['DateTime']['output']>;
  pc4Res: Maybe<Scalars['Int']['output']>;
  pc5Date: Maybe<Scalars['DateTime']['output']>;
  pc5Res: Maybe<Scalars['Int']['output']>;
  pc6Date: Maybe<Scalars['DateTime']['output']>;
  pc6Res: Maybe<Scalars['Int']['output']>;
  pc7Date: Maybe<Scalars['DateTime']['output']>;
  pc7Res: Maybe<Scalars['Int']['output']>;
  pc8Date: Maybe<Scalars['DateTime']['output']>;
  pc8Res: Maybe<Scalars['Int']['output']>;
  pc9Date: Maybe<Scalars['DateTime']['output']>;
  pc9Res: Maybe<Scalars['Int']['output']>;
  plan1: Maybe<Scalars['String']['output']>;
  plan2: Maybe<Scalars['String']['output']>;
  plan3: Maybe<Scalars['String']['output']>;
  ppapy: Maybe<Scalars['Int']['output']>;
  preBook: Maybe<Scalars['Int']['output']>;
  price: Maybe<Scalars['Decimal']['output']>;
  probableDonorId: Maybe<Scalars['UUID']['output']>;
  probabledonor: Maybe<Donor>;
  profit: Maybe<Scalars['Int']['output']>;
  proj: Maybe<Scalars['String']['output']>;
  projd: Maybe<Scalars['String']['output']>;
  qcEg10: Maybe<Scalars['String']['output']>;
  qcEg10Date: Maybe<Scalars['DateTime']['output']>;
  qcEmbHolding: Maybe<Scalars['String']['output']>;
  qcEmbHoldingExpDate: Maybe<Scalars['DateTime']['output']>;
  qcSucroseDate: Maybe<Scalars['DateTime']['output']>;
  qcTryPsInDate: Maybe<Scalars['DateTime']['output']>;
  qcehmDate: Maybe<Scalars['DateTime']['output']>;
  qcfreezingComment: Maybe<Scalars['String']['output']>;
  qual: Maybe<Scalars['Int']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
  ranchValue: Maybe<Scalars['Int']['output']>;
  recId: Maybe<Scalars['String']['output']>;
  receivedOn: Maybe<Scalars['DateTime']['output']>;
  recipBirthDate: Maybe<Scalars['DateTime']['output']>;
  recipBreed: Maybe<Breed>;
  recipBreedId: Maybe<Scalars['UUID']['output']>;
  recipCalvingDate: Maybe<Scalars['DateTime']['output']>;
  recipOwner: Maybe<Account>;
  recipOwnerId: Maybe<Scalars['UUID']['output']>;
  recipient: Maybe<Recipient>;
  recipientId: Maybe<Scalars['UUID']['output']>;
  recoveryFirmCode: Maybe<Scalars['String']['output']>;
  recoveryFirmName: Maybe<Scalars['String']['output']>;
  rightLeft: Maybe<Scalars['Int']['output']>;
  rightLeftHidden: Maybe<Scalars['String']['output']>;
  scHerdman: Maybe<Scalars['String']['output']>;
  scHnDate: Maybe<Scalars['DateTime']['output']>;
  scIpDate: Maybe<Scalars['DateTime']['output']>;
  scLastHeat: Maybe<Scalars['DateTime']['output']>;
  scPreviousHeat: Maybe<Scalars['DateTime']['output']>;
  scRpDate: Maybe<Scalars['DateTime']['output']>;
  scSyDate: Maybe<Scalars['DateTime']['output']>;
  semen: Maybe<Semen>;
  semenId: Maybe<Scalars['UUID']['output']>;
  sex: Maybe<Scalars['Int']['output']>;
  sexValue: Maybe<Scalars['String']['output']>;
  shipCity: Maybe<Scalars['String']['output']>;
  shipState: Maybe<Scalars['String']['output']>;
  shipToFbd: Maybe<Scalars['String']['output']>;
  shipment: Maybe<Shipment>;
  shipmentId: Maybe<Scalars['UUID']['output']>;
  sire: Maybe<Sire>;
  sireCheeseMerit: Maybe<Scalars['Int']['output']>;
  sireId: Maybe<Scalars['UUID']['output']>;
  sireJpi: Maybe<Scalars['Int']['output']>;
  sireNetMerit: Maybe<Scalars['Int']['output']>;
  sireTpi: Maybe<Scalars['Int']['output']>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  soldTo: Maybe<Scalars['String']['output']>;
  stage: Maybe<Scalars['Int']['output']>;
  stateCode: Scalars['Int']['output'];
  status: Maybe<Scalars['Int']['output']>;
  statusCode: Maybe<Scalars['Int']['output']>;
  statusValue: Maybe<Scalars['String']['output']>;
  stopId: Maybe<Scalars['Int']['output']>;
  storageLocation: Maybe<StorageLocation>;
  storageLocationId: Maybe<Scalars['UUID']['output']>;
  straw: Maybe<Scalars['String']['output']>;
  strawNumber: Maybe<Scalars['Int']['output']>;
  sync: Maybe<Scalars['String']['output']>;
  technician1: Maybe<Equipment>;
  technician1Id: Maybe<Scalars['UUID']['output']>;
  technician1Name: Maybe<Scalars['String']['output']>;
  technician2Name: Maybe<Scalars['String']['output']>;
  thawBy: Maybe<Equipment>;
  thawById: Maybe<Scalars['UUID']['output']>;
  thawByName: Maybe<Scalars['String']['output']>;
  togId: Maybe<Scalars['Int']['output']>;
  tpi: Maybe<Scalars['Int']['output']>;
  transComment: Maybe<Scalars['String']['output']>;
  transactionCurrencyId: Maybe<Scalars['UUID']['output']>;
  transferDate: Maybe<Scalars['DateTime']['output']>;
  transferFarmLocation: Maybe<Equipment>;
  transferFarmLocationId: Maybe<Scalars['UUID']['output']>;
  transferPrice: Maybe<Scalars['Decimal']['output']>;
  transferPriceBase: Maybe<Scalars['Decimal']['output']>;
  transferTime: Maybe<Scalars['String']['output']>;
  tt: Maybe<Scalars['Boolean']['output']>;
  vialId: Maybe<Scalars['String']['output']>;
  vitrificationSolutionDate: Maybe<Scalars['DateTime']['output']>;
};

export type EmbryoFilterInput = {
  abort?: InputMaybe<IntOperationFilterInput>;
  accountType?: InputMaybe<IntOperationFilterInput>;
  additionalEmbryoImplant?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<EmbryoFilterInput>>;
  betaCasein?: InputMaybe<StringOperationFilterInput>;
  billStatusCrt?: InputMaybe<IntOperationFilterInput>;
  billStatusImp?: InputMaybe<IntOperationFilterInput>;
  breed?: InputMaybe<BreedFilterInput>;
  breedId?: InputMaybe<UuidOperationFilterInput>;
  calfSex?: InputMaybe<IntOperationFilterInput>;
  calfType?: InputMaybe<IntOperationFilterInput>;
  cane?: InputMaybe<StringOperationFilterInput>;
  cellId?: InputMaybe<StringOperationFilterInput>;
  cellLineId?: InputMaybe<UuidOperationFilterInput>;
  clientRecipient?: InputMaybe<StringOperationFilterInput>;
  clinic?: InputMaybe<IntOperationFilterInput>;
  clinicRanchHaulIn?: InputMaybe<IntOperationFilterInput>;
  comm?: InputMaybe<StringOperationFilterInput>;
  crackedZona?: InputMaybe<BooleanOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  creation?: InputMaybe<DateTimeOperationFilterInput>;
  dairyCompEmbryoId?: InputMaybe<IntOperationFilterInput>;
  dateUsed?: InputMaybe<DateTimeOperationFilterInput>;
  dist?: InputMaybe<IntOperationFilterInput>;
  donor?: InputMaybe<DonorFilterInput>;
  donorAge?: InputMaybe<StringOperationFilterInput>;
  donorCheeseMerit?: InputMaybe<IntOperationFilterInput>;
  donorId?: InputMaybe<UuidOperationFilterInput>;
  donorJpi?: InputMaybe<IntOperationFilterInput>;
  donorNetMerit?: InputMaybe<IntOperationFilterInput>;
  donorTpi?: InputMaybe<IntOperationFilterInput>;
  edonorId?: InputMaybe<StringOperationFilterInput>;
  embTag?: InputMaybe<StringOperationFilterInput>;
  embTagNumber?: InputMaybe<IntOperationFilterInput>;
  embryoOutsideEntryId?: InputMaybe<UuidOperationFilterInput>;
  embryoPa?: InputMaybe<IntOperationFilterInput>;
  embryoStagingId?: InputMaybe<IntOperationFilterInput>;
  equilibrationSolutionDate?: InputMaybe<DateTimeOperationFilterInput>;
  estDueDate?: InputMaybe<DateTimeOperationFilterInput>;
  estrusSync?: InputMaybe<IntOperationFilterInput>;
  estrusSyncNumber?: InputMaybe<DecimalOperationFilterInput>;
  exchangeRate?: InputMaybe<DecimalOperationFilterInput>;
  export?: InputMaybe<IntOperationFilterInput>;
  feeder?: InputMaybe<IntOperationFilterInput>;
  fertilization?: InputMaybe<FertilizationFilterInput>;
  fertilizationId?: InputMaybe<UuidOperationFilterInput>;
  flexFieldPartners?: InputMaybe<StringOperationFilterInput>;
  flshId?: InputMaybe<StringOperationFilterInput>;
  flush?: InputMaybe<FlushFilterInput>;
  flushId?: InputMaybe<UuidOperationFilterInput>;
  flushIdNumber?: InputMaybe<IntOperationFilterInput>;
  freezeComment?: InputMaybe<StringOperationFilterInput>;
  freezeMethod?: InputMaybe<OptionSetFilterInput>;
  freezeMethodId?: InputMaybe<UuidOperationFilterInput>;
  freezeTech?: InputMaybe<EquipmentFilterInput>;
  freezeTechId?: InputMaybe<UuidOperationFilterInput>;
  freezeTechName?: InputMaybe<StringOperationFilterInput>;
  freezeTime?: InputMaybe<StringOperationFilterInput>;
  freezer?: InputMaybe<StringOperationFilterInput>;
  freshFrozen?: InputMaybe<IntOperationFilterInput>;
  freshStrawNo?: InputMaybe<IntOperationFilterInput>;
  frozenGrade?: InputMaybe<IntOperationFilterInput>;
  frozenStage?: InputMaybe<IntOperationFilterInput>;
  gnrhUsedAtImplant?: InputMaybe<BooleanOperationFilterInput>;
  grade?: InputMaybe<IntOperationFilterInput>;
  grader7?: InputMaybe<EquipmentFilterInput>;
  grader7Id?: InputMaybe<UuidOperationFilterInput>;
  grader7Name?: InputMaybe<StringOperationFilterInput>;
  grader65?: InputMaybe<StringOperationFilterInput>;
  group?: InputMaybe<IntOperationFilterInput>;
  hcgApplied?: InputMaybe<IntOperationFilterInput>;
  heat1?: InputMaybe<DateTimeOperationFilterInput>;
  holsteinPtanetMerit?: InputMaybe<IntOperationFilterInput>;
  hornStatus?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  impId?: InputMaybe<IntOperationFilterInput>;
  implantDate?: InputMaybe<DateTimeOperationFilterInput>;
  implantStatusCode?: InputMaybe<IntOperationFilterInput>;
  jerseyPtanetMerit?: InputMaybe<IntOperationFilterInput>;
  jpi?: InputMaybe<IntOperationFilterInput>;
  kappaCasein?: InputMaybe<StringOperationFilterInput>;
  label1?: InputMaybe<StringOperationFilterInput>;
  label2?: InputMaybe<StringOperationFilterInput>;
  lastAct?: InputMaybe<StringOperationFilterInput>;
  loadBy?: InputMaybe<EquipmentFilterInput>;
  loadById?: InputMaybe<UuidOperationFilterInput>;
  loadTime?: InputMaybe<StringOperationFilterInput>;
  lowQuality?: InputMaybe<BooleanOperationFilterInput>;
  method?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldOwner?: InputMaybe<AccountFilterInput>;
  oldOwnerId?: InputMaybe<UuidOperationFilterInput>;
  oldStorageLocation?: InputMaybe<StorageLocationFilterInput>;
  oldStorageLocationId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<EmbryoFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  origin?: InputMaybe<StringOperationFilterInput>;
  originalFreezeSite?: InputMaybe<SiteFilterInput>;
  originalFreezeSiteId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  pc1Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc1Res?: InputMaybe<IntOperationFilterInput>;
  pc2Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc2Res?: InputMaybe<IntOperationFilterInput>;
  pc3Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc3Res?: InputMaybe<IntOperationFilterInput>;
  pc4Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc4Res?: InputMaybe<IntOperationFilterInput>;
  pc5Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc5Res?: InputMaybe<IntOperationFilterInput>;
  pc6Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc6Res?: InputMaybe<IntOperationFilterInput>;
  pc7Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc7Res?: InputMaybe<IntOperationFilterInput>;
  pc8Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc8Res?: InputMaybe<IntOperationFilterInput>;
  pc9Date?: InputMaybe<DateTimeOperationFilterInput>;
  pc9Res?: InputMaybe<IntOperationFilterInput>;
  plan1?: InputMaybe<StringOperationFilterInput>;
  plan2?: InputMaybe<StringOperationFilterInput>;
  plan3?: InputMaybe<StringOperationFilterInput>;
  ppapy?: InputMaybe<IntOperationFilterInput>;
  preBook?: InputMaybe<IntOperationFilterInput>;
  price?: InputMaybe<DecimalOperationFilterInput>;
  probableDonorId?: InputMaybe<UuidOperationFilterInput>;
  probabledonor?: InputMaybe<DonorFilterInput>;
  profit?: InputMaybe<IntOperationFilterInput>;
  proj?: InputMaybe<StringOperationFilterInput>;
  projd?: InputMaybe<StringOperationFilterInput>;
  qcEg10?: InputMaybe<StringOperationFilterInput>;
  qcEg10Date?: InputMaybe<DateTimeOperationFilterInput>;
  qcEmbHolding?: InputMaybe<StringOperationFilterInput>;
  qcEmbHoldingExpDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcSucroseDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcTryPsInDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcehmDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcfreezingComment?: InputMaybe<StringOperationFilterInput>;
  qual?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  ranchValue?: InputMaybe<IntOperationFilterInput>;
  recId?: InputMaybe<StringOperationFilterInput>;
  receivedOn?: InputMaybe<DateTimeOperationFilterInput>;
  recipBirthDate?: InputMaybe<DateTimeOperationFilterInput>;
  recipBreed?: InputMaybe<BreedFilterInput>;
  recipBreedId?: InputMaybe<UuidOperationFilterInput>;
  recipCalvingDate?: InputMaybe<DateTimeOperationFilterInput>;
  recipOwner?: InputMaybe<AccountFilterInput>;
  recipOwnerId?: InputMaybe<UuidOperationFilterInput>;
  recipient?: InputMaybe<RecipientFilterInput>;
  recipientId?: InputMaybe<UuidOperationFilterInput>;
  recoveryFirmCode?: InputMaybe<StringOperationFilterInput>;
  recoveryFirmName?: InputMaybe<StringOperationFilterInput>;
  rightLeft?: InputMaybe<IntOperationFilterInput>;
  rightLeftHidden?: InputMaybe<StringOperationFilterInput>;
  scHerdman?: InputMaybe<StringOperationFilterInput>;
  scHnDate?: InputMaybe<DateTimeOperationFilterInput>;
  scIpDate?: InputMaybe<DateTimeOperationFilterInput>;
  scLastHeat?: InputMaybe<DateTimeOperationFilterInput>;
  scPreviousHeat?: InputMaybe<DateTimeOperationFilterInput>;
  scRpDate?: InputMaybe<DateTimeOperationFilterInput>;
  scSyDate?: InputMaybe<DateTimeOperationFilterInput>;
  semen?: InputMaybe<SemenFilterInput>;
  semenId?: InputMaybe<UuidOperationFilterInput>;
  sex?: InputMaybe<IntOperationFilterInput>;
  shipCity?: InputMaybe<StringOperationFilterInput>;
  shipState?: InputMaybe<StringOperationFilterInput>;
  shipToFbd?: InputMaybe<StringOperationFilterInput>;
  shipment?: InputMaybe<ShipmentFilterInput>;
  shipmentId?: InputMaybe<UuidOperationFilterInput>;
  sire?: InputMaybe<SireFilterInput>;
  sireCheeseMerit?: InputMaybe<IntOperationFilterInput>;
  sireId?: InputMaybe<UuidOperationFilterInput>;
  sireJpi?: InputMaybe<IntOperationFilterInput>;
  sireNetMerit?: InputMaybe<IntOperationFilterInput>;
  sireTpi?: InputMaybe<IntOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  soldTo?: InputMaybe<StringOperationFilterInput>;
  stage?: InputMaybe<IntOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  stopId?: InputMaybe<IntOperationFilterInput>;
  storageLocation?: InputMaybe<StorageLocationFilterInput>;
  storageLocationId?: InputMaybe<UuidOperationFilterInput>;
  straw?: InputMaybe<StringOperationFilterInput>;
  strawNumber?: InputMaybe<IntOperationFilterInput>;
  sync?: InputMaybe<StringOperationFilterInput>;
  technician1?: InputMaybe<EquipmentFilterInput>;
  technician1Id?: InputMaybe<UuidOperationFilterInput>;
  technician1Name?: InputMaybe<StringOperationFilterInput>;
  technician2Name?: InputMaybe<StringOperationFilterInput>;
  thawBy?: InputMaybe<EquipmentFilterInput>;
  thawById?: InputMaybe<UuidOperationFilterInput>;
  thawByName?: InputMaybe<StringOperationFilterInput>;
  togId?: InputMaybe<IntOperationFilterInput>;
  tpi?: InputMaybe<IntOperationFilterInput>;
  transComment?: InputMaybe<StringOperationFilterInput>;
  transactionCurrencyId?: InputMaybe<UuidOperationFilterInput>;
  transferDate?: InputMaybe<DateTimeOperationFilterInput>;
  transferFarmLocation?: InputMaybe<EquipmentFilterInput>;
  transferFarmLocationId?: InputMaybe<UuidOperationFilterInput>;
  transferPrice?: InputMaybe<DecimalOperationFilterInput>;
  transferPriceBase?: InputMaybe<DecimalOperationFilterInput>;
  transferTime?: InputMaybe<StringOperationFilterInput>;
  tt?: InputMaybe<BooleanOperationFilterInput>;
  vialId?: InputMaybe<StringOperationFilterInput>;
  vitrificationSolutionDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type EmbryoSortInput = {
  abort?: InputMaybe<SortEnumType>;
  accountType?: InputMaybe<SortEnumType>;
  additionalEmbryoImplant?: InputMaybe<SortEnumType>;
  betaCasein?: InputMaybe<SortEnumType>;
  billStatusCrt?: InputMaybe<SortEnumType>;
  billStatusImp?: InputMaybe<SortEnumType>;
  breed?: InputMaybe<BreedSortInput>;
  breedId?: InputMaybe<SortEnumType>;
  calfSex?: InputMaybe<SortEnumType>;
  calfType?: InputMaybe<SortEnumType>;
  cane?: InputMaybe<SortEnumType>;
  cellId?: InputMaybe<SortEnumType>;
  cellLineId?: InputMaybe<SortEnumType>;
  clientRecipient?: InputMaybe<SortEnumType>;
  clinic?: InputMaybe<SortEnumType>;
  clinicRanchHaulIn?: InputMaybe<SortEnumType>;
  comm?: InputMaybe<SortEnumType>;
  crackedZona?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  creation?: InputMaybe<SortEnumType>;
  dairyCompEmbryoId?: InputMaybe<SortEnumType>;
  dateUsed?: InputMaybe<SortEnumType>;
  dist?: InputMaybe<SortEnumType>;
  donor?: InputMaybe<DonorSortInput>;
  donorAge?: InputMaybe<SortEnumType>;
  donorCheeseMerit?: InputMaybe<SortEnumType>;
  donorId?: InputMaybe<SortEnumType>;
  donorJpi?: InputMaybe<SortEnumType>;
  donorNetMerit?: InputMaybe<SortEnumType>;
  donorTpi?: InputMaybe<SortEnumType>;
  edonorId?: InputMaybe<SortEnumType>;
  embTag?: InputMaybe<SortEnumType>;
  embTagNumber?: InputMaybe<SortEnumType>;
  embryoOutsideEntryId?: InputMaybe<SortEnumType>;
  embryoPa?: InputMaybe<SortEnumType>;
  embryoStagingId?: InputMaybe<SortEnumType>;
  equilibrationSolutionDate?: InputMaybe<SortEnumType>;
  estDueDate?: InputMaybe<SortEnumType>;
  estrusSync?: InputMaybe<SortEnumType>;
  estrusSyncNumber?: InputMaybe<SortEnumType>;
  exchangeRate?: InputMaybe<SortEnumType>;
  export?: InputMaybe<SortEnumType>;
  feeder?: InputMaybe<SortEnumType>;
  fertilization?: InputMaybe<FertilizationSortInput>;
  fertilizationId?: InputMaybe<SortEnumType>;
  flexFieldPartners?: InputMaybe<SortEnumType>;
  flshId?: InputMaybe<SortEnumType>;
  flush?: InputMaybe<FlushSortInput>;
  flushId?: InputMaybe<SortEnumType>;
  flushIdNumber?: InputMaybe<SortEnumType>;
  freezeComment?: InputMaybe<SortEnumType>;
  freezeMethod?: InputMaybe<OptionSetSortInput>;
  freezeMethodId?: InputMaybe<SortEnumType>;
  freezeTech?: InputMaybe<EquipmentSortInput>;
  freezeTechId?: InputMaybe<SortEnumType>;
  freezeTechName?: InputMaybe<SortEnumType>;
  freezeTime?: InputMaybe<SortEnumType>;
  freezer?: InputMaybe<SortEnumType>;
  freshFrozen?: InputMaybe<SortEnumType>;
  freshStrawNo?: InputMaybe<SortEnumType>;
  frozenGrade?: InputMaybe<SortEnumType>;
  frozenStage?: InputMaybe<SortEnumType>;
  gnrhUsedAtImplant?: InputMaybe<SortEnumType>;
  grade?: InputMaybe<SortEnumType>;
  grader7?: InputMaybe<EquipmentSortInput>;
  grader7Id?: InputMaybe<SortEnumType>;
  grader7Name?: InputMaybe<SortEnumType>;
  grader65?: InputMaybe<SortEnumType>;
  group?: InputMaybe<SortEnumType>;
  hcgApplied?: InputMaybe<SortEnumType>;
  heat1?: InputMaybe<SortEnumType>;
  holsteinPtanetMerit?: InputMaybe<SortEnumType>;
  hornStatus?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  impId?: InputMaybe<SortEnumType>;
  implantDate?: InputMaybe<SortEnumType>;
  implantStatusCode?: InputMaybe<SortEnumType>;
  jerseyPtanetMerit?: InputMaybe<SortEnumType>;
  jpi?: InputMaybe<SortEnumType>;
  kappaCasein?: InputMaybe<SortEnumType>;
  label1?: InputMaybe<SortEnumType>;
  label2?: InputMaybe<SortEnumType>;
  lastAct?: InputMaybe<SortEnumType>;
  loadBy?: InputMaybe<EquipmentSortInput>;
  loadById?: InputMaybe<SortEnumType>;
  loadTime?: InputMaybe<SortEnumType>;
  lowQuality?: InputMaybe<SortEnumType>;
  method?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  oldOwner?: InputMaybe<AccountSortInput>;
  oldOwnerId?: InputMaybe<SortEnumType>;
  oldStorageLocation?: InputMaybe<StorageLocationSortInput>;
  oldStorageLocationId?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  origin?: InputMaybe<SortEnumType>;
  originalFreezeSite?: InputMaybe<SiteSortInput>;
  originalFreezeSiteId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  pc1Date?: InputMaybe<SortEnumType>;
  pc1Res?: InputMaybe<SortEnumType>;
  pc2Date?: InputMaybe<SortEnumType>;
  pc2Res?: InputMaybe<SortEnumType>;
  pc3Date?: InputMaybe<SortEnumType>;
  pc3Res?: InputMaybe<SortEnumType>;
  pc4Date?: InputMaybe<SortEnumType>;
  pc4Res?: InputMaybe<SortEnumType>;
  pc5Date?: InputMaybe<SortEnumType>;
  pc5Res?: InputMaybe<SortEnumType>;
  pc6Date?: InputMaybe<SortEnumType>;
  pc6Res?: InputMaybe<SortEnumType>;
  pc7Date?: InputMaybe<SortEnumType>;
  pc7Res?: InputMaybe<SortEnumType>;
  pc8Date?: InputMaybe<SortEnumType>;
  pc8Res?: InputMaybe<SortEnumType>;
  pc9Date?: InputMaybe<SortEnumType>;
  pc9Res?: InputMaybe<SortEnumType>;
  plan1?: InputMaybe<SortEnumType>;
  plan2?: InputMaybe<SortEnumType>;
  plan3?: InputMaybe<SortEnumType>;
  ppapy?: InputMaybe<SortEnumType>;
  preBook?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  probableDonorId?: InputMaybe<SortEnumType>;
  probabledonor?: InputMaybe<DonorSortInput>;
  profit?: InputMaybe<SortEnumType>;
  proj?: InputMaybe<SortEnumType>;
  projd?: InputMaybe<SortEnumType>;
  qcEg10?: InputMaybe<SortEnumType>;
  qcEg10Date?: InputMaybe<SortEnumType>;
  qcEmbHolding?: InputMaybe<SortEnumType>;
  qcEmbHoldingExpDate?: InputMaybe<SortEnumType>;
  qcSucroseDate?: InputMaybe<SortEnumType>;
  qcTryPsInDate?: InputMaybe<SortEnumType>;
  qcehmDate?: InputMaybe<SortEnumType>;
  qcfreezingComment?: InputMaybe<SortEnumType>;
  qual?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  ranchValue?: InputMaybe<SortEnumType>;
  recId?: InputMaybe<SortEnumType>;
  receivedOn?: InputMaybe<SortEnumType>;
  recipBirthDate?: InputMaybe<SortEnumType>;
  recipBreed?: InputMaybe<BreedSortInput>;
  recipBreedId?: InputMaybe<SortEnumType>;
  recipCalvingDate?: InputMaybe<SortEnumType>;
  recipOwner?: InputMaybe<AccountSortInput>;
  recipOwnerId?: InputMaybe<SortEnumType>;
  recipient?: InputMaybe<RecipientSortInput>;
  recipientId?: InputMaybe<SortEnumType>;
  recoveryFirmCode?: InputMaybe<SortEnumType>;
  recoveryFirmName?: InputMaybe<SortEnumType>;
  rightLeft?: InputMaybe<SortEnumType>;
  rightLeftHidden?: InputMaybe<SortEnumType>;
  scHerdman?: InputMaybe<SortEnumType>;
  scHnDate?: InputMaybe<SortEnumType>;
  scIpDate?: InputMaybe<SortEnumType>;
  scLastHeat?: InputMaybe<SortEnumType>;
  scPreviousHeat?: InputMaybe<SortEnumType>;
  scRpDate?: InputMaybe<SortEnumType>;
  scSyDate?: InputMaybe<SortEnumType>;
  semen?: InputMaybe<SemenSortInput>;
  semenId?: InputMaybe<SortEnumType>;
  sex?: InputMaybe<SortEnumType>;
  shipCity?: InputMaybe<SortEnumType>;
  shipState?: InputMaybe<SortEnumType>;
  shipToFbd?: InputMaybe<SortEnumType>;
  shipment?: InputMaybe<ShipmentSortInput>;
  shipmentId?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SireSortInput>;
  sireCheeseMerit?: InputMaybe<SortEnumType>;
  sireId?: InputMaybe<SortEnumType>;
  sireJpi?: InputMaybe<SortEnumType>;
  sireNetMerit?: InputMaybe<SortEnumType>;
  sireTpi?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  soldTo?: InputMaybe<SortEnumType>;
  stage?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  stopId?: InputMaybe<SortEnumType>;
  storageLocation?: InputMaybe<StorageLocationSortInput>;
  storageLocationId?: InputMaybe<SortEnumType>;
  straw?: InputMaybe<SortEnumType>;
  strawNumber?: InputMaybe<SortEnumType>;
  sync?: InputMaybe<SortEnumType>;
  technician1?: InputMaybe<EquipmentSortInput>;
  technician1Id?: InputMaybe<SortEnumType>;
  technician1Name?: InputMaybe<SortEnumType>;
  technician2Name?: InputMaybe<SortEnumType>;
  thawBy?: InputMaybe<EquipmentSortInput>;
  thawById?: InputMaybe<SortEnumType>;
  thawByName?: InputMaybe<SortEnumType>;
  togId?: InputMaybe<SortEnumType>;
  tpi?: InputMaybe<SortEnumType>;
  transComment?: InputMaybe<SortEnumType>;
  transactionCurrencyId?: InputMaybe<SortEnumType>;
  transferDate?: InputMaybe<SortEnumType>;
  transferFarmLocation?: InputMaybe<EquipmentSortInput>;
  transferFarmLocationId?: InputMaybe<SortEnumType>;
  transferPrice?: InputMaybe<SortEnumType>;
  transferPriceBase?: InputMaybe<SortEnumType>;
  transferTime?: InputMaybe<SortEnumType>;
  tt?: InputMaybe<SortEnumType>;
  vialId?: InputMaybe<SortEnumType>;
  vitrificationSolutionDate?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EmbryosCollectionSegment = {
  __typename: 'EmbryosCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Embryo>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Equipment = {
  __typename: 'Equipment';
  activeInactive: Maybe<Scalars['Boolean']['output']>;
  assistantAspirations: Array<Aspiration>;
  businessUnitId: Scalars['UUID']['output'];
  calendar: Maybe<Calendar>;
  calendarId: Scalars['UUID']['output'];
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayInServiceViews: Maybe<Scalars['Boolean']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  embryologist: Maybe<Scalars['Boolean']['output']>;
  equipmentType: Maybe<Scalars['Int']['output']>;
  etSearch: Maybe<Scalars['String']['output']>;
  farmlocation: Maybe<Scalars['Boolean']['output']>;
  fertValidatorFertilizations: Array<Fertilization>;
  flushTechnicianFlushes: Array<Flush>;
  freezeTechnicianEmbryos: Array<Embryo>;
  freezeTechnicianFertilizations: Array<Fertilization>;
  frozeValidatorFertilizations: Array<Fertilization>;
  grader7Embryos: Array<Embryo>;
  graderFertilizations: Array<Fertilization>;
  graderFlushes: Array<Flush>;
  herdsman: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  inTraining: Maybe<Scalars['Boolean']['output']>;
  isDisabled: Maybe<Scalars['Boolean']['output']>;
  ivcTechnicianFertilizations: Array<Fertilization>;
  ivfLabTechnician1Fertilizations: Array<Fertilization>;
  ivfLabTechnician2Fertilizations: Array<Fertilization>;
  ivmTechnicianAspirations: Array<Aspiration>;
  loadByEmbryos: Array<Embryo>;
  lowProdValidatorFertilizations: Array<Fertilization>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['UUID']['output'];
  packedShipments: Array<Shipment>;
  predictedByFertilizations: Array<Fertilization>;
  processingTechnicianFertilizations: Array<Fertilization>;
  shipValidatorFertilizations: Array<Fertilization>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  skills: Maybe<Scalars['String']['output']>;
  systemUser: Maybe<SystemUser>;
  systemUserId: Maybe<Scalars['UUID']['output']>;
  technician: Maybe<Scalars['Boolean']['output']>;
  technician1Embryos: Array<Embryo>;
  technicianAspirations: Array<Aspiration>;
  thawByEmbryos: Array<Embryo>;
  timeZoneCode: Maybe<Scalars['Int']['output']>;
  togEmployee: Maybe<Scalars['Int']['output']>;
  transValidatorFertilizations: Array<Fertilization>;
  transferFarmLocationEmbryos: Array<Embryo>;
  veterinarian: Maybe<Scalars['Boolean']['output']>;
};

export type EquipmentFilterInput = {
  activeInactive?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<EquipmentFilterInput>>;
  assistantAspirations?: InputMaybe<ListFilterInputTypeOfAspirationFilterInput>;
  businessUnitId?: InputMaybe<UuidOperationFilterInput>;
  calendar?: InputMaybe<CalendarFilterInput>;
  calendarId?: InputMaybe<UuidOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  displayInServiceViews?: InputMaybe<BooleanOperationFilterInput>;
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  embryologist?: InputMaybe<BooleanOperationFilterInput>;
  equipmentType?: InputMaybe<IntOperationFilterInput>;
  etSearch?: InputMaybe<StringOperationFilterInput>;
  farmlocation?: InputMaybe<BooleanOperationFilterInput>;
  fertValidatorFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  flushTechnicianFlushes?: InputMaybe<ListFilterInputTypeOfFlushFilterInput>;
  freezeTechnicianEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  freezeTechnicianFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  frozeValidatorFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  grader7Embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  graderFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  graderFlushes?: InputMaybe<ListFilterInputTypeOfFlushFilterInput>;
  herdsman?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  inTraining?: InputMaybe<BooleanOperationFilterInput>;
  isDisabled?: InputMaybe<BooleanOperationFilterInput>;
  ivcTechnicianFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  ivfLabTechnician1Fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  ivfLabTechnician2Fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  ivmTechnicianAspirations?: InputMaybe<ListFilterInputTypeOfAspirationFilterInput>;
  loadByEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  lowProdValidatorFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EquipmentFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  packedShipments?: InputMaybe<ListFilterInputTypeOfShipmentFilterInput>;
  predictedByFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  processingTechnicianFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  shipValidatorFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  skills?: InputMaybe<StringOperationFilterInput>;
  systemUser?: InputMaybe<SystemUserFilterInput>;
  systemUserId?: InputMaybe<UuidOperationFilterInput>;
  technician?: InputMaybe<BooleanOperationFilterInput>;
  technician1Embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  technicianAspirations?: InputMaybe<ListFilterInputTypeOfAspirationFilterInput>;
  thawByEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  timeZoneCode?: InputMaybe<IntOperationFilterInput>;
  togEmployee?: InputMaybe<IntOperationFilterInput>;
  transValidatorFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  transferFarmLocationEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  veterinarian?: InputMaybe<BooleanOperationFilterInput>;
};

export type EquipmentSortInput = {
  activeInactive?: InputMaybe<SortEnumType>;
  businessUnitId?: InputMaybe<SortEnumType>;
  calendar?: InputMaybe<CalendarSortInput>;
  calendarId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  displayInServiceViews?: InputMaybe<SortEnumType>;
  emailAddress?: InputMaybe<SortEnumType>;
  embryologist?: InputMaybe<SortEnumType>;
  equipmentType?: InputMaybe<SortEnumType>;
  etSearch?: InputMaybe<SortEnumType>;
  farmlocation?: InputMaybe<SortEnumType>;
  herdsman?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  inTraining?: InputMaybe<SortEnumType>;
  isDisabled?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  skills?: InputMaybe<SortEnumType>;
  systemUser?: InputMaybe<SystemUserSortInput>;
  systemUserId?: InputMaybe<SortEnumType>;
  technician?: InputMaybe<SortEnumType>;
  timeZoneCode?: InputMaybe<SortEnumType>;
  togEmployee?: InputMaybe<SortEnumType>;
  veterinarian?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EquipmentsCollectionSegment = {
  __typename: 'EquipmentsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Equipment>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Feature = {
  __typename: 'Feature';
  accounts: Array<FeatureTogAccount>;
  adGroups: Array<FeatureAdGroup>;
  adUsers: Array<FeatureAdUser>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type FeatureAdGroup = {
  __typename: 'FeatureAdGroup';
  adGroup: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  feature: Feature;
  featureId: Scalars['UUID']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type FeatureAdGroupFilterInput = {
  adGroup?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<FeatureAdGroupFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  feature?: InputMaybe<FeatureFilterInput>;
  featureId?: InputMaybe<UuidOperationFilterInput>;
  isEnabled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<FeatureAdGroupFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type FeatureAdUser = {
  __typename: 'FeatureAdUser';
  adUserId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  feature: Feature;
  featureId: Scalars['UUID']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<SystemUser>;
};

export type FeatureAdUserFilterInput = {
  adUserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<FeatureAdUserFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  feature?: InputMaybe<FeatureFilterInput>;
  featureId?: InputMaybe<UuidOperationFilterInput>;
  isEnabled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<FeatureAdUserFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type FeatureFilterInput = {
  accounts?: InputMaybe<ListFilterInputTypeOfFeatureTogAccountFilterInput>;
  adGroups?: InputMaybe<ListFilterInputTypeOfFeatureAdGroupFilterInput>;
  adUsers?: InputMaybe<ListFilterInputTypeOfFeatureAdUserFilterInput>;
  and?: InputMaybe<Array<FeatureFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isEnabled?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FeatureFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type FeatureSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isEnabled?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type FeatureTogAccount = {
  __typename: 'FeatureTogAccount';
  account: Account;
  accountId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  feature: Feature;
  featureId: Scalars['UUID']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type FeatureTogAccountFilterInput = {
  accountId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<FeatureTogAccountFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  feature?: InputMaybe<FeatureFilterInput>;
  featureId?: InputMaybe<UuidOperationFilterInput>;
  isEnabled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<FeatureTogAccountFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** A segment of a collection. */
export type FeaturesCollectionSegment = {
  __typename: 'FeaturesCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Feature>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Fertilization = {
  __typename: 'Fertilization';
  aspiration: Maybe<Aspiration>;
  aspirationId: Maybe<Scalars['UUID']['output']>;
  aspirationPool: Maybe<AspirationPool>;
  aspirationPoolId: Maybe<Scalars['UUID']['output']>;
  batch: Maybe<Scalars['String']['output']>;
  billIncubator: Maybe<Scalars['Boolean']['output']>;
  billIncubator2: Maybe<Scalars['Boolean']['output']>;
  billShipping: Maybe<Scalars['Boolean']['output']>;
  billStatus: Maybe<Scalars['Int']['output']>;
  billUnits: Maybe<Scalars['Decimal']['output']>;
  billshipping2: Maybe<Scalars['Boolean']['output']>;
  breed: Maybe<Breed>;
  bsVerified: Maybe<Scalars['Boolean']['output']>;
  cleaved: Maybe<Scalars['Int']['output']>;
  /** @deprecated use tog_discardclient instead */
  clientDiscard: Maybe<Scalars['Int']['output']>;
  clientGrade1: Maybe<Scalars['Int']['output']>;
  clientGrade2: Maybe<Scalars['Int']['output']>;
  clientGrade3: Maybe<Scalars['Int']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  conc: Maybe<Scalars['Float']['output']>;
  createdBy: Maybe<SystemUser>;
  createdByApp: Maybe<Scalars['Int']['output']>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  credSemenComment: Maybe<Scalars['String']['output']>;
  creditSemen: Maybe<Scalars['Int']['output']>;
  day5esttrans: Maybe<Scalars['Int']['output']>;
  deRecip: Maybe<Scalars['String']['output']>;
  deYard: Maybe<TOG_yardBase>;
  deYardId: Maybe<Scalars['UUID']['output']>;
  debugInfo: Maybe<Scalars['String']['output']>;
  degenerates: Maybe<Scalars['Int']['output']>;
  discardClient: Maybe<Scalars['Int']['output']>;
  discarded: Maybe<Scalars['Int']['output']>;
  discardedGrade1: Maybe<Scalars['Int']['output']>;
  discardedGrade2: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `donorSchedule.donor` instead */
  donor: Maybe<Donor>;
  /** @deprecated Use `donorSchedule.donorId` instead */
  donorId: Maybe<Scalars['UUID']['output']>;
  donorSchedule: Maybe<DonorSchedule>;
  donorScheduleId: Maybe<Scalars['UUID']['output']>;
  donorSchedules: Array<DonorSchedule>;
  embryoScore: Maybe<Scalars['Int']['output']>;
  embryos: Array<Embryo>;
  errorCode: Maybe<Scalars['Decimal']['output']>;
  export: Maybe<Scalars['Int']['output']>;
  fertValidationDate: Maybe<Scalars['DateTime']['output']>;
  fertValidator: Maybe<Equipment>;
  fertValidatorId: Maybe<Scalars['UUID']['output']>;
  flexFieldPartner: Maybe<Scalars['String']['output']>;
  flshId: Maybe<Scalars['String']['output']>;
  flushIdNumber: Maybe<Scalars['Int']['output']>;
  freezable: Maybe<Scalars['Int']['output']>;
  freezeComment: Maybe<Scalars['String']['output']>;
  freezeMethod: Maybe<OptionSet>;
  freezeMethodId: Maybe<Scalars['UUID']['output']>;
  freezeTechnician: Maybe<Equipment>;
  freezeTechnicianId: Maybe<Scalars['UUID']['output']>;
  freshShipmentId: Maybe<Scalars['UUID']['output']>;
  freshShipmentId2: Maybe<Scalars['UUID']['output']>;
  frozeClient: Maybe<Scalars['Int']['output']>;
  frozeValidationDate: Maybe<Scalars['DateTime']['output']>;
  frozeValidator: Maybe<Equipment>;
  frozeValidatorId: Maybe<Scalars['UUID']['output']>;
  frozen: Maybe<Scalars['Int']['output']>;
  frzCane: Maybe<Scalars['String']['output']>;
  frzFreezeMethod: Maybe<OptionSet>;
  frzFreezeMethodId: Maybe<Scalars['UUID']['output']>;
  frzFreezeTechnician: Maybe<Scalars['String']['output']>;
  frzFreezeTime: Maybe<Scalars['String']['output']>;
  frzFreezer: Maybe<Scalars['String']['output']>;
  frzOwner: Maybe<Account>;
  frzOwnerId: Maybe<Scalars['UUID']['output']>;
  frzStorageLocation: Maybe<StorageLocation>;
  frzStorageLocationId: Maybe<Scalars['UUID']['output']>;
  frzTt: Maybe<Scalars['Boolean']['output']>;
  gpBatch: Maybe<Scalars['String']['output']>;
  grade1: Maybe<Scalars['Int']['output']>;
  grade2: Maybe<Scalars['Int']['output']>;
  grade2Minus: Maybe<Scalars['Int']['output']>;
  grade3: Maybe<Scalars['Int']['output']>;
  grade42Minus: Maybe<Scalars['Int']['output']>;
  grader: Maybe<Equipment>;
  grader6: Maybe<Scalars['String']['output']>;
  graderId: Maybe<Scalars['UUID']['output']>;
  hasFewMotileSperm: Maybe<Scalars['Boolean']['output']>;
  hasNoMotileSperm: Maybe<Scalars['Boolean']['output']>;
  hasPoorCytoplasmQuality: Maybe<Scalars['Boolean']['output']>;
  hasTwoCellCleavage: Maybe<Scalars['Boolean']['output']>;
  heldAtTogForTransfer: Maybe<Scalars['Int']['output']>;
  heldAtTogFreezeTransferLowGrade: Maybe<Scalars['Int']['output']>;
  heldAtTogToFreeze: Maybe<Scalars['Int']['output']>;
  heparIn: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  implantPriceOf: Maybe<Scalars['Decimal']['output']>;
  implantTierOf: Maybe<Scalars['Int']['output']>;
  isFragile: Maybe<Scalars['Boolean']['output']>;
  isHardToStrip: Maybe<Scalars['Boolean']['output']>;
  ivcBroken: Maybe<Scalars['Int']['output']>;
  ivcComment: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `strippedAt` instead */
  ivcDate: Maybe<Scalars['DateTime']['output']>;
  ivcDead: Maybe<Scalars['Int']['output']>;
  ivcExtra: Maybe<Scalars['Int']['output']>;
  ivcImmature: Maybe<Scalars['Int']['output']>;
  ivcMissing: Maybe<Scalars['Int']['output']>;
  ivcNumber: Maybe<Scalars['Int']['output']>;
  ivcTechnician: Maybe<Equipment>;
  ivcTechnicianId: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Use `strippedAt` instead */
  ivcTime: Maybe<Scalars['String']['output']>;
  ivfDate: Maybe<Scalars['Date']['output']>;
  ivfLabTechnician1: Maybe<Equipment>;
  ivfLabTechnician1Id: Maybe<Scalars['UUID']['output']>;
  ivfLabTechnician2: Maybe<Equipment>;
  ivfLabTechnician2Id: Maybe<Scalars['UUID']['output']>;
  ivfNumber: Maybe<Scalars['Int']['output']>;
  ivfTime: Maybe<Scalars['String']['output']>;
  loadDonorPartners: Maybe<Scalars['Int']['output']>;
  loadDonorPartnersFrz: Maybe<Scalars['Int']['output']>;
  loadedInStraws: Maybe<Scalars['Int']['output']>;
  lowProdValidationDate: Maybe<Scalars['DateTime']['output']>;
  lowProdValidator: Maybe<Equipment>;
  lowProdValidatorId: Maybe<Scalars['UUID']['output']>;
  mating: Maybe<Scalars['String']['output']>;
  matings: Maybe<Scalars['Int']['output']>;
  matings2: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  noEmbryosProduced: Maybe<tog_noembryosproduced>;
  number: Maybe<Scalars['Int']['output']>;
  oocyteScore: Maybe<Scalars['Int']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  origHeldAtTog: Maybe<Scalars['Int']['output']>;
  origShipped: Maybe<Scalars['Int']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  packagingFee: Maybe<Scalars['Boolean']['output']>;
  packagingFee2: Maybe<Scalars['Boolean']['output']>;
  plan: Maybe<Scalars['Int']['output']>;
  plate: Maybe<Scalars['Int']['output']>;
  plateNumber: Maybe<Scalars['Decimal']['output']>;
  predictedBy: Maybe<Equipment>;
  predictedById: Maybe<Scalars['UUID']['output']>;
  predictedByName: Maybe<Scalars['String']['output']>;
  predictedComment: Maybe<Scalars['String']['output']>;
  predictedGood: Maybe<Scalars['Int']['output']>;
  predictedLow: Maybe<Scalars['Int']['output']>;
  predictedVeryLow: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `donorSchedule.donor` instead */
  probableDonor: Maybe<Donor>;
  /** @deprecated Use `donorSchedule.donorId` instead */
  probableDonorId: Maybe<Scalars['UUID']['output']>;
  procId: Maybe<Scalars['String']['output']>;
  processingTechnician: Maybe<Equipment>;
  processingTechnicianId: Maybe<Scalars['UUID']['output']>;
  prodres: Maybe<Scalars['Int']['output']>;
  qcCultureIncubator: Maybe<Scalars['String']['output']>;
  qcCulturePlate: Maybe<Scalars['String']['output']>;
  qcFertMedia: Maybe<Scalars['DateTime']['output']>;
  qcFertPlate: Maybe<Scalars['String']['output']>;
  qcHoldingsOfDate: Maybe<Scalars['DateTime']['output']>;
  qcIsolate: Maybe<Scalars['String']['output']>;
  qcMineralOil: Maybe<Scalars['String']['output']>;
  qcSerum: Maybe<Scalars['String']['output']>;
  qcShippingsOfDate: Maybe<Scalars['DateTime']['output']>;
  qcSofDate: Maybe<Scalars['DateTime']['output']>;
  recipOwner: Maybe<Account>;
  recipOwnerId: Maybe<Scalars['UUID']['output']>;
  recipient: Maybe<Recipient>;
  recipientId: Maybe<Scalars['UUID']['output']>;
  resultsComment: Maybe<Scalars['String']['output']>;
  rsPool: Maybe<Scalars['Int']['output']>;
  semen: Maybe<Semen>;
  semenFee: Maybe<Scalars['Decimal']['output']>;
  semenId: Maybe<Scalars['UUID']['output']>;
  semenOwner: Maybe<Account>;
  semenOwnerId: Maybe<Scalars['UUID']['output']>;
  semenScore: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `donorSchedule.semenType` instead */
  semenType: Maybe<Scalars['Int']['output']>;
  semenUnits: Maybe<Scalars['Int']['output']>;
  shipGroup: Maybe<Scalars['String']['output']>;
  shipRecipGroup: Maybe<Scalars['String']['output']>;
  shipValidationDate: Maybe<Scalars['DateTime']['output']>;
  shipValidator: Maybe<Equipment>;
  shipValidatorId: Maybe<Scalars['UUID']['output']>;
  shipmentGroupId: Maybe<Scalars['UUID']['output']>;
  shipmentGroupMaster2Id: Maybe<Scalars['UUID']['output']>;
  shipmentGroupMasterId: Maybe<Scalars['UUID']['output']>;
  shipped: Maybe<Scalars['Int']['output']>;
  shipped2: Maybe<Scalars['Int']['output']>;
  shippedBillable: Maybe<Scalars['Decimal']['output']>;
  shippedBillable2: Maybe<Scalars['Decimal']['output']>;
  shippedTog: Maybe<Scalars['Int']['output']>;
  shippedTog2: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `donorSchedule.sire` instead */
  sire: Maybe<Sire>;
  /** @deprecated Use `donorSchedule.sireId` instead */
  sireId: Maybe<Scalars['UUID']['output']>;
  sireTogIds: Maybe<Scalars['String']['output']>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  /** Combines ivcDate + ivcTime in UTC */
  strippedAt: Maybe<Scalars['DateTime']['output']>;
  transValidationDate: Maybe<Scalars['DateTime']['output']>;
  transValidator: Maybe<Equipment>;
  transValidatorId: Maybe<Scalars['UUID']['output']>;
  transferClient: Maybe<Scalars['Int']['output']>;
  transferable: Maybe<Scalars['Int']['output']>;
  transferred: Maybe<Scalars['Int']['output']>;
  trfClinicRanchHaulIn: Maybe<Scalars['Int']['output']>;
  trfOwner: Maybe<Account>;
  trfOwnerId: Maybe<Scalars['UUID']['output']>;
  trfSite: Maybe<Site>;
  trfSiteId: Maybe<Scalars['UUID']['output']>;
  tt: Maybe<Scalars['Boolean']['output']>;
  tubeNumber: Maybe<Scalars['Decimal']['output']>;
  updateSemen: Maybe<Scalars['Boolean']['output']>;
};


export type FertilizationstrippedAtArgs = {
  ianaTimeZone: Scalars['String']['input'];
};

export type FertilizationFilterInput = {
  and?: InputMaybe<Array<FertilizationFilterInput>>;
  aspiration?: InputMaybe<AspirationFilterInput>;
  aspirationId?: InputMaybe<UuidOperationFilterInput>;
  aspirationPool?: InputMaybe<AspirationPoolFilterInput>;
  aspirationPoolId?: InputMaybe<UuidOperationFilterInput>;
  batch?: InputMaybe<StringOperationFilterInput>;
  billIncubator?: InputMaybe<BooleanOperationFilterInput>;
  billIncubator2?: InputMaybe<BooleanOperationFilterInput>;
  billShipping?: InputMaybe<BooleanOperationFilterInput>;
  billStatus?: InputMaybe<IntOperationFilterInput>;
  billUnits?: InputMaybe<DecimalOperationFilterInput>;
  billshipping2?: InputMaybe<BooleanOperationFilterInput>;
  bsVerified?: InputMaybe<BooleanOperationFilterInput>;
  cleaved?: InputMaybe<IntOperationFilterInput>;
  clientDiscard?: InputMaybe<IntOperationFilterInput>;
  clientGrade1?: InputMaybe<IntOperationFilterInput>;
  clientGrade2?: InputMaybe<IntOperationFilterInput>;
  clientGrade3?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  conc?: InputMaybe<FloatOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdByApp?: InputMaybe<IntOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  credSemenComment?: InputMaybe<StringOperationFilterInput>;
  creditSemen?: InputMaybe<IntOperationFilterInput>;
  day5esttrans?: InputMaybe<IntOperationFilterInput>;
  deRecip?: InputMaybe<StringOperationFilterInput>;
  deYard?: InputMaybe<TOG_yardBaseFilterInput>;
  deYardId?: InputMaybe<UuidOperationFilterInput>;
  debugInfo?: InputMaybe<StringOperationFilterInput>;
  degenerates?: InputMaybe<IntOperationFilterInput>;
  discardClient?: InputMaybe<IntOperationFilterInput>;
  discarded?: InputMaybe<IntOperationFilterInput>;
  discardedGrade1?: InputMaybe<IntOperationFilterInput>;
  discardedGrade2?: InputMaybe<IntOperationFilterInput>;
  donor?: InputMaybe<DonorFilterInput>;
  donorId?: InputMaybe<UuidOperationFilterInput>;
  donorSchedule?: InputMaybe<DonorScheduleFilterInput>;
  donorScheduleId?: InputMaybe<UuidOperationFilterInput>;
  donorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  embryoScore?: InputMaybe<IntOperationFilterInput>;
  embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  errorCode?: InputMaybe<DecimalOperationFilterInput>;
  export?: InputMaybe<IntOperationFilterInput>;
  fertValidationDate?: InputMaybe<DateTimeOperationFilterInput>;
  fertValidator?: InputMaybe<EquipmentFilterInput>;
  fertValidatorId?: InputMaybe<UuidOperationFilterInput>;
  flexFieldPartner?: InputMaybe<StringOperationFilterInput>;
  flshId?: InputMaybe<StringOperationFilterInput>;
  flushIdNumber?: InputMaybe<IntOperationFilterInput>;
  freezable?: InputMaybe<IntOperationFilterInput>;
  freezeComment?: InputMaybe<StringOperationFilterInput>;
  freezeMethod?: InputMaybe<OptionSetFilterInput>;
  freezeMethodId?: InputMaybe<UuidOperationFilterInput>;
  freezeTechnician?: InputMaybe<EquipmentFilterInput>;
  freezeTechnicianId?: InputMaybe<UuidOperationFilterInput>;
  freshShipmentId?: InputMaybe<UuidOperationFilterInput>;
  freshShipmentId2?: InputMaybe<UuidOperationFilterInput>;
  frozeClient?: InputMaybe<IntOperationFilterInput>;
  frozeValidationDate?: InputMaybe<DateTimeOperationFilterInput>;
  frozeValidator?: InputMaybe<EquipmentFilterInput>;
  frozeValidatorId?: InputMaybe<UuidOperationFilterInput>;
  frozen?: InputMaybe<IntOperationFilterInput>;
  frzCane?: InputMaybe<StringOperationFilterInput>;
  frzFreezeMethod?: InputMaybe<OptionSetFilterInput>;
  frzFreezeMethodId?: InputMaybe<UuidOperationFilterInput>;
  frzFreezeTechnician?: InputMaybe<StringOperationFilterInput>;
  frzFreezeTime?: InputMaybe<StringOperationFilterInput>;
  frzFreezer?: InputMaybe<StringOperationFilterInput>;
  frzOwner?: InputMaybe<AccountFilterInput>;
  frzOwnerId?: InputMaybe<UuidOperationFilterInput>;
  frzStorageLocation?: InputMaybe<StorageLocationFilterInput>;
  frzStorageLocationId?: InputMaybe<UuidOperationFilterInput>;
  frzTt?: InputMaybe<BooleanOperationFilterInput>;
  gpBatch?: InputMaybe<StringOperationFilterInput>;
  grade1?: InputMaybe<IntOperationFilterInput>;
  grade2?: InputMaybe<IntOperationFilterInput>;
  grade2Minus?: InputMaybe<IntOperationFilterInput>;
  grade3?: InputMaybe<IntOperationFilterInput>;
  grade42Minus?: InputMaybe<IntOperationFilterInput>;
  grader?: InputMaybe<EquipmentFilterInput>;
  grader6?: InputMaybe<StringOperationFilterInput>;
  graderId?: InputMaybe<UuidOperationFilterInput>;
  hasFewMotileSperm?: InputMaybe<BooleanOperationFilterInput>;
  hasNoMotileSperm?: InputMaybe<BooleanOperationFilterInput>;
  hasPoorCytoplasmQuality?: InputMaybe<BooleanOperationFilterInput>;
  hasTwoCellCleavage?: InputMaybe<BooleanOperationFilterInput>;
  heldAtTogForTransfer?: InputMaybe<IntOperationFilterInput>;
  heldAtTogFreezeTransferLowGrade?: InputMaybe<IntOperationFilterInput>;
  heldAtTogToFreeze?: InputMaybe<IntOperationFilterInput>;
  heparIn?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  implantPriceOf?: InputMaybe<DecimalOperationFilterInput>;
  implantTierOf?: InputMaybe<IntOperationFilterInput>;
  isFragile?: InputMaybe<BooleanOperationFilterInput>;
  isHardToStrip?: InputMaybe<BooleanOperationFilterInput>;
  ivcBroken?: InputMaybe<IntOperationFilterInput>;
  ivcComment?: InputMaybe<StringOperationFilterInput>;
  ivcDate?: InputMaybe<DateTimeOperationFilterInput>;
  ivcDead?: InputMaybe<IntOperationFilterInput>;
  ivcExtra?: InputMaybe<IntOperationFilterInput>;
  ivcImmature?: InputMaybe<IntOperationFilterInput>;
  ivcMissing?: InputMaybe<IntOperationFilterInput>;
  ivcNumber?: InputMaybe<IntOperationFilterInput>;
  ivcTechnician?: InputMaybe<EquipmentFilterInput>;
  ivcTechnicianId?: InputMaybe<UuidOperationFilterInput>;
  ivcTime?: InputMaybe<StringOperationFilterInput>;
  ivfDate?: InputMaybe<DateTimeOperationFilterInput>;
  ivfLabTechnician1?: InputMaybe<EquipmentFilterInput>;
  ivfLabTechnician1Id?: InputMaybe<UuidOperationFilterInput>;
  ivfLabTechnician2?: InputMaybe<EquipmentFilterInput>;
  ivfLabTechnician2Id?: InputMaybe<UuidOperationFilterInput>;
  ivfNumber?: InputMaybe<IntOperationFilterInput>;
  ivfTime?: InputMaybe<StringOperationFilterInput>;
  loadDonorPartners?: InputMaybe<IntOperationFilterInput>;
  loadDonorPartnersFrz?: InputMaybe<IntOperationFilterInput>;
  loadedInStraws?: InputMaybe<IntOperationFilterInput>;
  lowProdValidationDate?: InputMaybe<DateTimeOperationFilterInput>;
  lowProdValidator?: InputMaybe<EquipmentFilterInput>;
  lowProdValidatorId?: InputMaybe<UuidOperationFilterInput>;
  mating?: InputMaybe<StringOperationFilterInput>;
  matings?: InputMaybe<IntOperationFilterInput>;
  matings2?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  noEmbryosProduced?: InputMaybe<NullableOftog_noembryosproducedOperationFilterInput>;
  number?: InputMaybe<IntOperationFilterInput>;
  oocyteScore?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FertilizationFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  origHeldAtTog?: InputMaybe<IntOperationFilterInput>;
  origShipped?: InputMaybe<IntOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  packagingFee?: InputMaybe<BooleanOperationFilterInput>;
  packagingFee2?: InputMaybe<BooleanOperationFilterInput>;
  plan?: InputMaybe<IntOperationFilterInput>;
  plate?: InputMaybe<IntOperationFilterInput>;
  plateNumber?: InputMaybe<DecimalOperationFilterInput>;
  predictedBy?: InputMaybe<EquipmentFilterInput>;
  predictedById?: InputMaybe<UuidOperationFilterInput>;
  predictedByName?: InputMaybe<StringOperationFilterInput>;
  predictedComment?: InputMaybe<StringOperationFilterInput>;
  predictedGood?: InputMaybe<IntOperationFilterInput>;
  predictedLow?: InputMaybe<IntOperationFilterInput>;
  predictedVeryLow?: InputMaybe<IntOperationFilterInput>;
  probableDonor?: InputMaybe<DonorFilterInput>;
  probableDonorId?: InputMaybe<UuidOperationFilterInput>;
  procId?: InputMaybe<StringOperationFilterInput>;
  processingTechnician?: InputMaybe<EquipmentFilterInput>;
  processingTechnicianId?: InputMaybe<UuidOperationFilterInput>;
  prodres?: InputMaybe<IntOperationFilterInput>;
  qcCultureIncubator?: InputMaybe<StringOperationFilterInput>;
  qcCulturePlate?: InputMaybe<StringOperationFilterInput>;
  qcFertMedia?: InputMaybe<DateTimeOperationFilterInput>;
  qcFertPlate?: InputMaybe<StringOperationFilterInput>;
  qcHoldingsOfDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcIsolate?: InputMaybe<StringOperationFilterInput>;
  qcMineralOil?: InputMaybe<StringOperationFilterInput>;
  qcSerum?: InputMaybe<StringOperationFilterInput>;
  qcShippingsOfDate?: InputMaybe<DateTimeOperationFilterInput>;
  qcSofDate?: InputMaybe<DateTimeOperationFilterInput>;
  recipOwner?: InputMaybe<AccountFilterInput>;
  recipOwnerId?: InputMaybe<UuidOperationFilterInput>;
  recipient?: InputMaybe<RecipientFilterInput>;
  recipientId?: InputMaybe<UuidOperationFilterInput>;
  resultsComment?: InputMaybe<StringOperationFilterInput>;
  rsPool?: InputMaybe<IntOperationFilterInput>;
  semen?: InputMaybe<SemenFilterInput>;
  semenFee?: InputMaybe<DecimalOperationFilterInput>;
  semenId?: InputMaybe<UuidOperationFilterInput>;
  semenOwner?: InputMaybe<AccountFilterInput>;
  semenOwnerId?: InputMaybe<UuidOperationFilterInput>;
  semenScore?: InputMaybe<IntOperationFilterInput>;
  semenType?: InputMaybe<IntOperationFilterInput>;
  semenUnits?: InputMaybe<IntOperationFilterInput>;
  shipGroup?: InputMaybe<StringOperationFilterInput>;
  shipRecipGroup?: InputMaybe<StringOperationFilterInput>;
  shipValidationDate?: InputMaybe<DateTimeOperationFilterInput>;
  shipValidator?: InputMaybe<EquipmentFilterInput>;
  shipValidatorId?: InputMaybe<UuidOperationFilterInput>;
  shipmentGroupId?: InputMaybe<UuidOperationFilterInput>;
  shipmentGroupMaster2Id?: InputMaybe<UuidOperationFilterInput>;
  shipmentGroupMasterId?: InputMaybe<UuidOperationFilterInput>;
  shipped?: InputMaybe<IntOperationFilterInput>;
  shipped2?: InputMaybe<IntOperationFilterInput>;
  shippedBillable?: InputMaybe<DecimalOperationFilterInput>;
  shippedBillable2?: InputMaybe<DecimalOperationFilterInput>;
  shippedTog?: InputMaybe<IntOperationFilterInput>;
  shippedTog2?: InputMaybe<IntOperationFilterInput>;
  sire?: InputMaybe<SireFilterInput>;
  sireId?: InputMaybe<UuidOperationFilterInput>;
  sireTogIds?: InputMaybe<StringOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  transValidationDate?: InputMaybe<DateTimeOperationFilterInput>;
  transValidator?: InputMaybe<EquipmentFilterInput>;
  transValidatorId?: InputMaybe<UuidOperationFilterInput>;
  transferClient?: InputMaybe<IntOperationFilterInput>;
  transferable?: InputMaybe<IntOperationFilterInput>;
  transferred?: InputMaybe<IntOperationFilterInput>;
  trfClinicRanchHaulIn?: InputMaybe<IntOperationFilterInput>;
  trfOwner?: InputMaybe<AccountFilterInput>;
  trfOwnerId?: InputMaybe<UuidOperationFilterInput>;
  trfSite?: InputMaybe<SiteFilterInput>;
  trfSiteId?: InputMaybe<UuidOperationFilterInput>;
  tt?: InputMaybe<BooleanOperationFilterInput>;
  tubeNumber?: InputMaybe<DecimalOperationFilterInput>;
  updateSemen?: InputMaybe<BooleanOperationFilterInput>;
};

export type FertilizationSortInput = {
  aspiration?: InputMaybe<AspirationSortInput>;
  aspirationId?: InputMaybe<SortEnumType>;
  aspirationPool?: InputMaybe<AspirationPoolSortInput>;
  aspirationPoolId?: InputMaybe<SortEnumType>;
  batch?: InputMaybe<SortEnumType>;
  billIncubator?: InputMaybe<SortEnumType>;
  billIncubator2?: InputMaybe<SortEnumType>;
  billShipping?: InputMaybe<SortEnumType>;
  billStatus?: InputMaybe<SortEnumType>;
  billUnits?: InputMaybe<SortEnumType>;
  billshipping2?: InputMaybe<SortEnumType>;
  bsVerified?: InputMaybe<SortEnumType>;
  cleaved?: InputMaybe<SortEnumType>;
  clientDiscard?: InputMaybe<SortEnumType>;
  clientGrade1?: InputMaybe<SortEnumType>;
  clientGrade2?: InputMaybe<SortEnumType>;
  clientGrade3?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  conc?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdByApp?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  credSemenComment?: InputMaybe<SortEnumType>;
  creditSemen?: InputMaybe<SortEnumType>;
  day5esttrans?: InputMaybe<SortEnumType>;
  deRecip?: InputMaybe<SortEnumType>;
  deYard?: InputMaybe<TOG_yardBaseSortInput>;
  deYardId?: InputMaybe<SortEnumType>;
  debugInfo?: InputMaybe<SortEnumType>;
  degenerates?: InputMaybe<SortEnumType>;
  discardClient?: InputMaybe<SortEnumType>;
  discarded?: InputMaybe<SortEnumType>;
  discardedGrade1?: InputMaybe<SortEnumType>;
  discardedGrade2?: InputMaybe<SortEnumType>;
  donor?: InputMaybe<DonorSortInput>;
  donorId?: InputMaybe<SortEnumType>;
  donorSchedule?: InputMaybe<DonorScheduleSortInput>;
  donorScheduleId?: InputMaybe<SortEnumType>;
  embryoScore?: InputMaybe<SortEnumType>;
  errorCode?: InputMaybe<SortEnumType>;
  export?: InputMaybe<SortEnumType>;
  fertValidationDate?: InputMaybe<SortEnumType>;
  fertValidator?: InputMaybe<EquipmentSortInput>;
  fertValidatorId?: InputMaybe<SortEnumType>;
  flexFieldPartner?: InputMaybe<SortEnumType>;
  flshId?: InputMaybe<SortEnumType>;
  flushIdNumber?: InputMaybe<SortEnumType>;
  freezable?: InputMaybe<SortEnumType>;
  freezeComment?: InputMaybe<SortEnumType>;
  freezeMethod?: InputMaybe<OptionSetSortInput>;
  freezeMethodId?: InputMaybe<SortEnumType>;
  freezeTechnician?: InputMaybe<EquipmentSortInput>;
  freezeTechnicianId?: InputMaybe<SortEnumType>;
  freshShipmentId?: InputMaybe<SortEnumType>;
  freshShipmentId2?: InputMaybe<SortEnumType>;
  frozeClient?: InputMaybe<SortEnumType>;
  frozeValidationDate?: InputMaybe<SortEnumType>;
  frozeValidator?: InputMaybe<EquipmentSortInput>;
  frozeValidatorId?: InputMaybe<SortEnumType>;
  frozen?: InputMaybe<SortEnumType>;
  frzCane?: InputMaybe<SortEnumType>;
  frzFreezeMethod?: InputMaybe<OptionSetSortInput>;
  frzFreezeMethodId?: InputMaybe<SortEnumType>;
  frzFreezeTechnician?: InputMaybe<SortEnumType>;
  frzFreezeTime?: InputMaybe<SortEnumType>;
  frzFreezer?: InputMaybe<SortEnumType>;
  frzOwner?: InputMaybe<AccountSortInput>;
  frzOwnerId?: InputMaybe<SortEnumType>;
  frzStorageLocation?: InputMaybe<StorageLocationSortInput>;
  frzStorageLocationId?: InputMaybe<SortEnumType>;
  frzTt?: InputMaybe<SortEnumType>;
  gpBatch?: InputMaybe<SortEnumType>;
  grade1?: InputMaybe<SortEnumType>;
  grade2?: InputMaybe<SortEnumType>;
  grade2Minus?: InputMaybe<SortEnumType>;
  grade3?: InputMaybe<SortEnumType>;
  grade42Minus?: InputMaybe<SortEnumType>;
  grader?: InputMaybe<EquipmentSortInput>;
  grader6?: InputMaybe<SortEnumType>;
  graderId?: InputMaybe<SortEnumType>;
  hasFewMotileSperm?: InputMaybe<SortEnumType>;
  hasNoMotileSperm?: InputMaybe<SortEnumType>;
  hasPoorCytoplasmQuality?: InputMaybe<SortEnumType>;
  hasTwoCellCleavage?: InputMaybe<SortEnumType>;
  heldAtTogForTransfer?: InputMaybe<SortEnumType>;
  heldAtTogFreezeTransferLowGrade?: InputMaybe<SortEnumType>;
  heldAtTogToFreeze?: InputMaybe<SortEnumType>;
  heparIn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  implantPriceOf?: InputMaybe<SortEnumType>;
  implantTierOf?: InputMaybe<SortEnumType>;
  isFragile?: InputMaybe<SortEnumType>;
  isHardToStrip?: InputMaybe<SortEnumType>;
  ivcBroken?: InputMaybe<SortEnumType>;
  ivcComment?: InputMaybe<SortEnumType>;
  ivcDate?: InputMaybe<SortEnumType>;
  ivcDead?: InputMaybe<SortEnumType>;
  ivcExtra?: InputMaybe<SortEnumType>;
  ivcImmature?: InputMaybe<SortEnumType>;
  ivcMissing?: InputMaybe<SortEnumType>;
  ivcNumber?: InputMaybe<SortEnumType>;
  ivcTechnician?: InputMaybe<EquipmentSortInput>;
  ivcTechnicianId?: InputMaybe<SortEnumType>;
  ivcTime?: InputMaybe<SortEnumType>;
  ivfDate?: InputMaybe<SortEnumType>;
  ivfLabTechnician1?: InputMaybe<EquipmentSortInput>;
  ivfLabTechnician1Id?: InputMaybe<SortEnumType>;
  ivfLabTechnician2?: InputMaybe<EquipmentSortInput>;
  ivfLabTechnician2Id?: InputMaybe<SortEnumType>;
  ivfNumber?: InputMaybe<SortEnumType>;
  ivfTime?: InputMaybe<SortEnumType>;
  loadDonorPartners?: InputMaybe<SortEnumType>;
  loadDonorPartnersFrz?: InputMaybe<SortEnumType>;
  loadedInStraws?: InputMaybe<SortEnumType>;
  lowProdValidationDate?: InputMaybe<SortEnumType>;
  lowProdValidator?: InputMaybe<EquipmentSortInput>;
  lowProdValidatorId?: InputMaybe<SortEnumType>;
  mating?: InputMaybe<SortEnumType>;
  matings?: InputMaybe<SortEnumType>;
  matings2?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  noEmbryosProduced?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  oocyteScore?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  origHeldAtTog?: InputMaybe<SortEnumType>;
  origShipped?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  packagingFee?: InputMaybe<SortEnumType>;
  packagingFee2?: InputMaybe<SortEnumType>;
  plan?: InputMaybe<SortEnumType>;
  plate?: InputMaybe<SortEnumType>;
  plateNumber?: InputMaybe<SortEnumType>;
  predictedBy?: InputMaybe<EquipmentSortInput>;
  predictedById?: InputMaybe<SortEnumType>;
  predictedByName?: InputMaybe<SortEnumType>;
  predictedComment?: InputMaybe<SortEnumType>;
  predictedGood?: InputMaybe<SortEnumType>;
  predictedLow?: InputMaybe<SortEnumType>;
  predictedVeryLow?: InputMaybe<SortEnumType>;
  probableDonor?: InputMaybe<DonorSortInput>;
  probableDonorId?: InputMaybe<SortEnumType>;
  procId?: InputMaybe<SortEnumType>;
  processingTechnician?: InputMaybe<EquipmentSortInput>;
  processingTechnicianId?: InputMaybe<SortEnumType>;
  prodres?: InputMaybe<SortEnumType>;
  qcCultureIncubator?: InputMaybe<SortEnumType>;
  qcCulturePlate?: InputMaybe<SortEnumType>;
  qcFertMedia?: InputMaybe<SortEnumType>;
  qcFertPlate?: InputMaybe<SortEnumType>;
  qcHoldingsOfDate?: InputMaybe<SortEnumType>;
  qcIsolate?: InputMaybe<SortEnumType>;
  qcMineralOil?: InputMaybe<SortEnumType>;
  qcSerum?: InputMaybe<SortEnumType>;
  qcShippingsOfDate?: InputMaybe<SortEnumType>;
  qcSofDate?: InputMaybe<SortEnumType>;
  recipOwner?: InputMaybe<AccountSortInput>;
  recipOwnerId?: InputMaybe<SortEnumType>;
  recipient?: InputMaybe<RecipientSortInput>;
  recipientId?: InputMaybe<SortEnumType>;
  resultsComment?: InputMaybe<SortEnumType>;
  rsPool?: InputMaybe<SortEnumType>;
  semen?: InputMaybe<SemenSortInput>;
  semenFee?: InputMaybe<SortEnumType>;
  semenId?: InputMaybe<SortEnumType>;
  semenOwner?: InputMaybe<AccountSortInput>;
  semenOwnerId?: InputMaybe<SortEnumType>;
  semenScore?: InputMaybe<SortEnumType>;
  semenType?: InputMaybe<SortEnumType>;
  semenUnits?: InputMaybe<SortEnumType>;
  shipGroup?: InputMaybe<SortEnumType>;
  shipRecipGroup?: InputMaybe<SortEnumType>;
  shipValidationDate?: InputMaybe<SortEnumType>;
  shipValidator?: InputMaybe<EquipmentSortInput>;
  shipValidatorId?: InputMaybe<SortEnumType>;
  shipmentGroupId?: InputMaybe<SortEnumType>;
  shipmentGroupMaster2Id?: InputMaybe<SortEnumType>;
  shipmentGroupMasterId?: InputMaybe<SortEnumType>;
  shipped?: InputMaybe<SortEnumType>;
  shipped2?: InputMaybe<SortEnumType>;
  shippedBillable?: InputMaybe<SortEnumType>;
  shippedBillable2?: InputMaybe<SortEnumType>;
  shippedTog?: InputMaybe<SortEnumType>;
  shippedTog2?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SireSortInput>;
  sireId?: InputMaybe<SortEnumType>;
  sireTogIds?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  transValidationDate?: InputMaybe<SortEnumType>;
  transValidator?: InputMaybe<EquipmentSortInput>;
  transValidatorId?: InputMaybe<SortEnumType>;
  transferClient?: InputMaybe<SortEnumType>;
  transferable?: InputMaybe<SortEnumType>;
  transferred?: InputMaybe<SortEnumType>;
  trfClinicRanchHaulIn?: InputMaybe<SortEnumType>;
  trfOwner?: InputMaybe<AccountSortInput>;
  trfOwnerId?: InputMaybe<SortEnumType>;
  trfSite?: InputMaybe<SiteSortInput>;
  trfSiteId?: InputMaybe<SortEnumType>;
  tt?: InputMaybe<SortEnumType>;
  tubeNumber?: InputMaybe<SortEnumType>;
  updateSemen?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type FertilizationsCollectionSegment = {
  __typename: 'FertilizationsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Fertilization>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type Flush = {
  __typename: 'Flush';
  billDrugs: Maybe<Scalars['Boolean']['output']>;
  billStatus: Maybe<Scalars['Int']['output']>;
  bredBy: Maybe<Scalars['String']['output']>;
  breedingDate: Maybe<Scalars['DateTime']['output']>;
  breedingId: Maybe<Scalars['UUID']['output']>;
  breedingcomment: Maybe<Scalars['String']['output']>;
  breedingtime: Maybe<Scalars['Int']['output']>;
  clinic: Maybe<Scalars['Int']['output']>;
  clinicRanchHaulIn: Maybe<Scalars['Int']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  deYard: Maybe<TOG_yardBase>;
  deYardId: Maybe<Scalars['UUID']['output']>;
  degenerate: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  donor: Maybe<Donor>;
  donorId: Maybe<Scalars['UUID']['output']>;
  drugUsedId: Maybe<Scalars['UUID']['output']>;
  embryoPlan: Maybe<Scalars['Int']['output']>;
  embryos: Array<Embryo>;
  errorCode: Maybe<Scalars['Decimal']['output']>;
  estrusDate: Maybe<Scalars['DateTime']['output']>;
  estrusToFlush: Maybe<Scalars['Decimal']['output']>;
  flexFieldPartner: Maybe<Scalars['String']['output']>;
  flshId: Maybe<Scalars['Int']['output']>;
  flushDate: Maybe<Scalars['DateTime']['output']>;
  flushTechId: Maybe<Scalars['UUID']['output']>;
  flushTechnician: Maybe<Equipment>;
  flushTechnicianId: Maybe<Scalars['UUID']['output']>;
  flushTime: Maybe<Scalars['String']['output']>;
  flushToImplant: Maybe<Scalars['Decimal']['output']>;
  flushType: Maybe<Scalars['Int']['output']>;
  freezeComment: Maybe<Scalars['String']['output']>;
  frozen: Maybe<Scalars['Int']['output']>;
  frzCane: Maybe<Scalars['String']['output']>;
  frzFreezeMethod: Maybe<OptionSet>;
  frzFreezeMethodId: Maybe<Scalars['UUID']['output']>;
  frzFreezeTechnician: Maybe<Scalars['String']['output']>;
  frzFreezeTime: Maybe<Scalars['String']['output']>;
  frzFreezer: Maybe<Scalars['String']['output']>;
  frzStorageLocation: Maybe<StorageLocation>;
  frzStorageLocationId: Maybe<Scalars['UUID']['output']>;
  frzTt: Maybe<Scalars['Boolean']['output']>;
  grade3: Maybe<Scalars['Int']['output']>;
  grader: Maybe<Equipment>;
  graderId: Maybe<Scalars['UUID']['output']>;
  histId: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  implantTierOf: Maybe<Scalars['Int']['output']>;
  leftCl: Maybe<Scalars['Int']['output']>;
  loadDonorPartners: Maybe<Scalars['Int']['output']>;
  mileage: Maybe<Scalars['Decimal']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  msgSemenQty: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  pgfToEstrus: Maybe<Scalars['Decimal']['output']>;
  previousFlush: Maybe<Scalars['DateTime']['output']>;
  procId: Maybe<Scalars['String']['output']>;
  recipOwner: Maybe<Account>;
  recipOwnerId: Maybe<Scalars['UUID']['output']>;
  recoveryEtCode: Maybe<Scalars['String']['output']>;
  recoveryFirm: Maybe<Scalars['String']['output']>;
  righCl: Maybe<Scalars['Int']['output']>;
  semen: Maybe<Semen>;
  semenId: Maybe<Scalars['UUID']['output']>;
  semenLocation: Maybe<StorageLocation>;
  semenLocationId: Maybe<Scalars['UUID']['output']>;
  semenOwner: Maybe<Account>;
  semenOwnerId: Maybe<Scalars['UUID']['output']>;
  semenQty: Maybe<Scalars['Int']['output']>;
  semenQtyOverTime: Maybe<Scalars['String']['output']>;
  semenQuality: Maybe<Scalars['Int']['output']>;
  semenTransactionId: Maybe<Scalars['UUID']['output']>;
  semenType: Maybe<Scalars['Int']['output']>;
  sire: Maybe<Sire>;
  sireId: Maybe<Scalars['UUID']['output']>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  soSchedule: Maybe<SuperOvulationSchedule>;
  soScheduleId: Maybe<Scalars['UUID']['output']>;
  soSchedules: Array<SuperOvulationSchedule>;
  stateCode: Scalars['Int']['output'];
  status: Maybe<Scalars['Int']['output']>;
  statusCode: Maybe<Scalars['Int']['output']>;
  stopId: Maybe<Scalars['Int']['output']>;
  togId: Maybe<Scalars['Int']['output']>;
  totalCollected: Maybe<Scalars['Int']['output']>;
  transferred: Maybe<Scalars['Int']['output']>;
  trfClinicRanchHaulIn: Maybe<Scalars['Int']['output']>;
  trfFrzEmbryos: Maybe<Scalars['Boolean']['output']>;
  tripCharge: Maybe<Scalars['Decimal']['output']>;
  type: Maybe<Scalars['Int']['output']>;
  unFertilized: Maybe<Scalars['Int']['output']>;
};

export type FlushFilterInput = {
  and?: InputMaybe<Array<FlushFilterInput>>;
  billDrugs?: InputMaybe<BooleanOperationFilterInput>;
  billStatus?: InputMaybe<IntOperationFilterInput>;
  bredBy?: InputMaybe<StringOperationFilterInput>;
  breedingDate?: InputMaybe<DateTimeOperationFilterInput>;
  breedingId?: InputMaybe<UuidOperationFilterInput>;
  breedingcomment?: InputMaybe<StringOperationFilterInput>;
  breedingtime?: InputMaybe<IntOperationFilterInput>;
  clinic?: InputMaybe<IntOperationFilterInput>;
  clinicRanchHaulIn?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  deYard?: InputMaybe<TOG_yardBaseFilterInput>;
  deYardId?: InputMaybe<UuidOperationFilterInput>;
  degenerate?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  donor?: InputMaybe<DonorFilterInput>;
  donorId?: InputMaybe<UuidOperationFilterInput>;
  drugUsedId?: InputMaybe<UuidOperationFilterInput>;
  embryoPlan?: InputMaybe<IntOperationFilterInput>;
  embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  errorCode?: InputMaybe<DecimalOperationFilterInput>;
  estrusDate?: InputMaybe<DateTimeOperationFilterInput>;
  estrusToFlush?: InputMaybe<DecimalOperationFilterInput>;
  flexFieldPartner?: InputMaybe<StringOperationFilterInput>;
  flshId?: InputMaybe<IntOperationFilterInput>;
  flushDate?: InputMaybe<DateTimeOperationFilterInput>;
  flushTechId?: InputMaybe<UuidOperationFilterInput>;
  flushTechnician?: InputMaybe<EquipmentFilterInput>;
  flushTechnicianId?: InputMaybe<UuidOperationFilterInput>;
  flushTime?: InputMaybe<StringOperationFilterInput>;
  flushToImplant?: InputMaybe<DecimalOperationFilterInput>;
  flushType?: InputMaybe<IntOperationFilterInput>;
  freezeComment?: InputMaybe<StringOperationFilterInput>;
  frozen?: InputMaybe<IntOperationFilterInput>;
  frzCane?: InputMaybe<StringOperationFilterInput>;
  frzFreezeMethod?: InputMaybe<OptionSetFilterInput>;
  frzFreezeMethodId?: InputMaybe<UuidOperationFilterInput>;
  frzFreezeTechnician?: InputMaybe<StringOperationFilterInput>;
  frzFreezeTime?: InputMaybe<StringOperationFilterInput>;
  frzFreezer?: InputMaybe<StringOperationFilterInput>;
  frzStorageLocation?: InputMaybe<StorageLocationFilterInput>;
  frzStorageLocationId?: InputMaybe<UuidOperationFilterInput>;
  frzTt?: InputMaybe<BooleanOperationFilterInput>;
  grade3?: InputMaybe<IntOperationFilterInput>;
  grader?: InputMaybe<EquipmentFilterInput>;
  graderId?: InputMaybe<UuidOperationFilterInput>;
  histId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  implantTierOf?: InputMaybe<IntOperationFilterInput>;
  leftCl?: InputMaybe<IntOperationFilterInput>;
  loadDonorPartners?: InputMaybe<IntOperationFilterInput>;
  mileage?: InputMaybe<DecimalOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  msgSemenQty?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FlushFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  pgfToEstrus?: InputMaybe<DecimalOperationFilterInput>;
  previousFlush?: InputMaybe<DateTimeOperationFilterInput>;
  procId?: InputMaybe<StringOperationFilterInput>;
  recipOwner?: InputMaybe<AccountFilterInput>;
  recipOwnerId?: InputMaybe<UuidOperationFilterInput>;
  recoveryEtCode?: InputMaybe<StringOperationFilterInput>;
  recoveryFirm?: InputMaybe<StringOperationFilterInput>;
  righCl?: InputMaybe<IntOperationFilterInput>;
  semen?: InputMaybe<SemenFilterInput>;
  semenId?: InputMaybe<UuidOperationFilterInput>;
  semenLocation?: InputMaybe<StorageLocationFilterInput>;
  semenLocationId?: InputMaybe<UuidOperationFilterInput>;
  semenOwner?: InputMaybe<AccountFilterInput>;
  semenOwnerId?: InputMaybe<UuidOperationFilterInput>;
  semenQty?: InputMaybe<IntOperationFilterInput>;
  semenQtyOverTime?: InputMaybe<StringOperationFilterInput>;
  semenQuality?: InputMaybe<IntOperationFilterInput>;
  semenTransactionId?: InputMaybe<UuidOperationFilterInput>;
  semenType?: InputMaybe<IntOperationFilterInput>;
  sire?: InputMaybe<SireFilterInput>;
  sireId?: InputMaybe<UuidOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  soSchedule?: InputMaybe<SuperOvulationScheduleFilterInput>;
  soScheduleId?: InputMaybe<UuidOperationFilterInput>;
  soSchedules?: InputMaybe<ListFilterInputTypeOfSuperOvulationScheduleFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  stopId?: InputMaybe<IntOperationFilterInput>;
  togId?: InputMaybe<IntOperationFilterInput>;
  totalCollected?: InputMaybe<IntOperationFilterInput>;
  transferred?: InputMaybe<IntOperationFilterInput>;
  trfClinicRanchHaulIn?: InputMaybe<IntOperationFilterInput>;
  trfFrzEmbryos?: InputMaybe<BooleanOperationFilterInput>;
  tripCharge?: InputMaybe<DecimalOperationFilterInput>;
  type?: InputMaybe<IntOperationFilterInput>;
  unFertilized?: InputMaybe<IntOperationFilterInput>;
};

export type FlushSortInput = {
  billDrugs?: InputMaybe<SortEnumType>;
  billStatus?: InputMaybe<SortEnumType>;
  bredBy?: InputMaybe<SortEnumType>;
  breedingDate?: InputMaybe<SortEnumType>;
  breedingId?: InputMaybe<SortEnumType>;
  breedingcomment?: InputMaybe<SortEnumType>;
  breedingtime?: InputMaybe<SortEnumType>;
  clinic?: InputMaybe<SortEnumType>;
  clinicRanchHaulIn?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  deYard?: InputMaybe<TOG_yardBaseSortInput>;
  deYardId?: InputMaybe<SortEnumType>;
  degenerate?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  donor?: InputMaybe<DonorSortInput>;
  donorId?: InputMaybe<SortEnumType>;
  drugUsedId?: InputMaybe<SortEnumType>;
  embryoPlan?: InputMaybe<SortEnumType>;
  errorCode?: InputMaybe<SortEnumType>;
  estrusDate?: InputMaybe<SortEnumType>;
  estrusToFlush?: InputMaybe<SortEnumType>;
  flexFieldPartner?: InputMaybe<SortEnumType>;
  flshId?: InputMaybe<SortEnumType>;
  flushDate?: InputMaybe<SortEnumType>;
  flushTechId?: InputMaybe<SortEnumType>;
  flushTechnician?: InputMaybe<EquipmentSortInput>;
  flushTechnicianId?: InputMaybe<SortEnumType>;
  flushTime?: InputMaybe<SortEnumType>;
  flushToImplant?: InputMaybe<SortEnumType>;
  flushType?: InputMaybe<SortEnumType>;
  freezeComment?: InputMaybe<SortEnumType>;
  frozen?: InputMaybe<SortEnumType>;
  frzCane?: InputMaybe<SortEnumType>;
  frzFreezeMethod?: InputMaybe<OptionSetSortInput>;
  frzFreezeMethodId?: InputMaybe<SortEnumType>;
  frzFreezeTechnician?: InputMaybe<SortEnumType>;
  frzFreezeTime?: InputMaybe<SortEnumType>;
  frzFreezer?: InputMaybe<SortEnumType>;
  frzStorageLocation?: InputMaybe<StorageLocationSortInput>;
  frzStorageLocationId?: InputMaybe<SortEnumType>;
  frzTt?: InputMaybe<SortEnumType>;
  grade3?: InputMaybe<SortEnumType>;
  grader?: InputMaybe<EquipmentSortInput>;
  graderId?: InputMaybe<SortEnumType>;
  histId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  implantTierOf?: InputMaybe<SortEnumType>;
  leftCl?: InputMaybe<SortEnumType>;
  loadDonorPartners?: InputMaybe<SortEnumType>;
  mileage?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  msgSemenQty?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  pgfToEstrus?: InputMaybe<SortEnumType>;
  previousFlush?: InputMaybe<SortEnumType>;
  procId?: InputMaybe<SortEnumType>;
  recipOwner?: InputMaybe<AccountSortInput>;
  recipOwnerId?: InputMaybe<SortEnumType>;
  recoveryEtCode?: InputMaybe<SortEnumType>;
  recoveryFirm?: InputMaybe<SortEnumType>;
  righCl?: InputMaybe<SortEnumType>;
  semen?: InputMaybe<SemenSortInput>;
  semenId?: InputMaybe<SortEnumType>;
  semenLocation?: InputMaybe<StorageLocationSortInput>;
  semenLocationId?: InputMaybe<SortEnumType>;
  semenOwner?: InputMaybe<AccountSortInput>;
  semenOwnerId?: InputMaybe<SortEnumType>;
  semenQty?: InputMaybe<SortEnumType>;
  semenQtyOverTime?: InputMaybe<SortEnumType>;
  semenQuality?: InputMaybe<SortEnumType>;
  semenTransactionId?: InputMaybe<SortEnumType>;
  semenType?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SireSortInput>;
  sireId?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  soSchedule?: InputMaybe<SuperOvulationScheduleSortInput>;
  soScheduleId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  stopId?: InputMaybe<SortEnumType>;
  togId?: InputMaybe<SortEnumType>;
  totalCollected?: InputMaybe<SortEnumType>;
  transferred?: InputMaybe<SortEnumType>;
  trfClinicRanchHaulIn?: InputMaybe<SortEnumType>;
  trfFrzEmbryos?: InputMaybe<SortEnumType>;
  tripCharge?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  unFertilized?: InputMaybe<SortEnumType>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type ItRequestsCollectionSegment = {
  __typename: 'ItRequestsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type KeyValuePairOfInt32AndString = {
  __typename: 'KeyValuePairOfInt32AndString';
  key: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type ListFilterInputTypeOfAccountBreedFilterInput = {
  all?: InputMaybe<AccountBreedFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountBreedFilterInput>;
  some?: InputMaybe<AccountBreedFilterInput>;
};

export type ListFilterInputTypeOfAccountFilterInput = {
  all?: InputMaybe<AccountFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountFilterInput>;
  some?: InputMaybe<AccountFilterInput>;
};

export type ListFilterInputTypeOfAspirationFilterInput = {
  all?: InputMaybe<AspirationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AspirationFilterInput>;
  some?: InputMaybe<AspirationFilterInput>;
};

export type ListFilterInputTypeOfAspirationPoolFilterInput = {
  all?: InputMaybe<AspirationPoolFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AspirationPoolFilterInput>;
  some?: InputMaybe<AspirationPoolFilterInput>;
};

export type ListFilterInputTypeOfCalendarFilterInput = {
  all?: InputMaybe<CalendarFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CalendarFilterInput>;
  some?: InputMaybe<CalendarFilterInput>;
};

export type ListFilterInputTypeOfCalfFilterInput = {
  all?: InputMaybe<CalfFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CalfFilterInput>;
  some?: InputMaybe<CalfFilterInput>;
};

export type ListFilterInputTypeOfCalfProcedureFilterInput = {
  all?: InputMaybe<CalfProcedureFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CalfProcedureFilterInput>;
  some?: InputMaybe<CalfProcedureFilterInput>;
};

export type ListFilterInputTypeOfDonorFilterInput = {
  all?: InputMaybe<DonorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DonorFilterInput>;
  some?: InputMaybe<DonorFilterInput>;
};

export type ListFilterInputTypeOfDonorScheduleFilterInput = {
  all?: InputMaybe<DonorScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DonorScheduleFilterInput>;
  some?: InputMaybe<DonorScheduleFilterInput>;
};

export type ListFilterInputTypeOfEmbryoFilterInput = {
  all?: InputMaybe<EmbryoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EmbryoFilterInput>;
  some?: InputMaybe<EmbryoFilterInput>;
};

export type ListFilterInputTypeOfEquipmentFilterInput = {
  all?: InputMaybe<EquipmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EquipmentFilterInput>;
  some?: InputMaybe<EquipmentFilterInput>;
};

export type ListFilterInputTypeOfFeatureAdGroupFilterInput = {
  all?: InputMaybe<FeatureAdGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FeatureAdGroupFilterInput>;
  some?: InputMaybe<FeatureAdGroupFilterInput>;
};

export type ListFilterInputTypeOfFeatureAdUserFilterInput = {
  all?: InputMaybe<FeatureAdUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FeatureAdUserFilterInput>;
  some?: InputMaybe<FeatureAdUserFilterInput>;
};

export type ListFilterInputTypeOfFeatureTogAccountFilterInput = {
  all?: InputMaybe<FeatureTogAccountFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FeatureTogAccountFilterInput>;
  some?: InputMaybe<FeatureTogAccountFilterInput>;
};

export type ListFilterInputTypeOfFertilizationFilterInput = {
  all?: InputMaybe<FertilizationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FertilizationFilterInput>;
  some?: InputMaybe<FertilizationFilterInput>;
};

export type ListFilterInputTypeOfFlushFilterInput = {
  all?: InputMaybe<FlushFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FlushFilterInput>;
  some?: InputMaybe<FlushFilterInput>;
};

export type ListFilterInputTypeOfLocationFilterInput = {
  all?: InputMaybe<LocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<LocationFilterInput>;
  some?: InputMaybe<LocationFilterInput>;
};

export type ListFilterInputTypeOfRecipientFilterInput = {
  all?: InputMaybe<RecipientFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RecipientFilterInput>;
  some?: InputMaybe<RecipientFilterInput>;
};

export type ListFilterInputTypeOfRequestEventFilterInput = {
  all?: InputMaybe<RequestEventFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RequestEventFilterInput>;
  some?: InputMaybe<RequestEventFilterInput>;
};

export type ListFilterInputTypeOfRequestImportantFilterInput = {
  all?: InputMaybe<RequestImportantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RequestImportantFilterInput>;
  some?: InputMaybe<RequestImportantFilterInput>;
};

export type ListFilterInputTypeOfRequestItIssueFilterInput = {
  all?: InputMaybe<RequestItIssueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RequestItIssueFilterInput>;
  some?: InputMaybe<RequestItIssueFilterInput>;
};

export type ListFilterInputTypeOfRequestSubscriptionFilterInput = {
  all?: InputMaybe<RequestSubscriptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RequestSubscriptionFilterInput>;
  some?: InputMaybe<RequestSubscriptionFilterInput>;
};

export type ListFilterInputTypeOfSemenFilterInput = {
  all?: InputMaybe<SemenFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SemenFilterInput>;
  some?: InputMaybe<SemenFilterInput>;
};

export type ListFilterInputTypeOfShipmentFilterInput = {
  all?: InputMaybe<ShipmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ShipmentFilterInput>;
  some?: InputMaybe<ShipmentFilterInput>;
};

export type ListFilterInputTypeOfSireFilterInput = {
  all?: InputMaybe<SireFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SireFilterInput>;
  some?: InputMaybe<SireFilterInput>;
};

export type ListFilterInputTypeOfStorageLocationFilterInput = {
  all?: InputMaybe<StorageLocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StorageLocationFilterInput>;
  some?: InputMaybe<StorageLocationFilterInput>;
};

export type ListFilterInputTypeOfSuperOvulationScheduleFilterInput = {
  all?: InputMaybe<SuperOvulationScheduleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SuperOvulationScheduleFilterInput>;
  some?: InputMaybe<SuperOvulationScheduleFilterInput>;
};

export type Location = {
  __typename: 'Location';
  accountSite: Maybe<Scalars['Int']['output']>;
  aspirationTechnician: Maybe<Equipment>;
  aspirationTechnicianId: Maybe<Scalars['UUID']['output']>;
  assignedVet: Maybe<Equipment>;
  assignedVetId: Maybe<Scalars['UUID']['output']>;
  billingDrugsType: Maybe<Scalars['Int']['output']>;
  billingGrade3: Maybe<Scalars['Int']['output']>;
  billingGrade3Freeze: Maybe<Scalars['Int']['output']>;
  billingStorageFees: Maybe<Scalars['Int']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  customerServiceRepresentative: Maybe<SystemUser>;
  customerServiceRepresentativeId: Maybe<Scalars['UUID']['output']>;
  defaultYard: Maybe<TOG_yardBase>;
  defaultYardId: Maybe<Scalars['UUID']['output']>;
  fertilizationSite: Maybe<Site>;
  fertilizationSiteId: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  isCombinedBillingDonorHousing: Maybe<Scalars['Boolean']['output']>;
  ivfBillingGroup: Maybe<Scalars['String']['output']>;
  maxProcedures: Maybe<Scalars['Int']['output']>;
  maxTransfers: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  region: Maybe<Scalars['Int']['output']>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  siteType: Maybe<Scalars['Int']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  travelingEmbryologist: Maybe<Equipment>;
  travelingEmbryologistId: Maybe<Scalars['UUID']['output']>;
  tripFee: Maybe<Scalars['Decimal']['output']>;
};

export type LocationFilterInput = {
  accountSite?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<LocationFilterInput>>;
  aspirationTechnician?: InputMaybe<EquipmentFilterInput>;
  aspirationTechnicianId?: InputMaybe<UuidOperationFilterInput>;
  assignedVet?: InputMaybe<EquipmentFilterInput>;
  assignedVetId?: InputMaybe<UuidOperationFilterInput>;
  billingDrugsType?: InputMaybe<IntOperationFilterInput>;
  billingGrade3?: InputMaybe<IntOperationFilterInput>;
  billingGrade3Freeze?: InputMaybe<IntOperationFilterInput>;
  billingStorageFees?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  customerServiceRepresentative?: InputMaybe<SystemUserFilterInput>;
  customerServiceRepresentativeId?: InputMaybe<UuidOperationFilterInput>;
  defaultYard?: InputMaybe<TOG_yardBaseFilterInput>;
  defaultYardId?: InputMaybe<UuidOperationFilterInput>;
  fertilizationSite?: InputMaybe<SiteFilterInput>;
  fertilizationSiteId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isCombinedBillingDonorHousing?: InputMaybe<BooleanOperationFilterInput>;
  ivfBillingGroup?: InputMaybe<StringOperationFilterInput>;
  maxProcedures?: InputMaybe<IntOperationFilterInput>;
  maxTransfers?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  region?: InputMaybe<IntOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  siteType?: InputMaybe<IntOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  travelingEmbryologist?: InputMaybe<EquipmentFilterInput>;
  travelingEmbryologistId?: InputMaybe<UuidOperationFilterInput>;
  tripFee?: InputMaybe<DecimalOperationFilterInput>;
};

export type LocationSortInput = {
  accountSite?: InputMaybe<SortEnumType>;
  aspirationTechnician?: InputMaybe<EquipmentSortInput>;
  aspirationTechnicianId?: InputMaybe<SortEnumType>;
  assignedVet?: InputMaybe<EquipmentSortInput>;
  assignedVetId?: InputMaybe<SortEnumType>;
  billingDrugsType?: InputMaybe<SortEnumType>;
  billingGrade3?: InputMaybe<SortEnumType>;
  billingGrade3Freeze?: InputMaybe<SortEnumType>;
  billingStorageFees?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  customerServiceRepresentative?: InputMaybe<SystemUserSortInput>;
  customerServiceRepresentativeId?: InputMaybe<SortEnumType>;
  defaultYard?: InputMaybe<TOG_yardBaseSortInput>;
  defaultYardId?: InputMaybe<SortEnumType>;
  fertilizationSite?: InputMaybe<SiteSortInput>;
  fertilizationSiteId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isCombinedBillingDonorHousing?: InputMaybe<SortEnumType>;
  ivfBillingGroup?: InputMaybe<SortEnumType>;
  maxProcedures?: InputMaybe<SortEnumType>;
  maxTransfers?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  region?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  siteType?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  travelingEmbryologist?: InputMaybe<EquipmentSortInput>;
  travelingEmbryologistId?: InputMaybe<SortEnumType>;
  tripFee?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type LocationsCollectionSegment = {
  __typename: 'LocationsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Location>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  addFavorite: Array<Scalars['ID']['output']>;
  addFeatureAccount: FeatureTogAccount;
  addFeatureGroup: FeatureAdGroup;
  addFeatureUser: FeatureAdUser;
  approveEmbryoShipments: Array<Shipment>;
  approveSireSelectionRequests: Array<SireSelectionRequest>;
  approveSireSelectionRequestsWithoutSemen: Array<SireSelectionRequest>;
  confirmSireSelection: SireSelectionDto;
  createAetaResult: CreateAetaResultPayload;
  createClientIssueRequest: Request;
  createEquipment: Array<Equipment>;
  createFeature: Feature;
  createIssueManager: RequestIssueManager;
  createItRequest: Request;
  createItRequestEvent: RequestEvent;
  createReportRequest: Request;
  createShippingFormReport: Scalars['String']['output'];
  deleteAetaResult: Scalars['Boolean']['output'];
  deleteFeature: Scalars['Boolean']['output'];
  deleteFeatureAccount: Scalars['Boolean']['output'];
  deleteFeatureGroup: Scalars['Boolean']['output'];
  deleteFeatureUser: Scalars['Boolean']['output'];
  deleteIssueManager: Scalars['Boolean']['output'];
  equipment_syncToTraining: Scalars['Boolean']['output'];
  login: Scalars['Boolean']['output'];
  removeFavorite: Array<Scalars['ID']['output']>;
  reorderFavorites: Array<Scalars['ID']['output']>;
  requestToggleImportant: Request;
  requestToggleSubscribed: Request;
  setSireSelectionRequestSemen: SireSelectionRequest;
  shipping_runShip: Scalars['Boolean']['output'];
  shipping_runShipIntra: Scalars['Boolean']['output'];
  shipping_runShipIntraYackley: Scalars['Boolean']['output'];
  shipping_setInProgress: Shipment;
  /** @deprecated Use `requestToggleSubscribed` instead */
  subscribeToRequest: Request;
  /** @deprecated Use `requestToggleSubscribed` instead */
  unsubscribeFromRequest: Request;
  updateAetaResult: UpdateAetaResultPayload;
  updateEquipment: Maybe<Equipment>;
  updateFeature: Feature;
  updateFeatureAccount: FeatureTogAccount;
  updateFeatureGroup: FeatureAdGroup;
  updateFeatureUser: FeatureAdUser;
  updateFertilization: Maybe<Fertilization>;
  updateShipment: Maybe<Shipment>;
};


export type MutationaddFavoriteArgs = {
  appId: Scalars['ID']['input'];
};


export type MutationaddFeatureAccountArgs = {
  accountId: Scalars['UUID']['input'];
  featureId: Scalars['UUID']['input'];
};


export type MutationaddFeatureGroupArgs = {
  adGroup: Scalars['String']['input'];
  featureId: Scalars['UUID']['input'];
};


export type MutationaddFeatureUserArgs = {
  adUserId: Scalars['UUID']['input'];
  featureId: Scalars['UUID']['input'];
};


export type MutationapproveEmbryoShipmentsArgs = {
  ids: Array<Scalars['UUID']['input']>;
  status: ShipmentStatus;
};


export type MutationapproveSireSelectionRequestsArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationapproveSireSelectionRequestsWithoutSemenArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationconfirmSireSelectionArgs = {
  confirmationTypeId: Scalars['Int']['input'];
  donorScheduleId: Scalars['UUID']['input'];
};


export type MutationcreateAetaResultArgs = {
  input: CreateAetaResultInput;
};


export type MutationcreateClientIssueRequestArgs = {
  input: RequestClientIssueInput;
};


export type MutationcreateEquipmentArgs = {
  isActive: Scalars['Boolean']['input'];
  isEmbryologist: Scalars['Boolean']['input'];
  isHerdsman: Scalars['Boolean']['input'];
  isInTraining: Scalars['Boolean']['input'];
  isTechnician: Scalars['Boolean']['input'];
  isVeterinarian: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  siteId: Scalars['UUID']['input'];
};


export type MutationcreateFeatureArgs = {
  name: Scalars['String']['input'];
};


export type MutationcreateIssueManagerArgs = {
  adUserId: Scalars['UUID']['input'];
  issueType: RequestIssueType;
};


export type MutationcreateItRequestArgs = {
  request: RequestInput;
};


export type MutationcreateItRequestEventArgs = {
  content: Scalars['String']['input'];
  refRequestId: InputMaybe<Scalars['Int']['input']>;
  requestId: Scalars['Int']['input'];
  type: RequestEventType;
};


export type MutationcreateReportRequestArgs = {
  input: ReportRequestInput;
};


export type MutationcreateShippingFormReportArgs = {
  sendEmail?: Scalars['Boolean']['input'];
  shipmentId: Scalars['UUID']['input'];
};


export type MutationdeleteAetaResultArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteFeatureArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteFeatureAccountArgs = {
  accountId: Scalars['UUID']['input'];
  featureId: Scalars['UUID']['input'];
};


export type MutationdeleteFeatureGroupArgs = {
  adGroup: Scalars['String']['input'];
  featureId: Scalars['UUID']['input'];
};


export type MutationdeleteFeatureUserArgs = {
  adUserId: Scalars['UUID']['input'];
  featureId: Scalars['UUID']['input'];
};


export type MutationdeleteIssueManagerArgs = {
  id: Scalars['Int']['input'];
};


export type Mutationequipment_syncToTrainingArgs = {
  equipmentId: Scalars['UUID']['input'];
};


export type MutationloginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationremoveFavoriteArgs = {
  appId: Scalars['ID']['input'];
};


export type MutationreorderFavoritesArgs = {
  appIds: Array<Scalars['ID']['input']>;
};


export type MutationrequestToggleImportantArgs = {
  id: Scalars['Int']['input'];
};


export type MutationrequestToggleSubscribedArgs = {
  id: Scalars['Int']['input'];
};


export type MutationsetSireSelectionRequestSemenArgs = {
  semenId: Scalars['UUID']['input'];
  semenType: Scalars['Int']['input'];
  sireSelectionRequestId: Scalars['UUID']['input'];
};


export type Mutationshipping_runShipArgs = {
  shipmentId: Scalars['UUID']['input'];
};


export type Mutationshipping_runShipIntraArgs = {
  shipmentId: Scalars['UUID']['input'];
};


export type Mutationshipping_runShipIntraYackleyArgs = {
  shipmentId: Scalars['UUID']['input'];
};


export type Mutationshipping_setInProgressArgs = {
  shipmentId: Scalars['UUID']['input'];
};


export type MutationsubscribeToRequestArgs = {
  id: Scalars['Int']['input'];
};


export type MutationunsubscribeFromRequestArgs = {
  id: Scalars['Int']['input'];
};


export type MutationupdateAetaResultArgs = {
  input: UpdateAetaResultInput;
};


export type MutationupdateEquipmentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateEquipmentInput;
};


export type MutationupdateFeatureArgs = {
  id: Scalars['UUID']['input'];
  isEnabled: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationupdateFeatureAccountArgs = {
  accountId: Scalars['UUID']['input'];
  featureId: Scalars['UUID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};


export type MutationupdateFeatureGroupArgs = {
  adGroup: Scalars['String']['input'];
  featureId: Scalars['UUID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};


export type MutationupdateFeatureUserArgs = {
  adUserId: Scalars['UUID']['input'];
  featureId: Scalars['UUID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};


export type MutationupdateFertilizationArgs = {
  ianaTimeZone: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  input: UpdateFertilizationInput;
};


export type MutationupdateShipmentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateShipmentInput;
};

export type NullableOftog_categoryOperationFilterInput = {
  eq?: InputMaybe<tog_category>;
  in?: InputMaybe<Array<InputMaybe<tog_category>>>;
  neq?: InputMaybe<tog_category>;
  nin?: InputMaybe<Array<InputMaybe<tog_category>>>;
};

export type NullableOftog_fertplanOperationFilterInput = {
  eq?: InputMaybe<tog_fertplan>;
  in?: InputMaybe<Array<InputMaybe<tog_fertplan>>>;
  neq?: InputMaybe<tog_fertplan>;
  nin?: InputMaybe<Array<InputMaybe<tog_fertplan>>>;
};

export type NullableOftog_noembryosproducedOperationFilterInput = {
  eq?: InputMaybe<tog_noembryosproduced>;
  in?: InputMaybe<Array<InputMaybe<tog_noembryosproduced>>>;
  neq?: InputMaybe<tog_noembryosproduced>;
  nin?: InputMaybe<Array<InputMaybe<tog_noembryosproduced>>>;
};

export type NullableOftog_subfamilyOperationFilterInput = {
  eq?: InputMaybe<tog_subfamily>;
  in?: InputMaybe<Array<InputMaybe<tog_subfamily>>>;
  neq?: InputMaybe<tog_subfamily>;
  nin?: InputMaybe<Array<InputMaybe<tog_subfamily>>>;
};

/** A segment of a collection. */
export type OpenItRequestsCollectionSegment = {
  __typename: 'OpenItRequestsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type OptionSet = {
  __typename: 'OptionSet';
  abbrev: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  description2: Maybe<Scalars['String']['output']>;
  description3: Maybe<Scalars['String']['output']>;
  filterKey: Maybe<Scalars['String']['output']>;
  freeMethodEmbryos: Array<Embryo>;
  freezeMethodFertilizations: Array<Fertilization>;
  frzFreezeMethodFertilizations: Array<Fertilization>;
  housingDonors: Array<Donor>;
  id: Scalars['UUID']['output'];
  ivfStatusDonors: Array<Donor>;
  label: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  ocvStatusDonors: Array<Donor>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  search: Maybe<Scalars['String']['output']>;
  sequence: Maybe<Scalars['Int']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  statusDonors: Array<Donor>;
};

export type OptionSetFilterInput = {
  abbrev?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<OptionSetFilterInput>>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  description2?: InputMaybe<StringOperationFilterInput>;
  description3?: InputMaybe<StringOperationFilterInput>;
  filterKey?: InputMaybe<StringOperationFilterInput>;
  freeMethodEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  freezeMethodFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  frzFreezeMethodFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  housingDonors?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  ivfStatusDonors?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  ocvStatusDonors?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  or?: InputMaybe<Array<OptionSetFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  sequence?: InputMaybe<IntOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  statusDonors?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
};

export type OptionSetSortInput = {
  abbrev?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  description2?: InputMaybe<SortEnumType>;
  description3?: InputMaybe<SortEnumType>;
  filterKey?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  label?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  sequence?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
};

export type Organization = {
  __typename: 'Organization';
  id: Scalars['UUID']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type OrganizationFilterInput = {
  and?: InputMaybe<Array<OrganizationFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationFilterInput>>;
};

export type OrganizationSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type Pen = {
  __typename: 'Pen';
  barn: Maybe<Scalars['String']['output']>;
  capacity: Maybe<Scalars['Int']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  pen: Maybe<Scalars['String']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  yard: Maybe<TOG_yardBase>;
  yardId: Maybe<Scalars['UUID']['output']>;
};

export type PenFilterInput = {
  and?: InputMaybe<Array<PenFilterInput>>;
  barn?: InputMaybe<StringOperationFilterInput>;
  capacity?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PenFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  pen?: InputMaybe<StringOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  yard?: InputMaybe<TOG_yardBaseFilterInput>;
  yardId?: InputMaybe<UuidOperationFilterInput>;
};

export type PenSortInput = {
  barn?: InputMaybe<SortEnumType>;
  capacity?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  pen?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  yard?: InputMaybe<TOG_yardBaseSortInput>;
  yardId?: InputMaybe<SortEnumType>;
};

export type Query = {
  __typename: 'Query';
  account: Maybe<Account>;
  accounts: Maybe<AccountsCollectionSegment>;
  adGroups: Array<Scalars['String']['output']>;
  adUsers: Array<AdUser>;
  aetaResults: Maybe<AetaResultsCollectionSegment>;
  apps: Array<App>;
  appsFavorites: Array<Scalars['ID']['output']>;
  calves: Maybe<CalvesCollectionSegment>;
  checkFeatures: Array<Scalars['String']['output']>;
  closedItRequests: Maybe<ClosedItRequestsCollectionSegment>;
  dailyAccountsWithSireSelections: Array<DailySireSelectionsWithAccountsDto>;
  dailySireSelections: Maybe<DailySireSelectionsCollectionSegment>;
  donors: Maybe<DonorsCollectionSegment>;
  embryos: Maybe<EmbryosCollectionSegment>;
  equipment: Maybe<Equipment>;
  equipments: Maybe<EquipmentsCollectionSegment>;
  features: Maybe<FeaturesCollectionSegment>;
  fertilizations: Maybe<FertilizationsCollectionSegment>;
  itRequest: Maybe<Request>;
  itRequestApps: Array<TogRequestApp>;
  itRequestDepartments: Array<TogRequestDepartment>;
  itRequestSearch: Array<Request>;
  itRequests: Maybe<ItRequestsCollectionSegment>;
  locations: Maybe<LocationsCollectionSegment>;
  me: SystemUser;
  messagingConfirmationTypes: Array<messaging_confirmation_type>;
  openItRequests: Maybe<OpenItRequestsCollectionSegment>;
  recipients: Maybe<RecipientsCollectionSegment>;
  requestIssueManagers: Maybe<RequestIssueManagersCollectionSegment>;
  semen: Maybe<SemenCollectionSegment>;
  shipment: Maybe<Shipment>;
  shipments: Maybe<ShipmentsCollectionSegment>;
  sireConfirmations: Maybe<SireConfirmationsCollectionSegment>;
  sireSelectionRequests: Maybe<SireSelectionRequestsCollectionSegment>;
  sites: Maybe<SitesCollectionSegment>;
  storageLocations: Maybe<StorageLocationsCollectionSegment>;
  stringMap: Maybe<Array<KeyValuePairOfInt32AndString>>;
  stringMaps: Maybe<StringMapsCollectionSegment>;
  strippingFertilizations: Maybe<StrippingFertilizationsCollectionSegment>;
  systemUsers: Maybe<SystemUsersCollectionSegment>;
  telemetryEvents: Maybe<TelemetryEventsCollectionSegment>;
};


export type QueryaccountArgs = {
  where: InputMaybe<AccountFilterInput>;
};


export type QueryaccountsArgs = {
  order: InputMaybe<Array<AccountSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<AccountFilterInput>;
};


export type QueryaetaResultsArgs = {
  order: InputMaybe<Array<AetaResultSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<AetaResultFilterInput>;
};


export type QuerycalvesArgs = {
  order: InputMaybe<Array<CalfSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CalfFilterInput>;
};


export type QuerycheckFeaturesArgs = {
  features: Array<Scalars['String']['input']>;
};


export type QueryclosedItRequestsArgs = {
  order: InputMaybe<Array<RequestSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RequestFilterInput>;
};


export type QuerydailySireSelectionsArgs = {
  order: InputMaybe<Array<DailySireSelectionsDtoSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<DailySireSelectionsDtoFilterInput>;
};


export type QuerydonorsArgs = {
  order: InputMaybe<Array<DonorSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<DonorFilterInput>;
};


export type QueryembryosArgs = {
  order: InputMaybe<Array<EmbryoSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<EmbryoFilterInput>;
};


export type QueryequipmentArgs = {
  where: InputMaybe<EquipmentFilterInput>;
};


export type QueryequipmentsArgs = {
  order: InputMaybe<Array<EquipmentSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<EquipmentFilterInput>;
};


export type QueryfeaturesArgs = {
  order: InputMaybe<Array<FeatureSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<FeatureFilterInput>;
};


export type QueryfertilizationsArgs = {
  order: InputMaybe<Array<FertilizationSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<FertilizationFilterInput>;
};


export type QueryitRequestArgs = {
  where: InputMaybe<RequestFilterInput>;
};


export type QueryitRequestSearchArgs = {
  issueType: InputMaybe<RequestIssueType>;
  query: Scalars['String']['input'];
};


export type QueryitRequestsArgs = {
  order: InputMaybe<Array<RequestSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RequestFilterInput>;
};


export type QuerylocationsArgs = {
  order: InputMaybe<Array<LocationSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<LocationFilterInput>;
};


export type QueryopenItRequestsArgs = {
  order: InputMaybe<Array<RequestSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RequestFilterInput>;
};


export type QueryrecipientsArgs = {
  order: InputMaybe<Array<RecipientSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RecipientFilterInput>;
};


export type QueryrequestIssueManagersArgs = {
  order: InputMaybe<Array<RequestIssueManagerSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RequestIssueManagerFilterInput>;
};


export type QuerysemenArgs = {
  order: InputMaybe<Array<SemenSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<SemenFilterInput>;
};


export type QueryshipmentArgs = {
  where: InputMaybe<ShipmentFilterInput>;
};


export type QueryshipmentsArgs = {
  order: InputMaybe<Array<ShipmentSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ShipmentFilterInput>;
};


export type QuerysireConfirmationsArgs = {
  order: InputMaybe<Array<SireSelectionDtoSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<SireSelectionDtoFilterInput>;
};


export type QuerysireSelectionRequestsArgs = {
  order: InputMaybe<Array<SireSelectionRequestSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<SireSelectionRequestFilterInput>;
};


export type QuerysitesArgs = {
  order: InputMaybe<Array<SiteSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<SiteFilterInput>;
};


export type QuerystorageLocationsArgs = {
  order: InputMaybe<Array<StorageLocationSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<StorageLocationFilterInput>;
};


export type QuerystringMapArgs = {
  name: Scalars['String']['input'];
  objectTypeCode: InputMaybe<Scalars['Int']['input']>;
};


export type QuerystringMapsArgs = {
  order: InputMaybe<Array<StringMapSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<StringMapFilterInput>;
};


export type QuerystrippingFertilizationsArgs = {
  fertilizationDate: Scalars['DateTime']['input'];
  order: InputMaybe<Array<FertilizationSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<FertilizationFilterInput>;
};


export type QuerysystemUsersArgs = {
  order: InputMaybe<Array<SystemUserSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<SystemUserFilterInput>;
};


export type QuerytelemetryEventsArgs = {
  order: InputMaybe<Array<TogTelemetryEventSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<TogTelemetryEventFilterInput>;
};

export type Recipient = {
  __typename: 'Recipient';
  activityDate: Maybe<Scalars['DateTime']['output']>;
  backdays: Maybe<Scalars['Int']['output']>;
  breed: Maybe<Breed>;
  breedId: Maybe<Scalars['UUID']['output']>;
  client: Maybe<Scalars['Int']['output']>;
  color: Maybe<Color>;
  colorId: Maybe<Scalars['UUID']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  cost: Maybe<Scalars['Decimal']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  cullPr: Maybe<Scalars['Decimal']['output']>;
  culledOn: Maybe<Scalars['DateTime']['output']>;
  estimatedBirthDateOn: Maybe<Scalars['DateTime']['output']>;
  flexField: Maybe<Scalars['String']['output']>;
  flexFieldNumber: Maybe<Scalars['Decimal']['output']>;
  fosterRecipientCalfProcedures: Array<CalfProcedure>;
  fosterRecipientCalves: Array<Calf>;
  hasCalf: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  lastActivity: Maybe<Scalars['String']['output']>;
  lastEmbryo: Maybe<Embryo>;
  lastEmbryoId: Maybe<Scalars['UUID']['output']>;
  lastHeatOn: Maybe<Scalars['DateTime']['output']>;
  lastHeatTime: Maybe<Scalars['Int']['output']>;
  lastSyncOn: Maybe<Scalars['DateTime']['output']>;
  metalTag: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Use recipientCalves relation */
  numCalves: Maybe<Scalars['Int']['output']>;
  ocvStatus: Maybe<OptionSet>;
  ocvStatusId: Maybe<Scalars['UUID']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  origin: Maybe<Scalars['String']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  pen: Maybe<Scalars['String']['output']>;
  previousHeatOn: Maybe<Scalars['DateTime']['output']>;
  previousHeatTime: Maybe<Scalars['Int']['output']>;
  previousTag: Maybe<Scalars['String']['output']>;
  /** @deprecated Use owner relation */
  ranchCode: Maybe<Scalars['String']['output']>;
  ranchTag: Maybe<Scalars['String']['output']>;
  received: Maybe<Scalars['DateTime']['output']>;
  recipientCalves: Array<Calf>;
  recipientEmbryos: Array<Embryo>;
  recipientFertilizations: Array<Fertilization>;
  recipientStatus: Maybe<OptionSet>;
  recipientStatusId: Maybe<Scalars['UUID']['output']>;
  registry: Maybe<Scalars['String']['output']>;
  shipOn: Maybe<Scalars['DateTime']['output']>;
  shipment: Maybe<Shipment>;
  shipmentId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  tag: Maybe<Scalars['String']['output']>;
  tattoo: Maybe<Scalars['String']['output']>;
  togId: Maybe<Scalars['Int']['output']>;
  weight: Maybe<Scalars['Int']['output']>;
  yard: Maybe<TOG_yardBase>;
  yardId: Maybe<Scalars['UUID']['output']>;
};

export type RecipientFilterInput = {
  activityDate?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<RecipientFilterInput>>;
  backdays?: InputMaybe<IntOperationFilterInput>;
  breed?: InputMaybe<BreedFilterInput>;
  breedId?: InputMaybe<UuidOperationFilterInput>;
  client?: InputMaybe<IntOperationFilterInput>;
  color?: InputMaybe<ColorFilterInput>;
  colorId?: InputMaybe<UuidOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  cost?: InputMaybe<DecimalOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  cullPr?: InputMaybe<DecimalOperationFilterInput>;
  culledOn?: InputMaybe<DateTimeOperationFilterInput>;
  estimatedBirthDateOn?: InputMaybe<DateTimeOperationFilterInput>;
  flexField?: InputMaybe<StringOperationFilterInput>;
  flexFieldNumber?: InputMaybe<DecimalOperationFilterInput>;
  fosterRecipientCalfProcedures?: InputMaybe<ListFilterInputTypeOfCalfProcedureFilterInput>;
  fosterRecipientCalves?: InputMaybe<ListFilterInputTypeOfCalfFilterInput>;
  hasCalf?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastActivity?: InputMaybe<StringOperationFilterInput>;
  lastEmbryo?: InputMaybe<EmbryoFilterInput>;
  lastEmbryoId?: InputMaybe<UuidOperationFilterInput>;
  lastHeatOn?: InputMaybe<DateTimeOperationFilterInput>;
  lastHeatTime?: InputMaybe<IntOperationFilterInput>;
  lastSyncOn?: InputMaybe<DateTimeOperationFilterInput>;
  metalTag?: InputMaybe<StringOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  numCalves?: InputMaybe<IntOperationFilterInput>;
  ocvStatus?: InputMaybe<OptionSetFilterInput>;
  ocvStatusId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RecipientFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  origin?: InputMaybe<StringOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  pen?: InputMaybe<StringOperationFilterInput>;
  previousHeatOn?: InputMaybe<DateTimeOperationFilterInput>;
  previousHeatTime?: InputMaybe<IntOperationFilterInput>;
  previousTag?: InputMaybe<StringOperationFilterInput>;
  ranchCode?: InputMaybe<StringOperationFilterInput>;
  ranchTag?: InputMaybe<StringOperationFilterInput>;
  received?: InputMaybe<DateTimeOperationFilterInput>;
  recipientCalves?: InputMaybe<ListFilterInputTypeOfCalfFilterInput>;
  recipientEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  recipientFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  recipientStatus?: InputMaybe<OptionSetFilterInput>;
  recipientStatusId?: InputMaybe<UuidOperationFilterInput>;
  registry?: InputMaybe<StringOperationFilterInput>;
  shipOn?: InputMaybe<DateTimeOperationFilterInput>;
  shipment?: InputMaybe<ShipmentFilterInput>;
  shipmentId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  tag?: InputMaybe<StringOperationFilterInput>;
  tattoo?: InputMaybe<StringOperationFilterInput>;
  togId?: InputMaybe<IntOperationFilterInput>;
  weight?: InputMaybe<IntOperationFilterInput>;
  yard?: InputMaybe<TOG_yardBaseFilterInput>;
  yardId?: InputMaybe<UuidOperationFilterInput>;
};

export type RecipientSortInput = {
  activityDate?: InputMaybe<SortEnumType>;
  backdays?: InputMaybe<SortEnumType>;
  breed?: InputMaybe<BreedSortInput>;
  breedId?: InputMaybe<SortEnumType>;
  client?: InputMaybe<SortEnumType>;
  color?: InputMaybe<ColorSortInput>;
  colorId?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  cost?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  cullPr?: InputMaybe<SortEnumType>;
  culledOn?: InputMaybe<SortEnumType>;
  estimatedBirthDateOn?: InputMaybe<SortEnumType>;
  flexField?: InputMaybe<SortEnumType>;
  flexFieldNumber?: InputMaybe<SortEnumType>;
  hasCalf?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastActivity?: InputMaybe<SortEnumType>;
  lastEmbryo?: InputMaybe<EmbryoSortInput>;
  lastEmbryoId?: InputMaybe<SortEnumType>;
  lastHeatOn?: InputMaybe<SortEnumType>;
  lastHeatTime?: InputMaybe<SortEnumType>;
  lastSyncOn?: InputMaybe<SortEnumType>;
  metalTag?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  numCalves?: InputMaybe<SortEnumType>;
  ocvStatus?: InputMaybe<OptionSetSortInput>;
  ocvStatusId?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  origin?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  pen?: InputMaybe<SortEnumType>;
  previousHeatOn?: InputMaybe<SortEnumType>;
  previousHeatTime?: InputMaybe<SortEnumType>;
  previousTag?: InputMaybe<SortEnumType>;
  ranchCode?: InputMaybe<SortEnumType>;
  ranchTag?: InputMaybe<SortEnumType>;
  received?: InputMaybe<SortEnumType>;
  recipientStatus?: InputMaybe<OptionSetSortInput>;
  recipientStatusId?: InputMaybe<SortEnumType>;
  registry?: InputMaybe<SortEnumType>;
  shipOn?: InputMaybe<SortEnumType>;
  shipment?: InputMaybe<ShipmentSortInput>;
  shipmentId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  tag?: InputMaybe<SortEnumType>;
  tattoo?: InputMaybe<SortEnumType>;
  togId?: InputMaybe<SortEnumType>;
  weight?: InputMaybe<SortEnumType>;
  yard?: InputMaybe<TOG_yardBaseSortInput>;
  yardId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RecipientsCollectionSegment = {
  __typename: 'RecipientsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Recipient>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportRequestInput = {
  content: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type Request = {
  __typename: 'Request';
  /** @deprecated Use `issue` instead */
  clientIssue: Maybe<RequestClientIssue>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  events: Array<RequestEvent>;
  id: Scalars['Int']['output'];
  importants: Array<RequestImportant>;
  isImportant: Scalars['Boolean']['output'];
  isSubscribed: Scalars['Boolean']['output'];
  issue: RequestIssue;
  /** @deprecated Use `issue` instead */
  itIssue: Maybe<RequestItIssue>;
  referencedIn: Array<RequestEvent>;
  /** @deprecated Use `issue` instead */
  reportIssue: Maybe<RequestReportIssue>;
  requestor: Maybe<SystemUser>;
  requestorId: Scalars['UUID']['output'];
  searchText: Scalars['String']['output'];
  subscriptions: Array<RequestSubscription>;
  title: Scalars['String']['output'];
  type: RequestIssueType;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RequestClientIssue = {
  __typename: 'RequestClientIssue';
  account: Account;
  accountId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  embryosPerFertilization: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  implantedOn: Scalars['String']['output'];
  request: Request;
  requestId: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RequestClientIssueFilterInput = {
  accountId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<RequestClientIssueFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  embryosPerFertilization?: InputMaybe<FloatOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  implantedOn?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RequestClientIssueFilterInput>>;
  request?: InputMaybe<RequestFilterInput>;
  requestId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RequestClientIssueInput = {
  accountId: Scalars['UUID']['input'];
  content: Scalars['String']['input'];
  embryosPerFertilization?: InputMaybe<Scalars['Float']['input']>;
  implantedOn: Scalars['Date']['input'];
  title: Scalars['String']['input'];
};

export type RequestClientIssueSortInput = {
  accountId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  embryosPerFertilization?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  implantedOn?: InputMaybe<SortEnumType>;
  request?: InputMaybe<RequestSortInput>;
  requestId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type RequestEvent = {
  __typename: 'RequestEvent';
  author: Maybe<SystemUser>;
  authorAdUserId: Scalars['UUID']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  refRequest: Maybe<Request>;
  refRequestId: Maybe<Scalars['Int']['output']>;
  request: Request;
  requestId: Scalars['Int']['output'];
  searchText: Scalars['String']['output'];
  type: RequestEventType;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RequestEventFilterInput = {
  and?: InputMaybe<Array<RequestEventFilterInput>>;
  authorAdUserId?: InputMaybe<UuidOperationFilterInput>;
  content?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RequestEventFilterInput>>;
  refRequest?: InputMaybe<RequestFilterInput>;
  refRequestId?: InputMaybe<IntOperationFilterInput>;
  request?: InputMaybe<RequestFilterInput>;
  requestId?: InputMaybe<IntOperationFilterInput>;
  searchText?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export enum RequestEventType {
  APPROVED = 'APPROVED',
  COMMENT = 'COMMENT',
  COMPLETED = 'COMPLETED',
  DENIED = 'DENIED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type RequestFilterInput = {
  and?: InputMaybe<Array<RequestFilterInput>>;
  clientIssue?: InputMaybe<RequestClientIssueFilterInput>;
  content?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  events?: InputMaybe<ListFilterInputTypeOfRequestEventFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  importants?: InputMaybe<ListFilterInputTypeOfRequestImportantFilterInput>;
  itIssue?: InputMaybe<RequestItIssueFilterInput>;
  or?: InputMaybe<Array<RequestFilterInput>>;
  referencedIn?: InputMaybe<ListFilterInputTypeOfRequestEventFilterInput>;
  reportIssue?: InputMaybe<RequestReportIssueFilterInput>;
  requestorId?: InputMaybe<UuidOperationFilterInput>;
  searchText?: InputMaybe<StringOperationFilterInput>;
  subscriptions?: InputMaybe<ListFilterInputTypeOfRequestSubscriptionFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RequestImportant = {
  __typename: 'RequestImportant';
  adUserId: Scalars['UUID']['output'];
  request: Request;
  requestId: Scalars['Int']['output'];
};

export type RequestImportantFilterInput = {
  adUserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<RequestImportantFilterInput>>;
  or?: InputMaybe<Array<RequestImportantFilterInput>>;
  request?: InputMaybe<RequestFilterInput>;
  requestId?: InputMaybe<IntOperationFilterInput>;
};

export type RequestInput = {
  appId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  departmentId: Scalars['Int']['input'];
  sponsorId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type RequestIssue = RequestClientIssue | RequestItIssue | RequestReportIssue;

export type RequestIssueManager = {
  __typename: 'RequestIssueManager';
  adUserId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  issueType: RequestIssueType;
  systemUser: Maybe<SystemUser>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RequestIssueManagerFilterInput = {
  adUserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<RequestIssueManagerFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  issueType?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RequestIssueManagerFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RequestIssueManagerSortInput = {
  adUserId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  issueType?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RequestIssueManagersCollectionSegment = {
  __typename: 'RequestIssueManagersCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<RequestIssueManager>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum RequestIssueType {
  CLIENT = 'CLIENT',
  IT = 'IT',
  REPORT = 'REPORT'
}

export type RequestItIssue = {
  __typename: 'RequestItIssue';
  app: TogRequestApp;
  appId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  department: TogRequestDepartment;
  departmentId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  request: Request;
  requestId: Scalars['Int']['output'];
  sponsor: Maybe<SystemUser>;
  sponsorId: Scalars['UUID']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RequestItIssueFilterInput = {
  and?: InputMaybe<Array<RequestItIssueFilterInput>>;
  app?: InputMaybe<TogRequestAppFilterInput>;
  appId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  department?: InputMaybe<TogRequestDepartmentFilterInput>;
  departmentId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RequestItIssueFilterInput>>;
  request?: InputMaybe<RequestFilterInput>;
  requestId?: InputMaybe<IntOperationFilterInput>;
  sponsorId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RequestItIssueSortInput = {
  app?: InputMaybe<TogRequestAppSortInput>;
  appId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  department?: InputMaybe<TogRequestDepartmentSortInput>;
  departmentId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  request?: InputMaybe<RequestSortInput>;
  requestId?: InputMaybe<SortEnumType>;
  sponsorId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type RequestReportIssue = {
  __typename: 'RequestReportIssue';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  request: Request;
  requestId: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RequestReportIssueFilterInput = {
  and?: InputMaybe<Array<RequestReportIssueFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<RequestReportIssueFilterInput>>;
  request?: InputMaybe<RequestFilterInput>;
  requestId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RequestReportIssueSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  request?: InputMaybe<RequestSortInput>;
  requestId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type RequestSortInput = {
  clientIssue?: InputMaybe<RequestClientIssueSortInput>;
  content?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  itIssue?: InputMaybe<RequestItIssueSortInput>;
  reportIssue?: InputMaybe<RequestReportIssueSortInput>;
  requestorId?: InputMaybe<SortEnumType>;
  searchText?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type RequestSubscription = {
  __typename: 'RequestSubscription';
  adUserId: Scalars['UUID']['output'];
  request: Request;
  requestId: Scalars['Int']['output'];
};

export type RequestSubscriptionFilterInput = {
  adUserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<RequestSubscriptionFilterInput>>;
  or?: InputMaybe<Array<RequestSubscriptionFilterInput>>;
  request?: InputMaybe<RequestFilterInput>;
  requestId?: InputMaybe<IntOperationFilterInput>;
};

export type ScheduleMaster = {
  __typename: 'ScheduleMaster';
  coast: Maybe<Scalars['Int']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  donorSchedules: Array<DonorSchedule>;
  id: Scalars['UUID']['output'];
  method: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  nextScheduleMasterAspirations: Array<Aspiration>;
  numberOfShots: Maybe<Scalars['Int']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  scheduleMasterAspirations: Array<Aspiration>;
  setup: Maybe<Scalars['Int']['output']>;
  soSchedules: Array<SuperOvulationSchedule>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  totalDose: Maybe<Scalars['Decimal']['output']>;
  twiceConcentration: Maybe<Scalars['Boolean']['output']>;
};

export type ScheduleMasterFilterInput = {
  and?: InputMaybe<Array<ScheduleMasterFilterInput>>;
  coast?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  donorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  method?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nextScheduleMasterAspirations?: InputMaybe<ListFilterInputTypeOfAspirationFilterInput>;
  numberOfShots?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleMasterFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  scheduleMasterAspirations?: InputMaybe<ListFilterInputTypeOfAspirationFilterInput>;
  setup?: InputMaybe<IntOperationFilterInput>;
  soSchedules?: InputMaybe<ListFilterInputTypeOfSuperOvulationScheduleFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  totalDose?: InputMaybe<DecimalOperationFilterInput>;
  twiceConcentration?: InputMaybe<BooleanOperationFilterInput>;
};

export type ScheduleMasterSortInput = {
  coast?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  method?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberOfShots?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  setup?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  totalDose?: InputMaybe<SortEnumType>;
  twiceConcentration?: InputMaybe<SortEnumType>;
};

export type Semen = {
  __typename: 'Semen';
  backupSemenDonorSchedules: Array<DonorSchedule>;
  cane: Maybe<Scalars['String']['output']>;
  center: Maybe<Scalars['String']['output']>;
  collectionCode: Maybe<Scalars['String']['output']>;
  collectionDate: Maybe<Scalars['DateTime']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  concentration: Maybe<Scalars['Int']['output']>;
  cost: Maybe<Scalars['Decimal']['output']>;
  country: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdByApp: Maybe<Scalars['Int']['output']>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  critical: Maybe<Scalars['Int']['output']>;
  cssCertified: Maybe<Scalars['Int']['output']>;
  cssCertifiedValue: Maybe<Scalars['String']['output']>;
  dateReceived: Maybe<Scalars['DateTime']['output']>;
  embryos: Array<Embryo>;
  export: Maybe<Scalars['Int']['output']>;
  exportValue: Maybe<Scalars['String']['output']>;
  fertilizations: Array<Fertilization>;
  flushs: Array<Flush>;
  group: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  ivfLabSemenDonorSchedules: Array<DonorSchedule>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  naab: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  pulledSemenQuantity: Maybe<Scalars['Int']['output']>;
  pulledSemenRsfQuantity: Maybe<Scalars['Int']['output']>;
  pulledSemenRsmQuantity: Maybe<Scalars['Int']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
  quantityAdj: Maybe<Scalars['Int']['output']>;
  quantityRec: Maybe<Scalars['Int']['output']>;
  quantityToShip: Maybe<Scalars['Int']['output']>;
  quantityUsed: Maybe<Scalars['Int']['output']>;
  regtog: Maybe<Scalars['Int']['output']>;
  secondaryOwner: Maybe<Scalars['String']['output']>;
  semenDonorSchedules: Array<DonorSchedule>;
  semenOrigin: Maybe<Scalars['Int']['output']>;
  semenType: Maybe<Scalars['String']['output']>;
  sex: Maybe<Scalars['Int']['output']>;
  sexValue: Maybe<Scalars['String']['output']>;
  sexed: Maybe<Scalars['Boolean']['output']>;
  shipment: Maybe<Shipment>;
  shipmentId: Maybe<Scalars['UUID']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  sire: Maybe<Sire>;
  sireId: Maybe<Scalars['UUID']['output']>;
  soldTo: Maybe<Scalars['String']['output']>;
  sortStatus: Maybe<Scalars['Int']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  storageLocation: Maybe<StorageLocation>;
  storageLocationId: Maybe<Scalars['UUID']['output']>;
  storageStartDate: Maybe<Scalars['DateTime']['output']>;
  toStorageLocation: Maybe<StorageLocation>;
  toStorageLocationId: Maybe<Scalars['UUID']['output']>;
  togCane: Maybe<Scalars['String']['output']>;
  togId: Maybe<Scalars['Int']['output']>;
  transIn: Maybe<Scalars['Int']['output']>;
  transOut: Maybe<Scalars['Int']['output']>;
  units: Maybe<Scalars['Int']['output']>;
};

/** A segment of a collection. */
export type SemenCollectionSegment = {
  __typename: 'SemenCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Semen>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SemenFilterInput = {
  and?: InputMaybe<Array<SemenFilterInput>>;
  backupSemenDonorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  cane?: InputMaybe<StringOperationFilterInput>;
  center?: InputMaybe<StringOperationFilterInput>;
  collectionCode?: InputMaybe<StringOperationFilterInput>;
  collectionDate?: InputMaybe<DateTimeOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  concentration?: InputMaybe<IntOperationFilterInput>;
  cost?: InputMaybe<DecimalOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdByApp?: InputMaybe<IntOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  critical?: InputMaybe<IntOperationFilterInput>;
  cssCertified?: InputMaybe<IntOperationFilterInput>;
  dateReceived?: InputMaybe<DateTimeOperationFilterInput>;
  embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  export?: InputMaybe<IntOperationFilterInput>;
  fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  flushs?: InputMaybe<ListFilterInputTypeOfFlushFilterInput>;
  group?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  ivfLabSemenDonorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  naab?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SemenFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  pulledSemenQuantity?: InputMaybe<IntOperationFilterInput>;
  pulledSemenRsfQuantity?: InputMaybe<IntOperationFilterInput>;
  pulledSemenRsmQuantity?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  quantityAdj?: InputMaybe<IntOperationFilterInput>;
  quantityRec?: InputMaybe<IntOperationFilterInput>;
  quantityToShip?: InputMaybe<IntOperationFilterInput>;
  quantityUsed?: InputMaybe<IntOperationFilterInput>;
  regtog?: InputMaybe<IntOperationFilterInput>;
  secondaryOwner?: InputMaybe<StringOperationFilterInput>;
  semenDonorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  semenOrigin?: InputMaybe<IntOperationFilterInput>;
  semenType?: InputMaybe<StringOperationFilterInput>;
  sex?: InputMaybe<IntOperationFilterInput>;
  sexed?: InputMaybe<BooleanOperationFilterInput>;
  shipment?: InputMaybe<ShipmentFilterInput>;
  shipmentId?: InputMaybe<UuidOperationFilterInput>;
  shortName?: InputMaybe<StringOperationFilterInput>;
  sire?: InputMaybe<SireFilterInput>;
  sireId?: InputMaybe<UuidOperationFilterInput>;
  soldTo?: InputMaybe<StringOperationFilterInput>;
  sortStatus?: InputMaybe<IntOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  storageLocation?: InputMaybe<StorageLocationFilterInput>;
  storageLocationId?: InputMaybe<UuidOperationFilterInput>;
  storageStartDate?: InputMaybe<DateTimeOperationFilterInput>;
  toStorageLocation?: InputMaybe<StorageLocationFilterInput>;
  toStorageLocationId?: InputMaybe<UuidOperationFilterInput>;
  togCane?: InputMaybe<StringOperationFilterInput>;
  togId?: InputMaybe<IntOperationFilterInput>;
  transIn?: InputMaybe<IntOperationFilterInput>;
  transOut?: InputMaybe<IntOperationFilterInput>;
  units?: InputMaybe<IntOperationFilterInput>;
};

export type SemenSortInput = {
  cane?: InputMaybe<SortEnumType>;
  center?: InputMaybe<SortEnumType>;
  collectionCode?: InputMaybe<SortEnumType>;
  collectionDate?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  concentration?: InputMaybe<SortEnumType>;
  cost?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdByApp?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  critical?: InputMaybe<SortEnumType>;
  cssCertified?: InputMaybe<SortEnumType>;
  dateReceived?: InputMaybe<SortEnumType>;
  export?: InputMaybe<SortEnumType>;
  group?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  naab?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  pulledSemenQuantity?: InputMaybe<SortEnumType>;
  pulledSemenRsfQuantity?: InputMaybe<SortEnumType>;
  pulledSemenRsmQuantity?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  quantityAdj?: InputMaybe<SortEnumType>;
  quantityRec?: InputMaybe<SortEnumType>;
  quantityToShip?: InputMaybe<SortEnumType>;
  quantityUsed?: InputMaybe<SortEnumType>;
  regtog?: InputMaybe<SortEnumType>;
  secondaryOwner?: InputMaybe<SortEnumType>;
  semenOrigin?: InputMaybe<SortEnumType>;
  semenType?: InputMaybe<SortEnumType>;
  sex?: InputMaybe<SortEnumType>;
  sexed?: InputMaybe<SortEnumType>;
  shipment?: InputMaybe<ShipmentSortInput>;
  shipmentId?: InputMaybe<SortEnumType>;
  shortName?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SireSortInput>;
  sireId?: InputMaybe<SortEnumType>;
  soldTo?: InputMaybe<SortEnumType>;
  sortStatus?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  storageLocation?: InputMaybe<StorageLocationSortInput>;
  storageLocationId?: InputMaybe<SortEnumType>;
  storageStartDate?: InputMaybe<SortEnumType>;
  toStorageLocation?: InputMaybe<StorageLocationSortInput>;
  toStorageLocationId?: InputMaybe<SortEnumType>;
  togCane?: InputMaybe<SortEnumType>;
  togId?: InputMaybe<SortEnumType>;
  transIn?: InputMaybe<SortEnumType>;
  transOut?: InputMaybe<SortEnumType>;
  units?: InputMaybe<SortEnumType>;
};

export type Shipment = {
  __typename: 'Shipment';
  amount: Maybe<Scalars['Decimal']['output']>;
  billStatus: Maybe<Scalars['Int']['output']>;
  billStatusValue: Maybe<Scalars['String']['output']>;
  billTo: Maybe<Scalars['String']['output']>;
  callTag: Maybe<Scalars['String']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  date: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  donors: Array<Donor>;
  earTagColor: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  embryos: Array<Embryo>;
  hasCalves: Maybe<Scalars['Boolean']['output']>;
  hasDonors: Maybe<Scalars['Boolean']['output']>;
  hasEmbryos: Maybe<Scalars['Boolean']['output']>;
  hasRecipients: Maybe<Scalars['Boolean']['output']>;
  hasSemen: Maybe<Scalars['Boolean']['output']>;
  heatTimeSpan: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  implantDate: Maybe<Scalars['DateTime']['output']>;
  implantPrice: Maybe<Scalars['Decimal']['output']>;
  implantTierOf: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  needByDate: Maybe<Scalars['DateTime']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  originalNeedByDate: Maybe<Scalars['DateTime']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  packedBy: Maybe<Equipment>;
  packedById: Maybe<Scalars['UUID']['output']>;
  packedby: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  receiveGnrh: Maybe<Scalars['String']['output']>;
  recipKickouts: Maybe<Scalars['String']['output']>;
  recipsAvail: Maybe<Scalars['String']['output']>;
  requestedBy: Maybe<Scalars['String']['output']>;
  semens: Array<Semen>;
  shipAddress: Maybe<Scalars['String']['output']>;
  shipAddress2: Maybe<Scalars['String']['output']>;
  shipAddress3: Maybe<Scalars['String']['output']>;
  shipCity: Maybe<Scalars['String']['output']>;
  shipFromSite: Maybe<Site>;
  shipFromSiteId: Maybe<Scalars['UUID']['output']>;
  shipName: Maybe<Scalars['String']['output']>;
  shipState: Maybe<Scalars['String']['output']>;
  shipTo: Maybe<Account>;
  shipToId: Maybe<Scalars['UUID']['output']>;
  shipZip: Maybe<Scalars['String']['output']>;
  shipmentCategory: Maybe<Scalars['Int']['output']>;
  shipmentCategoryValue: Maybe<Scalars['String']['output']>;
  shipmentNumber: Maybe<Scalars['String']['output']>;
  shipmentStatus: Maybe<Scalars['Int']['output']>;
  shipmentStatusValue: Maybe<Scalars['String']['output']>;
  shippingMethod: Maybe<Scalars['Int']['output']>;
  shippingMethodValue: Maybe<Scalars['String']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  tank: Maybe<Scalars['String']['output']>;
  technician1: Maybe<Scalars['String']['output']>;
  thawBy: Maybe<Scalars['String']['output']>;
  trackingNumber: Maybe<Scalars['String']['output']>;
  tripMileage: Maybe<Scalars['Decimal']['output']>;
  tripTank: Maybe<StorageLocation>;
  tripTankId: Maybe<Scalars['UUID']['output']>;
  warning: Maybe<Scalars['Int']['output']>;
};

export type ShipmentFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ShipmentFilterInput>>;
  billStatus?: InputMaybe<IntOperationFilterInput>;
  billTo?: InputMaybe<StringOperationFilterInput>;
  callTag?: InputMaybe<StringOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  donors?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  earTagColor?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  hasCalves?: InputMaybe<BooleanOperationFilterInput>;
  hasDonors?: InputMaybe<BooleanOperationFilterInput>;
  hasEmbryos?: InputMaybe<BooleanOperationFilterInput>;
  hasRecipients?: InputMaybe<BooleanOperationFilterInput>;
  hasSemen?: InputMaybe<BooleanOperationFilterInput>;
  heatTimeSpan?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  implantDate?: InputMaybe<DateTimeOperationFilterInput>;
  implantPrice?: InputMaybe<DecimalOperationFilterInput>;
  implantTierOf?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  needByDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ShipmentFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  originalNeedByDate?: InputMaybe<DateTimeOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  packedBy?: InputMaybe<EquipmentFilterInput>;
  packedById?: InputMaybe<UuidOperationFilterInput>;
  packedby?: InputMaybe<StringOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  receiveGnrh?: InputMaybe<StringOperationFilterInput>;
  recipKickouts?: InputMaybe<StringOperationFilterInput>;
  recipsAvail?: InputMaybe<StringOperationFilterInput>;
  requestedBy?: InputMaybe<StringOperationFilterInput>;
  semens?: InputMaybe<ListFilterInputTypeOfSemenFilterInput>;
  shipAddress?: InputMaybe<StringOperationFilterInput>;
  shipAddress2?: InputMaybe<StringOperationFilterInput>;
  shipAddress3?: InputMaybe<StringOperationFilterInput>;
  shipCity?: InputMaybe<StringOperationFilterInput>;
  shipFromSite?: InputMaybe<SiteFilterInput>;
  shipFromSiteId?: InputMaybe<UuidOperationFilterInput>;
  shipName?: InputMaybe<StringOperationFilterInput>;
  shipState?: InputMaybe<StringOperationFilterInput>;
  shipTo?: InputMaybe<AccountFilterInput>;
  shipToId?: InputMaybe<UuidOperationFilterInput>;
  shipZip?: InputMaybe<StringOperationFilterInput>;
  shipmentCategory?: InputMaybe<IntOperationFilterInput>;
  shipmentNumber?: InputMaybe<StringOperationFilterInput>;
  shipmentStatus?: InputMaybe<IntOperationFilterInput>;
  shippingMethod?: InputMaybe<IntOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  tank?: InputMaybe<StringOperationFilterInput>;
  technician1?: InputMaybe<StringOperationFilterInput>;
  thawBy?: InputMaybe<StringOperationFilterInput>;
  trackingNumber?: InputMaybe<StringOperationFilterInput>;
  tripMileage?: InputMaybe<DecimalOperationFilterInput>;
  tripTank?: InputMaybe<StorageLocationFilterInput>;
  tripTankId?: InputMaybe<UuidOperationFilterInput>;
  warning?: InputMaybe<IntOperationFilterInput>;
};

export type ShipmentSortInput = {
  amount?: InputMaybe<SortEnumType>;
  billStatus?: InputMaybe<SortEnumType>;
  billTo?: InputMaybe<SortEnumType>;
  callTag?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  earTagColor?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  hasCalves?: InputMaybe<SortEnumType>;
  hasDonors?: InputMaybe<SortEnumType>;
  hasEmbryos?: InputMaybe<SortEnumType>;
  hasRecipients?: InputMaybe<SortEnumType>;
  hasSemen?: InputMaybe<SortEnumType>;
  heatTimeSpan?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  implantDate?: InputMaybe<SortEnumType>;
  implantPrice?: InputMaybe<SortEnumType>;
  implantTierOf?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  needByDate?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  originalNeedByDate?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  packedBy?: InputMaybe<EquipmentSortInput>;
  packedById?: InputMaybe<SortEnumType>;
  packedby?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  receiveGnrh?: InputMaybe<SortEnumType>;
  recipKickouts?: InputMaybe<SortEnumType>;
  recipsAvail?: InputMaybe<SortEnumType>;
  requestedBy?: InputMaybe<SortEnumType>;
  shipAddress?: InputMaybe<SortEnumType>;
  shipAddress2?: InputMaybe<SortEnumType>;
  shipAddress3?: InputMaybe<SortEnumType>;
  shipCity?: InputMaybe<SortEnumType>;
  shipFromSite?: InputMaybe<SiteSortInput>;
  shipFromSiteId?: InputMaybe<SortEnumType>;
  shipName?: InputMaybe<SortEnumType>;
  shipState?: InputMaybe<SortEnumType>;
  shipTo?: InputMaybe<AccountSortInput>;
  shipToId?: InputMaybe<SortEnumType>;
  shipZip?: InputMaybe<SortEnumType>;
  shipmentCategory?: InputMaybe<SortEnumType>;
  shipmentNumber?: InputMaybe<SortEnumType>;
  shipmentStatus?: InputMaybe<SortEnumType>;
  shippingMethod?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  tank?: InputMaybe<SortEnumType>;
  technician1?: InputMaybe<SortEnumType>;
  thawBy?: InputMaybe<SortEnumType>;
  trackingNumber?: InputMaybe<SortEnumType>;
  tripMileage?: InputMaybe<SortEnumType>;
  tripTank?: InputMaybe<StorageLocationSortInput>;
  tripTankId?: InputMaybe<SortEnumType>;
  warning?: InputMaybe<SortEnumType>;
};

export enum ShipmentStatus {
  SCHEDULED = 'SCHEDULED',
  TRANSFER_TRIP = 'TRANSFER_TRIP'
}

/** A segment of a collection. */
export type ShipmentsCollectionSegment = {
  __typename: 'ShipmentsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Shipment>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Sire = {
  __typename: 'Sire';
  birth: Maybe<Scalars['DateTime']['output']>;
  breed: Maybe<Breed>;
  breedId: Maybe<Scalars['UUID']['output']>;
  breedReg: Maybe<Scalars['String']['output']>;
  cane: Maybe<Scalars['String']['output']>;
  clientShortName: Maybe<Scalars['String']['output']>;
  countryCodeId: Maybe<Scalars['UUID']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  donors: Array<Donor>;
  edonorId: Maybe<Scalars['String']['output']>;
  embryos: Array<Embryo>;
  fertilizations: Array<Fertilization>;
  heparin: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  regPrefix: Maybe<Scalars['String']['output']>;
  registrationNumber: Maybe<Scalars['String']['output']>;
  regtog: Maybe<Scalars['Int']['output']>;
  semens: Array<Semen>;
  sexedCane: Maybe<Scalars['String']['output']>;
  sire: Maybe<Scalars['String']['output']>;
  sireCm: Maybe<Scalars['Int']['output']>;
  sireJpi: Maybe<Scalars['Int']['output']>;
  sireNm: Maybe<Scalars['Int']['output']>;
  sireTpi: Maybe<Scalars['Int']['output']>;
  stateCode: Scalars['Int']['output'];
  status: Maybe<Scalars['String']['output']>;
  statusCode: Maybe<Scalars['Int']['output']>;
  yard: Maybe<TOG_yardBase>;
  yardId: Maybe<Scalars['UUID']['output']>;
};

/** A segment of a collection. */
export type SireConfirmationsCollectionSegment = {
  __typename: 'SireConfirmationsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<SireSelectionDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SireFilterInput = {
  and?: InputMaybe<Array<SireFilterInput>>;
  birth?: InputMaybe<DateTimeOperationFilterInput>;
  breed?: InputMaybe<BreedFilterInput>;
  breedId?: InputMaybe<UuidOperationFilterInput>;
  breedReg?: InputMaybe<StringOperationFilterInput>;
  cane?: InputMaybe<StringOperationFilterInput>;
  clientShortName?: InputMaybe<StringOperationFilterInput>;
  countryCodeId?: InputMaybe<UuidOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  donors?: InputMaybe<ListFilterInputTypeOfDonorFilterInput>;
  edonorId?: InputMaybe<StringOperationFilterInput>;
  embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  heparin?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SireFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  regPrefix?: InputMaybe<StringOperationFilterInput>;
  registrationNumber?: InputMaybe<StringOperationFilterInput>;
  regtog?: InputMaybe<IntOperationFilterInput>;
  semens?: InputMaybe<ListFilterInputTypeOfSemenFilterInput>;
  sexedCane?: InputMaybe<StringOperationFilterInput>;
  sire?: InputMaybe<StringOperationFilterInput>;
  sireCm?: InputMaybe<IntOperationFilterInput>;
  sireJpi?: InputMaybe<IntOperationFilterInput>;
  sireNm?: InputMaybe<IntOperationFilterInput>;
  sireTpi?: InputMaybe<IntOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  yard?: InputMaybe<TOG_yardBaseFilterInput>;
  yardId?: InputMaybe<UuidOperationFilterInput>;
};

export type SireSelectionDto = {
  __typename: 'SireSelectionDto';
  aspSiteId: Maybe<Scalars['UUID']['output']>;
  aspSiteName: Maybe<Scalars['String']['output']>;
  confirmationType: Maybe<Scalars['String']['output']>;
  confirmationTypeId: Maybe<Scalars['Int']['output']>;
  csrFullName: Maybe<Scalars['String']['output']>;
  csrId: Maybe<Scalars['UUID']['output']>;
  didSend: Maybe<Scalars['Boolean']['output']>;
  donorName: Maybe<Scalars['String']['output']>;
  fertSiteId: Maybe<Scalars['UUID']['output']>;
  fertSiteName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['UUID']['output']>;
  ranchCode: Maybe<Scalars['String']['output']>;
  ranchId: Maybe<Scalars['UUID']['output']>;
  revision: Maybe<Scalars['String']['output']>;
  scheduleDate: Maybe<Scalars['String']['output']>;
  semenType: Maybe<Scalars['String']['output']>;
  sireName: Maybe<Scalars['String']['output']>;
};

export type SireSelectionDtoFilterInput = {
  and?: InputMaybe<Array<SireSelectionDtoFilterInput>>;
  aspSiteId?: InputMaybe<UuidOperationFilterInput>;
  aspSiteName?: InputMaybe<StringOperationFilterInput>;
  confirmationType?: InputMaybe<StringOperationFilterInput>;
  confirmationTypeId?: InputMaybe<IntOperationFilterInput>;
  csrFullName?: InputMaybe<StringOperationFilterInput>;
  csrId?: InputMaybe<UuidOperationFilterInput>;
  didSend?: InputMaybe<BooleanOperationFilterInput>;
  donorName?: InputMaybe<StringOperationFilterInput>;
  fertSiteId?: InputMaybe<UuidOperationFilterInput>;
  fertSiteName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<SireSelectionDtoFilterInput>>;
  ranchCode?: InputMaybe<StringOperationFilterInput>;
  ranchId?: InputMaybe<UuidOperationFilterInput>;
  revision?: InputMaybe<StringOperationFilterInput>;
  scheduleDate?: InputMaybe<StringOperationFilterInput>;
  semenType?: InputMaybe<StringOperationFilterInput>;
  sireName?: InputMaybe<StringOperationFilterInput>;
};

export type SireSelectionDtoSortInput = {
  aspSiteId?: InputMaybe<SortEnumType>;
  aspSiteName?: InputMaybe<SortEnumType>;
  confirmationType?: InputMaybe<SortEnumType>;
  confirmationTypeId?: InputMaybe<SortEnumType>;
  csrFullName?: InputMaybe<SortEnumType>;
  csrId?: InputMaybe<SortEnumType>;
  didSend?: InputMaybe<SortEnumType>;
  donorName?: InputMaybe<SortEnumType>;
  fertSiteId?: InputMaybe<SortEnumType>;
  fertSiteName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  ranchCode?: InputMaybe<SortEnumType>;
  ranchId?: InputMaybe<SortEnumType>;
  revision?: InputMaybe<SortEnumType>;
  scheduleDate?: InputMaybe<SortEnumType>;
  semenType?: InputMaybe<SortEnumType>;
  sireName?: InputMaybe<SortEnumType>;
};

export type SireSelectionRequest = {
  __typename: 'SireSelectionRequest';
  comment: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  donorSchedule: DonorSchedule;
  donorScheduleId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  semen: Maybe<Semen>;
  semenId: Maybe<Scalars['UUID']['output']>;
  semenType: Maybe<Scalars['Int']['output']>;
  semenTypeValue: Maybe<Scalars['String']['output']>;
  sire: Maybe<Sire>;
  sireId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
};

export type SireSelectionRequestFilterInput = {
  and?: InputMaybe<Array<SireSelectionRequestFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  donorSchedule?: InputMaybe<DonorScheduleFilterInput>;
  donorScheduleId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SireSelectionRequestFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  semen?: InputMaybe<SemenFilterInput>;
  semenId?: InputMaybe<UuidOperationFilterInput>;
  semenType?: InputMaybe<IntOperationFilterInput>;
  sire?: InputMaybe<SireFilterInput>;
  sireId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
};

export type SireSelectionRequestSortInput = {
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  donorSchedule?: InputMaybe<DonorScheduleSortInput>;
  donorScheduleId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  semen?: InputMaybe<SemenSortInput>;
  semenId?: InputMaybe<SortEnumType>;
  semenType?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SireSortInput>;
  sireId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SireSelectionRequestsCollectionSegment = {
  __typename: 'SireSelectionRequestsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<SireSelectionRequest>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SireSortInput = {
  birth?: InputMaybe<SortEnumType>;
  breed?: InputMaybe<BreedSortInput>;
  breedId?: InputMaybe<SortEnumType>;
  breedReg?: InputMaybe<SortEnumType>;
  cane?: InputMaybe<SortEnumType>;
  clientShortName?: InputMaybe<SortEnumType>;
  countryCodeId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  edonorId?: InputMaybe<SortEnumType>;
  heparin?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  regPrefix?: InputMaybe<SortEnumType>;
  registrationNumber?: InputMaybe<SortEnumType>;
  regtog?: InputMaybe<SortEnumType>;
  sexedCane?: InputMaybe<SortEnumType>;
  sire?: InputMaybe<SortEnumType>;
  sireCm?: InputMaybe<SortEnumType>;
  sireJpi?: InputMaybe<SortEnumType>;
  sireNm?: InputMaybe<SortEnumType>;
  sireTpi?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  yard?: InputMaybe<TOG_yardBaseSortInput>;
  yardId?: InputMaybe<SortEnumType>;
};

export type Site = {
  __typename: 'Site';
  aspirationSiteDonorSchedules: Array<DonorSchedule>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  fertilizationLocations: Array<Location>;
  fertilizationSiteDonorSchedules: Array<DonorSchedule>;
  flushes: Array<Flush>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['UUID']['output'];
  originalFreezeSiteEmbryos: Array<Embryo>;
  shipments: Array<Shipment>;
  siteEmbryos: Array<Embryo>;
  siteFertilizations: Array<Fertilization>;
  soSchedules: Array<SuperOvulationSchedule>;
  storageLocations: Array<StorageLocation>;
  trfSiteFertilizations: Array<Fertilization>;
};


export type SiteaspirationSiteDonorSchedulesArgs = {
  order: InputMaybe<Array<DonorScheduleSortInput>>;
  where: InputMaybe<DonorScheduleFilterInput>;
};


export type SitefertilizationSiteDonorSchedulesArgs = {
  order: InputMaybe<Array<DonorScheduleSortInput>>;
  where: InputMaybe<DonorScheduleFilterInput>;
};

export type SiteFilterInput = {
  and?: InputMaybe<Array<SiteFilterInput>>;
  aspirationSiteDonorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  fertilizationLocations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  fertilizationSiteDonorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  flushes?: InputMaybe<ListFilterInputTypeOfFlushFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SiteFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  originalFreezeSiteEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  shipments?: InputMaybe<ListFilterInputTypeOfShipmentFilterInput>;
  siteEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  siteFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  soSchedules?: InputMaybe<ListFilterInputTypeOfSuperOvulationScheduleFilterInput>;
  storageLocations?: InputMaybe<ListFilterInputTypeOfStorageLocationFilterInput>;
  trfSiteFertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
};

export type SiteSortInput = {
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  emailAddress?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SitesCollectionSegment = {
  __typename: 'SitesCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Site>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum SortEnumType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type StorageLocation = {
  __typename: 'StorageLocation';
  billable: Maybe<Scalars['Boolean']['output']>;
  canister: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  donorSchedules: Array<DonorSchedule>;
  embryos: Array<Embryo>;
  fertilizations: Array<Fertilization>;
  id: Scalars['UUID']['output'];
  locationType: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  oldStorageEmbryos: Array<Embryo>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  semens: Array<Semen>;
  shipments: Array<Shipment>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  tankp: Maybe<Scalars['String']['output']>;
  tanks: Maybe<Scalars['String']['output']>;
  toStorageSemens: Array<Semen>;
};

export type StorageLocationFilterInput = {
  and?: InputMaybe<Array<StorageLocationFilterInput>>;
  billable?: InputMaybe<BooleanOperationFilterInput>;
  canister?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  donorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  embryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  fertilizations?: InputMaybe<ListFilterInputTypeOfFertilizationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  locationType?: InputMaybe<IntOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  oldStorageEmbryos?: InputMaybe<ListFilterInputTypeOfEmbryoFilterInput>;
  or?: InputMaybe<Array<StorageLocationFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  semens?: InputMaybe<ListFilterInputTypeOfSemenFilterInput>;
  shipments?: InputMaybe<ListFilterInputTypeOfShipmentFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  tankp?: InputMaybe<StringOperationFilterInput>;
  tanks?: InputMaybe<StringOperationFilterInput>;
  toStorageSemens?: InputMaybe<ListFilterInputTypeOfSemenFilterInput>;
};

export type StorageLocationSortInput = {
  billable?: InputMaybe<SortEnumType>;
  canister?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  locationType?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  tankp?: InputMaybe<SortEnumType>;
  tanks?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type StorageLocationsCollectionSegment = {
  __typename: 'StorageLocationsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<StorageLocation>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type StringMap = {
  __typename: 'StringMap';
  attributeName: Scalars['String']['output'];
  attributeValue: Scalars['Int']['output'];
  displayOrder: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  langId: Scalars['Int']['output'];
  objectTypeCode: Scalars['Int']['output'];
  organizationId: Scalars['UUID']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type StringMapFilterInput = {
  and?: InputMaybe<Array<StringMapFilterInput>>;
  attributeName?: InputMaybe<StringOperationFilterInput>;
  attributeValue?: InputMaybe<IntOperationFilterInput>;
  displayOrder?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  langId?: InputMaybe<IntOperationFilterInput>;
  objectTypeCode?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<StringMapFilterInput>>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type StringMapSortInput = {
  attributeName?: InputMaybe<SortEnumType>;
  attributeValue?: InputMaybe<SortEnumType>;
  displayOrder?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  langId?: InputMaybe<SortEnumType>;
  objectTypeCode?: InputMaybe<SortEnumType>;
  organizationId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type StringMapsCollectionSegment = {
  __typename: 'StringMapsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<StringMap>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type StrippingFertilizationsCollectionSegment = {
  __typename: 'StrippingFertilizationsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<Fertilization>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Subscription = {
  __typename: 'Subscription';
  createdEquipment: Equipment;
  updatedEquipment: Equipment;
  updatedFertilization: Fertilization;
  updatedShipment: Shipment;
};

export type SuperOvulationSchedule = {
  __typename: 'SuperOvulationSchedule';
  aspirations: Array<Aspiration>;
  billDrugs: Maybe<Scalars['Boolean']['output']>;
  billStatus: Maybe<Scalars['Int']['output']>;
  clinic: Maybe<Scalars['Int']['output']>;
  clinicRanchHaulIn: Maybe<Scalars['Int']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  donor: Maybe<Donor>;
  donorId: Maybe<Scalars['UUID']['output']>;
  donorSchedules: Array<DonorSchedule>;
  drugShipFee: Maybe<Scalars['Int']['output']>;
  dtogId: Maybe<Scalars['Int']['output']>;
  embryoComment: Maybe<Scalars['String']['output']>;
  export: Maybe<Scalars['Int']['output']>;
  flush: Maybe<Flush>;
  flushAmPm: Maybe<Scalars['Boolean']['output']>;
  flushDate: Maybe<Scalars['DateTime']['output']>;
  flushId: Maybe<Scalars['UUID']['output']>;
  flushs: Array<Flush>;
  freeze: Maybe<Scalars['Int']['output']>;
  freezeMethod: Maybe<OptionSet>;
  freezeMethodId: Maybe<Scalars['UUID']['output']>;
  fresh: Maybe<Scalars['Int']['output']>;
  fshDrug1Id: Maybe<Scalars['UUID']['output']>;
  fshDrug2Id: Maybe<Scalars['UUID']['output']>;
  fshNext: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  implantType: Maybe<Scalars['Int']['output']>;
  linkedAspiration: Maybe<Aspiration>;
  linkedAspirationId: Maybe<Scalars['UUID']['output']>;
  linkedDonorSchedule: Maybe<DonorSchedule>;
  linkedDonorScheduleId: Maybe<Scalars['UUID']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['UUID']['output']>;
  scheduleMaster: Maybe<ScheduleMaster>;
  scheduleMasterId: Maybe<Scalars['UUID']['output']>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  tempBep: Maybe<Scalars['String']['output']>;
  time: Maybe<Scalars['Boolean']['output']>;
  transfer: Maybe<Scalars['Int']['output']>;
};

export type SuperOvulationScheduleFilterInput = {
  and?: InputMaybe<Array<SuperOvulationScheduleFilterInput>>;
  aspirations?: InputMaybe<ListFilterInputTypeOfAspirationFilterInput>;
  billDrugs?: InputMaybe<BooleanOperationFilterInput>;
  billStatus?: InputMaybe<IntOperationFilterInput>;
  clinic?: InputMaybe<IntOperationFilterInput>;
  clinicRanchHaulIn?: InputMaybe<IntOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  donor?: InputMaybe<DonorFilterInput>;
  donorId?: InputMaybe<UuidOperationFilterInput>;
  donorSchedules?: InputMaybe<ListFilterInputTypeOfDonorScheduleFilterInput>;
  drugShipFee?: InputMaybe<IntOperationFilterInput>;
  dtogId?: InputMaybe<IntOperationFilterInput>;
  embryoComment?: InputMaybe<StringOperationFilterInput>;
  export?: InputMaybe<IntOperationFilterInput>;
  flush?: InputMaybe<FlushFilterInput>;
  flushAmPm?: InputMaybe<BooleanOperationFilterInput>;
  flushDate?: InputMaybe<DateTimeOperationFilterInput>;
  flushId?: InputMaybe<UuidOperationFilterInput>;
  flushs?: InputMaybe<ListFilterInputTypeOfFlushFilterInput>;
  freeze?: InputMaybe<IntOperationFilterInput>;
  freezeMethod?: InputMaybe<OptionSetFilterInput>;
  freezeMethodId?: InputMaybe<UuidOperationFilterInput>;
  fresh?: InputMaybe<IntOperationFilterInput>;
  fshDrug1Id?: InputMaybe<UuidOperationFilterInput>;
  fshDrug2Id?: InputMaybe<UuidOperationFilterInput>;
  fshNext?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  implantType?: InputMaybe<IntOperationFilterInput>;
  linkedAspiration?: InputMaybe<AspirationFilterInput>;
  linkedAspirationId?: InputMaybe<UuidOperationFilterInput>;
  linkedDonorSchedule?: InputMaybe<DonorScheduleFilterInput>;
  linkedDonorScheduleId?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<SuperOvulationScheduleFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  owner?: InputMaybe<AccountFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  scheduleMaster?: InputMaybe<ScheduleMasterFilterInput>;
  scheduleMasterId?: InputMaybe<UuidOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  tempBep?: InputMaybe<StringOperationFilterInput>;
  time?: InputMaybe<BooleanOperationFilterInput>;
  transfer?: InputMaybe<IntOperationFilterInput>;
};

export type SuperOvulationScheduleSortInput = {
  billDrugs?: InputMaybe<SortEnumType>;
  billStatus?: InputMaybe<SortEnumType>;
  clinic?: InputMaybe<SortEnumType>;
  clinicRanchHaulIn?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  donor?: InputMaybe<DonorSortInput>;
  donorId?: InputMaybe<SortEnumType>;
  drugShipFee?: InputMaybe<SortEnumType>;
  dtogId?: InputMaybe<SortEnumType>;
  embryoComment?: InputMaybe<SortEnumType>;
  export?: InputMaybe<SortEnumType>;
  flush?: InputMaybe<FlushSortInput>;
  flushAmPm?: InputMaybe<SortEnumType>;
  flushDate?: InputMaybe<SortEnumType>;
  flushId?: InputMaybe<SortEnumType>;
  freeze?: InputMaybe<SortEnumType>;
  freezeMethod?: InputMaybe<OptionSetSortInput>;
  freezeMethodId?: InputMaybe<SortEnumType>;
  fresh?: InputMaybe<SortEnumType>;
  fshDrug1Id?: InputMaybe<SortEnumType>;
  fshDrug2Id?: InputMaybe<SortEnumType>;
  fshNext?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  implantType?: InputMaybe<SortEnumType>;
  linkedAspiration?: InputMaybe<AspirationSortInput>;
  linkedAspirationId?: InputMaybe<SortEnumType>;
  linkedDonorSchedule?: InputMaybe<DonorScheduleSortInput>;
  linkedDonorScheduleId?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<AccountSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  scheduleMaster?: InputMaybe<ScheduleMasterSortInput>;
  scheduleMasterId?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  tempBep?: InputMaybe<SortEnumType>;
  time?: InputMaybe<SortEnumType>;
  transfer?: InputMaybe<SortEnumType>;
};

export type SystemUser = {
  __typename: 'SystemUser';
  activeDirectoryGuid: Maybe<Scalars['UUID']['output']>;
  adUserId: Scalars['UUID']['output'];
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Use `equipments` instead */
  equipment: Maybe<Equipment>;
  /** @deprecated Use `equipments` instead */
  equipmentId: Maybe<Scalars['UUID']['output']>;
  equipments: Maybe<EquipmentsCollectionSegment>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  groups: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  internalEmailAddress: Maybe<Scalars['String']['output']>;
  isDisabled: Maybe<Scalars['Boolean']['output']>;
  issueManagerTypes: Array<RequestIssueType>;
  lastName: Maybe<Scalars['String']['output']>;
  middleName: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  organization: Organization;
  organizationId: Scalars['UUID']['output'];
  personalEmailAddress: Maybe<Scalars['String']['output']>;
  salutation: Maybe<Scalars['String']['output']>;
  supervisor: Maybe<SystemUser>;
};


export type SystemUserequipmentsArgs = {
  order: InputMaybe<Array<EquipmentSortInput>>;
  skip: InputMaybe<Scalars['Int']['input']>;
  take: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<EquipmentFilterInput>;
};

export type SystemUserFilterInput = {
  activeDirectoryGuid?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<SystemUserFilterInput>>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  equipments?: InputMaybe<ListFilterInputTypeOfEquipmentFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  internalEmailAddress?: InputMaybe<StringOperationFilterInput>;
  isDisabled?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  middleName?: InputMaybe<StringOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<SystemUserFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  personalEmailAddress?: InputMaybe<StringOperationFilterInput>;
  salutation?: InputMaybe<StringOperationFilterInput>;
};

export type SystemUserSortInput = {
  activeDirectoryGuid?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  internalEmailAddress?: InputMaybe<SortEnumType>;
  isDisabled?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  middleName?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  personalEmailAddress?: InputMaybe<SortEnumType>;
  salutation?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SystemUsersCollectionSegment = {
  __typename: 'SystemUsersCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<SystemUser>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type TOG_yardBase = {
  __typename: 'TOG_yardBase';
  billboard: Maybe<Scalars['Int']['output']>;
  category: Maybe<Scalars['String']['output']>;
  center: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<SystemUser>;
  createdById: Maybe<Scalars['UUID']['output']>;
  createdOn: Maybe<Scalars['DateTime']['output']>;
  createdOnBehalfBy: Maybe<SystemUser>;
  createdOnBehalfById: Maybe<Scalars['UUID']['output']>;
  herdsman: Maybe<Equipment>;
  herdsmanId: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  modifiedBy: Maybe<SystemUser>;
  modifiedById: Maybe<Scalars['UUID']['output']>;
  modifiedOn: Maybe<Scalars['DateTime']['output']>;
  modifiedOnBehalfBy: Maybe<SystemUser>;
  modifiedOnBehalfById: Maybe<Scalars['UUID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['UUID']['output']>;
  searchYard: Maybe<Scalars['String']['output']>;
  sequence: Maybe<Scalars['Int']['output']>;
  site: Maybe<Site>;
  siteId: Maybe<Scalars['UUID']['output']>;
  stateCode: Scalars['Int']['output'];
  statusCode: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  yard: Maybe<Scalars['String']['output']>;
};

export type TOG_yardBaseFilterInput = {
  and?: InputMaybe<Array<TOG_yardBaseFilterInput>>;
  billboard?: InputMaybe<IntOperationFilterInput>;
  category?: InputMaybe<StringOperationFilterInput>;
  center?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<SystemUserFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  createdOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  createdOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  herdsman?: InputMaybe<EquipmentFilterInput>;
  herdsmanId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<SystemUserFilterInput>;
  modifiedById?: InputMaybe<UuidOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserFilterInput>;
  modifiedOnBehalfById?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TOG_yardBaseFilterInput>>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationId?: InputMaybe<UuidOperationFilterInput>;
  searchYard?: InputMaybe<StringOperationFilterInput>;
  sequence?: InputMaybe<IntOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  stateCode?: InputMaybe<IntOperationFilterInput>;
  statusCode?: InputMaybe<IntOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  yard?: InputMaybe<StringOperationFilterInput>;
};

export type TOG_yardBaseSortInput = {
  billboard?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  center?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SystemUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  createdOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  createdOnBehalfById?: InputMaybe<SortEnumType>;
  herdsman?: InputMaybe<EquipmentSortInput>;
  herdsmanId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SystemUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  modifiedOnBehalfBy?: InputMaybe<SystemUserSortInput>;
  modifiedOnBehalfById?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<OrganizationSortInput>;
  organizationId?: InputMaybe<SortEnumType>;
  searchYard?: InputMaybe<SortEnumType>;
  sequence?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  stateCode?: InputMaybe<SortEnumType>;
  statusCode?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  yard?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type TelemetryEventsCollectionSegment = {
  __typename: 'TelemetryEventsCollectionSegment';
  /** A flattened list of the items. */
  items: Maybe<Array<TogTelemetryEvent>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type TogRequestApp = {
  __typename: 'TogRequestApp';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  requests: Array<RequestItIssue>;
};

export type TogRequestAppFilterInput = {
  and?: InputMaybe<Array<TogRequestAppFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TogRequestAppFilterInput>>;
  requests?: InputMaybe<ListFilterInputTypeOfRequestItIssueFilterInput>;
};

export type TogRequestAppSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type TogRequestDepartment = {
  __typename: 'TogRequestDepartment';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  requests: Array<RequestItIssue>;
};

export type TogRequestDepartmentFilterInput = {
  and?: InputMaybe<Array<TogRequestDepartmentFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TogRequestDepartmentFilterInput>>;
  requests?: InputMaybe<ListFilterInputTypeOfRequestItIssueFilterInput>;
};

export type TogRequestDepartmentSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type TogTelemetryEvent = {
  __typename: 'TogTelemetryEvent';
  adUserId: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  jsonEvent: Scalars['String']['output'];
  mtoUserId: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type TogTelemetryEventFilterInput = {
  adUserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<TogTelemetryEventFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  jsonEvent?: InputMaybe<StringOperationFilterInput>;
  mtoUserId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TogTelemetryEventFilterInput>>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type TogTelemetryEventSortInput = {
  adUserId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  jsonEvent?: InputMaybe<SortEnumType>;
  mtoUserId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type UpdateAetaResultInput = {
  embryoDevelopmentRate: Scalars['Float']['input'];
  embryosPerAspiration: Scalars['Float']['input'];
  id: Scalars['UUID']['input'];
  oocytesPerAspiration: Scalars['Float']['input'];
  type: AetaResultType;
  year: Scalars['Int']['input'];
};

export type UpdateAetaResultPayload = {
  __typename: 'UpdateAetaResultPayload';
  aetaResult: Maybe<AetaResult>;
};

export type UpdateEquipmentInput = {
  isActive: Scalars['Boolean']['input'];
  isEmbryologist: Scalars['Boolean']['input'];
  isHerdsman: Scalars['Boolean']['input'];
  isInTraining: Scalars['Boolean']['input'];
  isTechnician: Scalars['Boolean']['input'];
  isVeterinarian: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  siteId: Scalars['UUID']['input'];
  systemUserId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateFertilizationInput = {
  hasFewMotileSperm: Scalars['Boolean']['input'];
  hasNoMotileSperm: Scalars['Boolean']['input'];
  hasPoorCytoplasmQuality: Scalars['Boolean']['input'];
  hasTwoCellCleavage: Scalars['Boolean']['input'];
  isFragile: Scalars['Boolean']['input'];
  isHardToStrip: Scalars['Boolean']['input'];
  ivcBroken: Scalars['Int']['input'];
  ivcComment: Scalars['String']['input'];
  ivcDead: Scalars['Int']['input'];
  ivcExtra: Scalars['Int']['input'];
  ivcImmature: Scalars['Int']['input'];
  ivcMissing: Scalars['Int']['input'];
  ivcNumber: Scalars['Int']['input'];
  ivcTechnicianId: Scalars['UUID']['input'];
  qcCultureIncubator: Scalars['String']['input'];
  strippedAt: Scalars['DateTime']['input'];
};

export type UpdateShipmentInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  billStatus?: InputMaybe<Scalars['Int']['input']>;
  billTo?: InputMaybe<Scalars['String']['input']>;
  callTag?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hasCalves?: InputMaybe<Scalars['Boolean']['input']>;
  hasDonors?: InputMaybe<Scalars['Boolean']['input']>;
  hasEmbryos?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipients?: InputMaybe<Scalars['Boolean']['input']>;
  hasSemen?: InputMaybe<Scalars['Boolean']['input']>;
  needByDate?: InputMaybe<Scalars['DateTime']['input']>;
  originalNeedByDate?: InputMaybe<Scalars['DateTime']['input']>;
  ownerId?: InputMaybe<Scalars['UUID']['input']>;
  packedById?: InputMaybe<Scalars['UUID']['input']>;
  packedby?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  requestedBy?: InputMaybe<Scalars['String']['input']>;
  shipAddress?: InputMaybe<Scalars['String']['input']>;
  shipAddress2?: InputMaybe<Scalars['String']['input']>;
  shipAddress3?: InputMaybe<Scalars['String']['input']>;
  shipCity?: InputMaybe<Scalars['String']['input']>;
  shipFromSiteId?: InputMaybe<Scalars['UUID']['input']>;
  shipName?: InputMaybe<Scalars['String']['input']>;
  shipState?: InputMaybe<Scalars['String']['input']>;
  shipToId?: InputMaybe<Scalars['UUID']['input']>;
  shipZip?: InputMaybe<Scalars['String']['input']>;
  shipmentCategory?: InputMaybe<Scalars['Int']['input']>;
  shipmentStatus?: InputMaybe<Scalars['Int']['input']>;
  shippingMethod?: InputMaybe<Scalars['Int']['input']>;
  tank?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type messaging_confirmation_type = {
  __typename: 'messaging_confirmation_type';
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export enum tog_category {
  BEEF = 'BEEF',
  CROSS = 'CROSS',
  DAIRY = 'DAIRY',
  DEER = 'DEER',
  EXOTIC = 'EXOTIC',
  MEAT = 'MEAT',
  PETS = 'PETS'
}

export enum tog_fertplan {
  DID_NOT_ASPIRATE = 'DID_NOT_ASPIRATE',
  DO_NOT_FERT = 'DO_NOT_FERT',
  FERT = 'FERT',
  POOL = 'POOL'
}

export enum tog_noembryosproduced {
  NO_EMBRYOS_PRODUCED = 'NO_EMBRYOS_PRODUCED'
}

export enum tog_subfamily {
  BOVINE = 'BOVINE',
  CANINE = 'CANINE',
  CAPRINE = 'CAPRINE',
  CERVIDAE = 'CERVIDAE',
  EQUINE = 'EQUINE',
  FELINE = 'FELINE',
  OVINE = 'OVINE',
  PORCINE = 'PORCINE'
}

export type LOGINMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LOGINMutation = { __typename: 'Mutation', login: boolean };


export const LOGIN = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LOGIN"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"username"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"username"},"value":{"kind":"Variable","name":{"kind":"Name","value":"username"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}]}]}}]} as unknown as DocumentNode<LOGINMutation, LOGINMutationVariables>;