import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';

import { useTrackPage } from '@transova/telemetry';
import { LoginForm } from '@transova/ui';

import { LOGIN } from '../types.gql';
import './login.scss';

function goToReturnUrl(username: string) {
  const params = new URLSearchParams(window.location.search);
  let returnUrl = params.get('returnUrl');
  if (!returnUrl) {
    const param = params.get('fromsite');
    if (param) {
      returnUrl = atob(param);
    }
  }
  if (!returnUrl) {
    returnUrl = import.meta.env.VITE_DEFAULT_REDIRECT;
  }
  if (!returnUrl) {
    return;
  }
  const url = new URL(returnUrl);
  url.searchParams.append('username', username);
  window.location.replace(url.toString());
}

function getCookie(key: string) {
  return document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');
}

function useUsernameCookie() {
  const username = useMemo(() => getCookie('username') || '', []);
  return { username };
}

const params = new URLSearchParams(window.location.search);
const returnToNucleus = params.get('returnUrl')?.includes('nucleus');

export function Login() {
  useTrackPage();

  const { username } = useUsernameCookie();
  useEffect(() => {
    if (!username) {
      return;
    }
    goToReturnUrl(username);
    // run hook only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mutate, res] = useMutation(LOGIN);
  const onSubmit = useCallback(
    async (data: { username: string; password: string }) => {
      const res = await mutate({ variables: data, errorPolicy: 'all' });
      if (!res.data?.login) {
        // no login. don't redirect
        return;
      }

      const missingCrmUser = res.errors?.some(
        (e) => e.extensions['code'] === 'MISSING_CRM_USER',
      );

      if (missingCrmUser && (returnToNucleus || import.meta.env.DEV)) {
        // disable going to nucleus without a valid crm user to prevent infinite loop
        return;
      }

      // continue redirecting even without a crm user to other apps
      goToReturnUrl(data.username);
    },
    [mutate],
  );

  const error = useMemo(
    () =>
      res.error?.graphQLErrors.find((e) => e.extensions['code'])?.extensions[
        'code'
      ] as string | undefined,
    [res.error],
  );

  let errorMessage = undefined as string | undefined;

  if (error) {
    if (error === 'INVALID_CREDENTIALS') {
      errorMessage = 'Wrong username or password';
    } else if (error === 'UNAUTHORIZED') {
      errorMessage = 'Account Locked';
    } else if (error === 'MISSING_CRM_USER') {
      if (returnToNucleus) {
        errorMessage =
          'An OvaDrive account not set up for you. Please contact support to set one up.';
      }
    } else {
      errorMessage = 'Unknown Error';
    }
  }

  return (
    <main className="login-app">
      <LoginForm
        onSubmit={onSubmit}
        isLoading={res.loading}
        errorTitle={errorMessage ? 'Error' : undefined}
        errorMessage={errorMessage}
      />
    </main>
  );
}
