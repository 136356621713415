import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '../reducers/apiSlice';

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'AnimalShipments',
    'Calves',
    'Client',
    'Donors',
    'EmbryoInventoryAll',
    'EmbryoInventoryByMating',
    'Flush',
    'FlushProductionSummary',
    'FreshShipments',
    'FrozenShipments',
    'InvoiceFinancials',
    'InvoicesAll',
    'InvoicesByDonor',
    'IVF',
    'IvfProductionSummary',
    'Kpi',
    'Ranches',
    'Ranches',
    'Reports',
    'SemenInventory',
    'Sites',
    'Statements',
    'Transfers',
    'User',
    'Users',
    'View',
  ],
  endpoints: (_build) => ({}),
});
