import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import {
  Analytics,
  AnalyticsProvider,
  transovaPlugin,
} from '@transova/telemetry';

import { Login } from '../components/login';
import './app.scss';

const analytics = Analytics({
  app: 'ovadrive-login',
  plugins: [
    transovaPlugin({
      inProduction: import.meta.env.PROD,
      url: import.meta.env.PROD
        ? '/api/ovadrive/telemetry'
        : `${import.meta.env.VITE_API_URL}/ovadrive/telemetry`,
    }),
  ],
});

const httpLink = new HttpLink({
  uri: import.meta.env.PROD
    ? '/graphql'
    : `${import.meta.env.VITE_API_URL}/graphql`,
  credentials: 'include',
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      analytics.track('GRAPHQL_ERROR', err);

      if (!import.meta.env.PROD) {
        console.error('[GraphQL Error]', err);
      }
    });
  }

  if (networkError) {
    analytics.track('GRAPHQL_ERROR', networkError);

    if (!import.meta.env.PROD) {
      console.error('[GraphQL Error]', networkError);
    }
  }
});
const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: !import.meta.env.PROD,
});

export default function App() {
  return (
    <AnalyticsProvider instance={analytics}>
      <ApolloProvider client={client}>
        <Login />
      </ApolloProvider>
    </AnalyticsProvider>
  );
}
