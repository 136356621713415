import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Controller, useForm } from 'react-hook-form';

import { Logo } from '../logo/logo';
import './login-form.scss';

const schema = z.object({
  username: z
    .string()
    .min(1, { message: 'Username is required.' })
    .refine(
      (value) => {
        const includesDomain = value.includes('\\');
        return !includesDomain;
      },
      { message: 'Username cannot include "transova\\\\".' },
    ),
  password: z.string().min(1, { message: 'Password is required.' }),
});

type Fields = z.TypeOf<typeof schema>;

/* eslint-disable-next-line */
export interface LoginFormProps {
  errorTitle?: string;
  errorMessage?: string;
  onSubmit(data: Fields): void;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export function LoginForm(props: LoginFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Fields>({ resolver: zodResolver(schema) });
  return (
    <section
      className={classNames(
        '[ card ] [ login-form ] [ radius-xl ]',
        !props.children ? ' [ no-children ]' : '',
      )}
    >
      <div className="[ login-form__logo ]">
        <Logo />
      </div>

      <div className="[ login-form__wrapper ] [ flow ]">
        {props.children}
        {props.errorMessage && !props.isLoading && (
          <dl className="login-form__error">
            <dt className="login-form__error-status">{props.errorTitle}</dt>
            <dd className="login-form__error-message">{props.errorMessage}</dd>
          </dl>
        )}

        <form
          className="login-form__form"
          onSubmit={handleSubmit(props.onSubmit)}
        >
          <div className="[ login-form__section ] [ login-form__username ]">
            <label className="login-form__label" htmlFor="username">
              Username
            </label>

            <Controller
              name="username"
              control={control}
              defaultValue={''}
              rules={{ required: 'Username is required.' }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  autoComplete="username"
                  autoFocus
                  autoCapitalize="off"
                  disabled={props.isLoading}
                  className={classNames(
                    '[ login-form__input login-form__input-username ]',
                    { 'p-invalid': fieldState.error },
                  )}
                />
              )}
            />

            {errors.username ? (
              <small className="[ login-form__validation ] [ p-error ]">
                {errors.username.message}
              </small>
            ) : null}
          </div>

          <div className="[ login-form__section ] [ login-form__password ]">
            <label className="login-form__label" htmlFor="password">
              Password
            </label>

            <Controller
              name="password"
              control={control}
              defaultValue={''}
              rules={{ required: 'Password is required.' }}
              render={({ field, fieldState }) => (
                <Password
                  id={field.name}
                  {...field}
                  autoComplete="password"
                  feedback={false}
                  disabled={props.isLoading}
                  className={classNames(
                    '[ login-form__input login-form__input-password ]',
                    { 'p-invalid': fieldState.error },
                  )}
                  inputRef={field.ref}
                />
              )}
            />

            {errors.password ? (
              <small className="[ login-form__validation ] [ p-error ]">
                {errors.password.message}
              </small>
            ) : null}
          </div>

          <div className="login-form__actions">
            <button
              disabled={props.isLoading}
              className="[ login-form__button ] [ login-form__button-submit ] [ button radius-m ]"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
