import { Analytics, AnalyticsPlugin } from 'analytics';
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';
import { AnalyticsProvider, useAnalytics } from 'use-analytics';

export { useAnalytics, AnalyticsProvider, Analytics };

function getCookie(key: string) {
  return document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');
}

function getBrowserProfile() {
  return {
    url: window.location.href,
    userAgent: window.navigator.userAgent,
    platform: window.navigator.platform,
    referrer: document.referrer,
    path: window.location.pathname,
    search: window.location.search,
    title: document.title,
    hash: window.location.hash,
  };
}

export function transovaPlugin({
  url,
  inProduction,
}: {
  url: string;
  inProduction: boolean;
}): AnalyticsPlugin {
  let eventBuffer: any[] = [];

  async function sendEvents() {
    if (!eventBuffer.length) {
      return;
    }

    try {
      const events = eventBuffer.slice();
      eventBuffer = [];
      await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(events),
      });
    } catch (e) {
      // swallow telemetry errors in production
      if (!inProduction) {
        console.error('[TELEMETRY]', e);
      }
    }
  }

  window.addEventListener('beforeunload', sendEvents, { capture: true });

  const batchedSendEvents = debounce(sendEvents, 1_000);

  return {
    name: 'transova-telemetry',
    async page({
      payload,
    }: {
      payload: {
        type: 'page';
        properties: Record<string, string>;
      };
    }) {
      const data = {
        username: getCookie('username'),
        ...payload.properties,
        ...getBrowserProfile(),
      };

      if (!inProduction) {
        console.info('[TELEMETRY]', { type: 'PAGE_VIEW', data });
        return;
      }

      eventBuffer.push({ type: 'PAGE_VIEW', data: JSON.stringify(data) });
      batchedSendEvents();
    },
    async track({
      payload,
    }: {
      payload: {
        type: 'track';
        event: string;
        properties: Record<string, string>;
      };
    }) {
      const data = {
        username: getCookie('username'),
        ...payload.properties,
        browser: getBrowserProfile(),
      };

      if (!inProduction) {
        console.info('[TELEMETRY]', { type: payload.event, data });
        return;
      }

      eventBuffer.push({ type: payload.event, data: JSON.stringify(data) });
      batchedSendEvents();
    },
  };
}

export function useTrackServerError() {
  const { track } = useAnalytics();
  return useCallback(
    (res: Response, method: string) => {
      track('SERVER_ERROR', {
        status: res.status,
        statusText: res.statusText,
        responseType: res.type,
        responseUrl: res.url,
        method,
      });
    },
    [track],
  );
}
export function useTrackPage() {
  const { page } = useAnalytics();
  useEffect(() => {
    page();
    // hook only runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
