import { createSlice } from '@reduxjs/toolkit';

export interface ICredentials {
  username: string;
  password: string;
}

export interface ILoggedInUserState {
  // id: string;
  // adUserId: string;
  // username: string;
  // ranchCode: string;
  // email: string;
  // stateCode: number;
  // // myOpuSites: string;
  // perPage: number;
  // isCustomerService: boolean;
  // isSuperAdmin: boolean;
  // isMyOpuEntry: boolean;
  // isMyTransferEntry: boolean;
  // moveStatementPdf: boolean;
  // isInIT: boolean;
  // showPa: boolean;
  // showVit: boolean;
  // useNewSite: boolean;
  // useNewSiteStopReminder: boolean;
  // hideInvoicesStatements: boolean;
  performLogout: boolean;
  // ranches?: string;
}

interface ILoginErrorData {
  message: string;
}

export interface ILoginErrorResponse {
  status: number;
  statusName?: string;
  data: ILoginErrorData;
}

export const initialLoggedInUserState: ILoggedInUserState = {
  // id: '',
  // adUserId: '',
  // username: '',
  // ranchCode: '',
  // email: '',
  // stateCode: 0,
  // // myOpuSites: '',
  // perPage: 0,
  // isCustomerService: false,
  // isSuperAdmin: false,
  // isMyOpuEntry: false,
  // isMyTransferEntry: false,
  // moveStatementPdf: false,
  // isInIT: false,
  // showPa: false,
  // showVit: false,
  // useNewSite: false,
  // useNewSiteStopReminder: false,
  // hideInvoicesStatements: false,
  performLogout: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoggedInUserState,
  reducers: {
    invalidateLoggedInUser: (state) => ({
      ...state,
      ...initialLoggedInUserState,
    }),
    performLogout: (_state) => ({
      ...initialLoggedInUserState,
      performLogout: true,
    }),
    finishLogout: (state) => ({
      ...state,
      performLogout: false,
    }),
  },
});

export const { invalidateLoggedInUser, performLogout, finishLogout } =
  loginSlice.actions;

export default loginSlice.reducer;
