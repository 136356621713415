import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../reducers/apiSlice';
import { ICredentials } from '../reducers/loginSlice';

export type Me = {
  id: string;
  adUserId: string;
  username: string;
  accountCode: string;
  email: string;
  isCustomerService: boolean;
  isSuperAdmin: boolean;
  isMyOpuEntry: boolean;
  isMyTransferEntry: boolean;
  moveStatementPdf: boolean;
  isInIT: boolean;
  showPa: boolean;
  showVit: boolean;
  useNewSite: boolean;
  useNewSiteStopReminder: boolean;
  hideInvoicesStatements: boolean;
};

export const authenticationApi = createApi({
  reducerPath: 'authentication',
  baseQuery,
  tagTypes: ['Login'],
  endpoints: (build) => ({
    loginUser: build.mutation<Me, ICredentials>({
      query: (credentials: ICredentials) => ({
        url: 'authentication/login',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['Login'],
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: 'authentication/logout',
        method: 'POST',
      }),
      invalidatesTags: ['Login'],
    }),
    forgotPassword: build.mutation<void, { username: string }>({
      query: (body) => ({
        url: 'authentication/forgot-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: build.mutation<
      void,
      { resetCode: string; password: string }
    >({
      query: (body) => ({
        url: 'authentication/reset-password',
        method: 'POST',
        body,
      }),
    }),
    me: build.query<Me, void>({
      query: () => ({
        url: 'authentication/me',
        method: 'GET',
      }),
      providesTags: ['Login'],
    }),
    ranches: build.query<
      Array<{ id: string; name: string; code: string }>,
      void
    >({
      query: () => ({
        url: 'Authentication/me/ranches',
        method: 'GET',
      }),
      providesTags: ['Login'],
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  useLoginUserMutation,
  useLogoutMutation,
  useMeQuery,
  useRanchesQuery,
  useResetPasswordMutation,
} = authenticationApi;

export function useRanches() {
  const ranches = useRanchesQuery();
  return ranches.currentData;
}
