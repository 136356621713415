import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IToolPanelParams } from 'ag-grid-community';

export interface ICustomView {
  id: string;
  username: string;
  name: string;
  view: string;
  usage: string;
  isDefault: boolean;
}

export interface ICustomViewProps extends IToolPanelParams {
  setQuickFilter(value: string): any;
  useAppDispatch(): any;
  useAppSelector(): any;
  //useAppSelector(state: any): any;
  // currentViewId?: string;
  // username: string;
  /*onSelectView(value: string | undefined): any;
  onAddView(value: string | undefined): any;
  onDefaultView(id: string): any;
  onUpdateView(id: string): any;
  onDeleteView(id: string): any;
  onClearAllFilters(): any;*/
}

export interface ICustomViewNamesState {
  currentViewId?: string;
  currentView?: ICustomView;
}

export const initialCustomViewsState: ICustomViewNamesState = {
  currentViewId: undefined,
  currentView: undefined,
};

export const customViewsSlice = createSlice({
  name: 'customViews',
  initialState: initialCustomViewsState,
  reducers: {
    setCurrentViewId: (
      state: any,
      action /*: PayloadAction<ICustomView[]>*/,
    ) => ({
      ...state,
      currentViewId: action.payload,
    }),
    setCurrentView: (state: any, action: PayloadAction<ICustomView>) => ({
      ...state,
      currentView: action.payload,
    }),
    resetCustomViews: () => ({
      ...initialCustomViewsState,
      currentViewId: undefined,
    }),
  },
});

export const { setCurrentViewId, setCurrentView, resetCustomViews } =
  customViewsSlice.actions;

export default customViewsSlice.reducer;
