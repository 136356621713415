import { ICustomView } from '../reducers/customViewsSlice';
import { baseApi } from './baseApi';

export const customViewsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getViewNames: build.query<
      ICustomView[],
      { username: string; usage: string }
    >({
      query: ({ usage, username }) =>
        `custom-views/viewNames?username=${username}&usage=${encodeURIComponent(
          'app=' + import.meta.env.VITE_USAGE_APP,
        )}${encodeURIComponent('&' + usage)}`,
      providesTags: ['View'],
    }),
    getViewById: build.query<ICustomView, string>({
      query: (id) => `custom-views/viewById/${id}`,
    }),
    addView: build.mutation<
      { success: boolean },
      { username: string; usage: string; name: string; view: string }
    >({
      query: ({ usage, username, name, view }) => ({
        url: 'custom-views/addView',
        method: 'POST',
        body: { username, usage, name, view },
      }),
      invalidatesTags: ['View'],
    }),
    removeView: build.mutation<{ success: boolean; id: string }, string>({
      query: (id) => {
        return {
          url: `custom-views/removeView/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['View'],
    }),
    updateView: build.mutation<
      { success: boolean },
      { id: string; view: string }
    >({
      query: ({ id, view }) => ({
        url: 'custom-views/updateView',
        method: 'POST',
        body: { id, view },
      }),
      invalidatesTags: ['View'],
    }),
    defaultView: build.mutation<
      void,
      { username: string; usage: string; viewId: string }
    >({
      query: ({ username, usage, viewId }) => ({
        url: 'custom-views/defaultView',
        method: 'POST',
        body: {
          username,
          usage,
          viewId,
        },
      }),
      invalidatesTags: ['View'],
    }),
  }),
});

export const {
  useGetViewNamesQuery,
  useGetViewByIdQuery,
  useAddViewMutation,
  useUpdateViewMutation,
  useRemoveViewMutation,
  useDefaultViewMutation,
} = customViewsApi;
